import React from 'react'

const TretiiTrestWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="150"
    height="36"
    viewBox="0 0 150 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_21870_121546)">
      <path
        d="M0 10.9672C1.46875 9.61401 3.30469 8.8273 4.99219 7.82817C9.15625 5.36576 13.3672 2.97415 17.5547 0.551071C17.7266 0.456665 17.8984 0.362259 18.0625 0.259987C18.6484 -0.101902 19.2187 -0.0625659 19.8047 0.275721C24.8047 3.17869 29.8047 6.08167 34.8047 8.98464C35.4766 9.37799 36.1562 9.75562 36.8203 10.1647C38.0156 10.8885 38.0234 12.045 36.8359 12.7609C35.6719 13.4689 34.5 14.1612 33.3359 14.8614C33.2422 14.8693 33.1406 14.885 33.0469 14.8929C32.8906 14.7827 32.7422 14.6568 32.5781 14.5546C31.1797 13.7443 29.7734 12.9339 28.375 12.1236C25.5469 10.4873 22.7031 8.8745 19.8828 7.21453C19.1484 6.78184 18.5469 6.82118 17.8125 7.26174C15.5391 8.63062 13.2187 9.92083 10.9297 11.2504C8.85937 12.454 6.80469 13.6813 4.74219 14.8929C4.66406 14.8929 4.58594 14.8771 4.51562 14.8693C4.16406 14.6411 3.82031 14.3972 3.46094 14.1927C2.25781 13.524 1.00781 12.9261 0 11.9427V10.9672Z"
        fill="white"
      />
      <path
        d="M33.4531 21.665C34.5703 22.318 35.6875 22.9789 36.8047 23.6318C37.9922 24.332 38.0234 25.5435 36.8516 26.2358C34.4766 27.6362 32.0859 29.0051 29.7031 30.3897C26.7891 32.0811 23.8437 33.7175 20.9687 35.4797C19.9531 36.1012 18.9297 35.999 17.875 35.9832C17.7031 35.9832 17.5234 35.8337 17.3594 35.7315C11.9687 32.624 6.57812 29.5164 1.19531 26.3932C0.757812 26.1493 0.398437 25.7638 0 25.4413C0 25.1187 0 24.7883 0 24.4657C0.625 24.0173 1.22656 23.5453 1.86719 23.1362C2.65625 22.6327 3.47656 22.1685 4.28125 21.6886C4.35937 21.6886 4.4375 21.6808 4.51562 21.6729C9.05469 24.3163 13.6016 26.9518 18.1406 29.5872C18.5937 29.8469 19.0703 29.8469 19.5391 29.6423C19.8047 29.5243 20.0547 29.3591 20.3047 29.2175C22.5781 27.9037 24.8516 26.5899 27.1172 25.2682C29.1641 24.0802 31.2109 22.8844 33.25 21.6965C33.3125 21.6886 33.3828 21.6729 33.4453 21.665H33.4531Z"
        fill="white"
      />
      <path
        d="M4.51563 21.6741C4.4375 21.6741 4.35938 21.682 4.28125 21.6898C4.08594 21.5325 3.89844 21.3673 3.69531 21.2335C2.47656 20.3996 1 19.9591 0 18.7947V18.0159C0.40625 17.5281 0.835938 17.1033 1.42188 16.7886C2.49219 16.2222 3.49219 15.522 4.51563 14.8848C4.59375 14.8848 4.67188 14.9005 4.74219 14.9084C6.20313 15.7738 7.66406 16.6313 9.125 17.5045C9.49219 17.7248 9.92969 17.8585 10.1875 18.2991C9.75 18.5587 9.35938 18.779 8.97656 19.0071C7.48438 19.8961 6 20.793 4.50781 21.682L4.51563 21.6741Z"
        fill="white"
      />
      <path
        d="M150 18.7945H147.484V24.6083H144.945V18.8024H142.453V16.4265C142.68 16.395 142.891 16.34 143.109 16.34C145.125 16.34 147.141 16.34 149.156 16.3242C149.5 16.3242 149.773 16.3872 150 16.6389V18.7945Z"
        fill="white"
      />
      <path
        d="M58.6094 27.9356H56.1562V16.5046C57.0156 16.2293 57.8672 16.2843 58.7969 16.6462C58.9219 16.6934 59.125 16.6777 59.2422 16.6069C61.3984 15.3167 64.3516 16.5911 64.9453 19.5177C65.3047 21.2957 64.8594 22.8849 63.3984 24.0649C62.1641 25.0641 60.8047 25.1349 59.3828 24.5055C59.2109 24.4268 59.0469 24.3167 58.7734 24.1593C58.5625 25.4495 58.8047 26.6768 58.6172 27.9356H58.6094ZM60.6172 18.5501C59.4297 18.5343 58.6563 19.2974 58.6484 20.4775C58.6406 21.6812 59.3828 22.4758 60.5391 22.4994C61.7109 22.523 62.4922 21.752 62.5078 20.5562C62.5234 19.3525 61.7812 18.5579 60.6172 18.5422V18.5501Z"
        fill="white"
      />
      <path
        d="M117.188 16.7959C117.555 16.6386 117.977 16.379 118.438 16.2531C120.617 15.6867 122.836 17.15 123.258 19.3764C123.578 21.0835 123.297 22.6176 121.984 23.8449C120.672 25.0722 118.977 25.2295 117.414 24.3012C117.305 24.2382 117.195 24.1753 117.008 24.073V27.8886H114.523V16.3239C115.234 16.3239 115.914 16.2924 116.586 16.3475C116.789 16.3632 116.977 16.6229 117.195 16.7881L117.188 16.7959ZM116.984 20.5407C116.992 21.7286 117.781 22.5075 118.945 22.4996C120.086 22.4917 120.883 21.6578 120.867 20.4935C120.852 19.3134 120.07 18.5346 118.898 18.5503C117.734 18.5582 116.969 19.3528 116.984 20.5407Z"
        fill="white"
      />
      <path
        d="M127.047 21.5253C127.719 22.6031 128.867 22.9729 129.899 22.3907C130.492 22.0524 130.891 22.1232 131.383 22.4851C131.711 22.7368 132.102 22.9178 132.477 23.1381C132.164 23.7832 131.656 24.1608 131.102 24.4047C129.5 25.1127 127.875 25.1363 126.336 24.2316C124.625 23.2246 123.883 21.0769 124.5 19.1337C125.117 17.1905 126.914 15.9868 128.992 16.1285C131 16.2622 132.531 17.6704 132.852 19.7473C132.938 20.3059 132.867 20.8959 132.867 21.5253H127.047ZM130.344 19.5979C129.906 18.583 129.281 18.2447 128.289 18.4099C127.586 18.5279 127.227 18.8898 127.156 19.5979H130.344Z"
        fill="white"
      />
      <path
        d="M74.461 21.5486H68.7422C69.2266 22.4926 70.4375 23.0119 71.3985 22.4533C72.211 21.9734 72.711 22.2566 73.3125 22.6578C73.5782 22.8309 73.8516 22.9883 74.1719 23.1849C73.5782 23.9717 72.8985 24.483 72.0079 24.7033C70.7735 25.0101 69.5547 24.9944 68.3907 24.4594C66.461 23.5704 65.5235 21.3362 66.1641 19.1884C66.7735 17.1351 68.75 15.8685 70.8985 16.1517C73 16.4271 74.5391 18.1578 74.586 20.282C74.586 20.5731 74.586 20.8641 74.5704 21.1631C74.5704 21.2575 74.5235 21.344 74.4688 21.5486H74.461ZM72.0313 19.5739C71.5469 18.5748 71.0157 18.2758 70.0235 18.4017C69.2969 18.4961 68.9141 18.8423 68.7657 19.5739H72.0313Z"
        fill="white"
      />
      <path
        d="M92.9216 16.3716H95.3044V20.1635C95.3591 20.1871 95.4138 20.2107 95.4685 20.2422C95.9529 19.6994 96.4529 19.1644 96.9294 18.6137C97.3982 18.0709 97.8904 17.5438 98.3044 16.9695C98.6716 16.466 99.1091 16.2536 99.7185 16.3401C99.9998 16.3794 100.289 16.3401 100.64 16.3401V24.6242H98.2576V20.5962C97.5154 21.4459 96.9216 22.1146 96.3279 22.799C95.9451 23.2396 95.5623 23.688 95.2107 24.1522C94.906 24.5534 94.5544 24.7422 94.0388 24.6793C93.6873 24.6399 93.3279 24.6793 92.9138 24.6793V16.3716H92.9216Z"
        fill="white"
      />
      <path
        d="M85.7581 16.3882V20.4476C86.4534 19.6531 87.0471 18.9843 87.6409 18.3156C87.9377 17.9695 88.2893 17.6548 88.5081 17.2693C89.1096 16.223 90.0237 16.2466 91.0784 16.4197V24.6329H88.7034V20.605C88.0627 21.3445 87.5549 21.9267 87.0549 22.5088C86.594 23.0517 86.1174 23.5866 85.6799 24.1531C85.3752 24.5464 85.0315 24.751 84.5159 24.688C84.1721 24.6408 83.8127 24.6802 83.4221 24.6802V16.3882H85.7581Z"
        fill="white"
      />
      <path
        d="M57.594 13.1695C56.9143 13.9877 56.3518 14.6957 55.7346 15.3566C55.5627 15.5375 55.2346 15.624 54.969 15.6398C54.3909 15.6791 53.8127 15.6555 53.1487 15.6555V24.624H50.4846V15.687H47.5471V13.2167C48.219 13.0436 56.1174 12.9886 57.594 13.1695Z"
        fill="white"
      />
      <path
        d="M108.828 15.6631H105.906V13.1299H115.664C114.961 13.9559 114.344 14.7112 113.695 15.4271C113.562 15.5687 113.304 15.6395 113.094 15.6474C112.609 15.6788 112.125 15.6552 111.508 15.6552C111.476 16.6701 111.5 17.6614 111.492 18.6605C111.492 19.636 111.492 20.6115 111.492 21.5871V24.6316H108.828V15.6631Z"
        fill="white"
      />
      <path
        d="M141.867 18.3226C141.141 18.7474 140.469 19.1329 139.813 19.5105C138.75 18.4642 137.625 18.3462 136.805 19.1801C136.125 19.8645 136.133 21.1311 136.82 21.8471C137.578 22.6416 138.789 22.5236 139.828 21.5324C140.492 21.91 141.156 22.2876 141.82 22.6652C141.195 24.278 139.273 25.1749 137.273 24.8287C135.414 24.514 133.922 22.799 133.797 20.903C133.641 18.4642 135.102 16.8357 136.813 16.3165C138.836 15.695 140.938 16.5289 141.875 18.3226H141.867Z"
        fill="white"
      />
      <path
        d="M33.4534 21.6665C33.3909 21.6743 33.3206 21.6901 33.2581 21.6979C32.3284 21.1236 31.3987 20.5336 30.4612 19.9672C29.5002 19.3929 28.5237 18.8343 27.4612 18.2128C28.5862 17.5677 29.5862 16.9934 30.5862 16.4033C31.4143 15.9077 32.2346 15.3963 33.0549 14.885C33.1487 14.8771 33.2502 14.8614 33.344 14.8535C34.0393 15.3019 34.7346 15.7582 35.4456 16.1909C35.9377 16.4899 36.4612 16.7416 36.9534 17.0484C37.9846 17.6857 37.9612 18.9366 36.9143 19.5738C35.7659 20.274 34.6096 20.9663 33.4612 21.6665H33.4534Z"
        fill="white"
      />
      <path
        d="M77.3909 24.6168V18.8187H74.9221V16.3799H82.2893V18.7794H79.8831V24.6089H77.3909V24.6168Z"
        fill="white"
      />
      <path
        d="M100.258 13.3184C99.6638 14.0107 99.1794 14.6086 98.656 15.175C98.5232 15.3166 98.281 15.4268 98.0779 15.4346C97.1794 15.4661 96.2888 15.4504 95.3513 15.4504V13.3262H100.258V13.3184Z"
        fill="white"
      />
      <path
        d="M33.0471 14.8921C32.2268 15.3956 31.4065 15.9148 30.5784 16.4104C29.5862 17.0005 28.5784 17.5748 27.4534 18.2199C28.5237 18.8414 29.4924 19.3921 30.4534 19.9742C31.3909 20.5407 32.3206 21.1307 33.2502 21.705C31.2034 22.8929 29.1643 24.0887 27.1174 25.2767C24.844 26.5984 22.5706 27.9122 20.3049 29.226C20.0549 29.3755 19.8049 29.5328 19.5393 29.6508C19.0706 29.8632 18.5862 29.8632 18.1409 29.5957C13.594 26.9602 9.05493 24.3169 4.51587 21.6814C6.00806 20.7924 7.49243 19.8956 8.98462 19.0066C9.36743 18.7784 9.75806 18.5503 10.1956 18.2985C9.93774 17.858 9.49243 17.7242 9.13306 17.504C7.67993 16.6307 6.21118 15.7732 4.75024 14.9078C6.81274 13.6963 8.86743 12.469 10.9377 11.2653C13.2268 9.9279 15.5471 8.63769 17.8206 7.27668C18.5471 6.83612 19.1487 6.79678 19.8909 7.22948C22.7112 8.88944 25.5471 10.5022 28.3831 12.1386C29.7815 12.9489 31.1877 13.7513 32.5862 14.5695C32.7502 14.6639 32.8987 14.7898 33.0549 14.9078L33.0471 14.8921ZM14.2893 12.9253C14.8909 13.6176 15.4768 14.2863 16.094 14.9943C16.2815 14.7741 16.3909 14.6167 16.5315 14.483C17.1799 13.8457 17.9534 13.594 18.8518 13.712C19.4299 13.7907 19.8362 14.1132 19.9143 14.5695C20.0081 15.1123 19.9534 15.6552 19.4299 15.9463C19.0159 16.1744 18.5393 16.3003 18.0784 16.4262C17.8127 16.497 17.5237 16.4734 17.2659 16.4891V18.5109C17.7971 18.5503 18.2893 18.5503 18.7581 18.629C19.3049 18.7234 19.8362 18.92 20.0862 19.4943C20.5237 20.4856 20.0862 21.587 18.8596 21.7365C17.6721 21.8781 16.6018 21.6421 15.6331 20.7452C15.094 21.469 14.6096 22.1298 14.1252 22.7828C15.0627 23.7505 15.8049 24.1281 17.469 24.3641C18.9221 24.5765 20.3206 24.3641 21.6174 23.6324C23.8909 22.3344 23.7034 18.7784 21.6331 17.6613C21.4846 17.5826 21.3596 17.4646 21.1877 17.3466C21.3284 17.2443 21.4065 17.1814 21.4924 17.1342C22.719 16.3947 23.2112 15.2697 23.0081 13.9008C22.8127 12.6106 21.9143 11.879 20.7268 11.4777C19.3206 11.0057 17.9143 11.0844 16.5159 11.5171C15.6643 11.7767 14.8987 12.1622 14.2737 12.9174L14.2893 12.9253Z"
        fill="#F9F9F9"
      />
    </g>
    <defs>
      <clipPath id="clip0_21870_121546">
        <rect width="150" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default TretiiTrestWhiteLogo
