import React from 'react'

const SberbankDevelopmentWhiteLogo: React.FC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    width="149"
    height="40"
    viewBox="0 0 149 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_21870_120887)">
      <mask
        id="mask0_21870_120887"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="149"
        height="40"
      >
        <path d="M0 0H149V40H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_21870_120887)">
        <path
          d="M20.4321 34.1344C24.2027 37.8857 29.3065 39.9944 34.6288 40C39.953 40.0014 45.0607 37.895 48.8326 34.1424C52.6001 30.392 54.7166 25.304 54.7166 20C54.7159 17.372 54.1958 14.77 53.1861 12.3431C52.1764 9.91628 50.697 7.71231 48.8326 5.8576C45.066 2.1072 39.9559 0 34.6288 0V5.7032C37.4679 5.70294 40.2436 6.54131 42.6067 8.1128C44.9664 9.6818 46.807 11.9147 47.8954 14.5288C48.9825 17.1416 49.2668 20.016 48.7125 22.7896C48.1574 25.5645 46.7896 28.1122 44.7824 30.1096C42.7722 32.1101 40.214 33.4716 37.4301 34.0224C34.6447 34.574 31.7582 34.291 29.1334 33.2088C26.5116 32.1281 24.2692 30.2958 22.6896 27.9432C21.1114 25.592 20.2687 22.828 20.2687 20H14.541C14.5452 22.6269 15.0675 25.2273 16.0784 27.6526C17.0892 30.0778 18.5686 32.2804 20.4321 34.1344Z"
          fill="white"
        />
        <path
          d="M89.048 34.2042H80.8474V34.1914C73.0634 34.1914 66.3231 30.009 66.3231 20.8666V19.0986C66.3231 9.87624 72.6332 5.77864 80.4116 5.77864H89.048V11.4778H80.4116C76.6153 11.4778 72.7702 13.0738 72.7702 19.1122V20.8802C72.7702 26.6898 76.8773 28.5202 80.8474 28.5202H89.048V34.2042Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.424 5.77864H134.475L134.463 5.78744C142.242 5.78744 148.982 9.96904 148.982 19.1122V20.8802C148.982 30.1066 142.677 34.2042 134.898 34.2042H123.424V5.77864ZM134.91 28.5194C138.707 28.5194 142.548 26.9234 142.548 20.8802V19.1034C142.548 13.2938 138.446 11.4634 134.475 11.4634H129.871V28.5194H134.91Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.264 17.113H110.148C115.516 17.113 119.472 18.789 119.472 25.4642C119.472 32.317 115.033 34.1378 110.148 34.1378H93.812V5.71224H117.834V11.3266H100.264V17.113ZM100.264 28.5194H110.148L110.152 28.5186C111.755 28.5186 113.056 27.9874 113.056 25.6274C113.056 23.2666 111.755 22.7362 110.152 22.7362H100.264V28.5194Z"
          fill="white"
        />
        <path
          d="M8.92288 36.6298C12.2285 38.828 16.1113 40.0007 20.0827 40.0002L20.0835 34.3146C17.2451 34.3152 14.4699 33.4774 12.1072 31.9066C9.74774 30.3384 7.9072 28.1063 6.81846 25.493C5.73104 22.8824 5.4455 20.0078 5.99816 17.2346C6.55188 14.4603 7.91804 11.9126 9.92342 9.91464C11.9318 7.91347 14.4883 6.55064 17.2709 5.99784C20.0548 5.44437 22.9405 5.72492 25.5652 6.80424C28.1877 7.88196 30.4317 9.71148 32.0139 12.0618C33.5932 14.4092 34.4383 17.1723 34.4419 20.0002H40.1696C40.1694 16.0433 38.9913 12.1758 36.7851 8.88904C34.575 5.59773 31.4379 3.03433 27.7698 1.52264C24.0981 0.00868062 20.0602 -0.387387 16.1638 0.384243C12.2695 1.15501 8.69077 3.05985 5.8788 5.85864C3.07098 8.65278 1.15748 12.2168 0.381016 16.0986C-0.393975 19.9783 0.00393696 24.0006 1.52415 27.6538C3.04665 31.3111 5.62157 34.4349 8.92288 36.6298Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_21870_120887">
        <rect width="149" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default SberbankDevelopmentWhiteLogo
