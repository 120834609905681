import React from 'react'
import Modal from '../Modal'

import * as s from './ModalYoutubeVideo.module.scss'

interface ModalIframeProps {
  onClose: () => void
  title: string
  src: string
  srcTitle: string
}

const ModalIframe: React.FC<ModalIframeProps> = ({
  onClose,
  title,
  src,
  srcTitle,
}) => {
  return (
    <Modal
      onClose={onClose}
      title={title}
      className={s.modal}
      contentClassName={s.modal_content}
    >
      <iframe
        title={srcTitle}
        className="w-100 h-100"
        src={src}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Modal>
  )
}

export default ModalIframe
