import React from 'react'

const YoutubePlayIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.666687" width="48" height="48" rx="24" fill="#0695D7" />
      <path
        d="M32.6667 24.0004L20.6667 30.9286L20.6667 17.0722L32.6667 24.0004Z"
        fill="white"
        stroke="white"
        strokeWidth="1.33333"
      />
    </svg>
  )
}

export default YoutubePlayIcon
