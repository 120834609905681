import React from 'react'

const SibtehproektWhiteLogo: React.FC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    width="103"
    height="49"
    viewBox="0 0 103 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35.867 11.8799C35.2582 11.3763 34.8142 11.0101 34.3701 10.6439C34.4205 10.5477 34.4663 10.4516 34.5166 10.3555H69.4542C69.6831 11.4267 68.5295 11.2207 68.0946 12.0904C68.2136 17.7165 68.3372 23.6448 68.4608 29.66H57.2178C57.172 29.0511 57.1217 28.5567 57.1034 28.0577C57.0118 25.5033 56.9614 22.9535 56.8424 20.3991C56.728 17.95 54.5856 16.096 51.9717 16.1143C49.3578 16.128 47.2794 17.9912 47.1696 20.4724C47.0551 23.0817 46.9956 25.691 46.9087 28.3004C46.8949 28.694 46.8446 29.0832 46.7988 29.66C43.045 29.898 39.3187 29.7378 35.4688 29.7469C35.6015 26.6295 35.7801 23.6402 35.8442 20.6509C35.9083 17.7211 35.8579 14.7868 35.8579 11.8799H35.867Z"
      fill="white"
    />
    <path
      d="M36.4617 9.20573C35.9673 6.44991 36.718 3.68493 35.9307 1.03898C36.9698 0.713955 65.6221 0.585778 68.1308 0.933689C67.2839 3.60253 68.0529 6.3904 67.5768 9.20573H36.4617Z"
      fill="white"
    />
    <path
      d="M46.8767 35.8808H35.1484V30.5203C39.1128 30.4745 42.9993 30.4653 46.8767 30.5203V35.8854V35.8808Z"
      fill="white"
    />
    <path
      d="M57.0898 30.5703H68.8776V35.6974C67.7835 36.0316 59.4474 36.1277 57.0898 35.8027V30.5703Z"
      fill="white"
    />
    <path
      d="M27.3472 48.2857H25.4474C25.5069 47.1138 25.5618 46.0197 25.6168 44.9256C24.8156 45.9236 23.5751 46.6652 23.9367 48.272H21.9088C22.1468 46.3539 22.4444 44.4312 21.7119 42.4902H23.8268C23.7628 43.6484 23.6987 44.7471 23.6392 45.8412C24.5044 44.8707 25.5801 44.0513 25.4565 42.4948H27.2922C27.0542 44.3946 26.9718 46.3081 27.3517 48.2903L27.3472 48.2857Z"
      fill="white"
    />
    <path
      d="M52.585 42.4805H58.0325V48.2805H56.2106V43.0252C55.7253 42.984 55.2034 42.9428 54.5763 42.8879C54.3886 44.7648 54.3749 46.4769 54.5992 48.2759H52.5895V42.4805H52.585Z"
      fill="white"
    />
    <path
      d="M77.5068 42.4771H79.5852C79.5211 43.3057 79.4661 44.0565 79.4066 44.8393C80.0933 45.0178 80.3588 44.6882 80.5465 44.2625C80.7296 43.8505 80.8166 43.3835 81.0638 43.0127C81.6955 42.056 82.5561 42.2116 83.1924 43.5163C83.078 43.6261 82.9269 43.7772 82.7575 43.942C82.4325 43.7772 82.2403 43.6811 82.048 43.5804C81.9107 43.5071 81.7733 43.4293 81.5307 43.2965C81.311 43.9237 81.1096 44.4959 80.9219 45.0361C81.5856 46.1073 82.2082 47.1144 82.9269 48.2726C82.3318 48.3001 81.8099 48.3275 81.2103 48.3596C80.7021 47.4898 80.226 46.6749 79.75 45.8601C79.6538 45.8738 79.5531 45.8921 79.457 45.9059C79.489 46.6704 79.5256 47.4303 79.5623 48.2726H77.5068C77.8502 46.3133 77.841 44.4547 77.516 42.4771H77.5068Z"
      fill="white"
    />
    <path
      d="M70.8317 45.3656C70.8317 47.096 69.2798 48.625 67.5494 48.5975C65.8602 48.5701 64.4045 47.1372 64.3542 45.4572C64.2992 43.6398 65.6497 42.3809 67.6364 42.3946C69.5499 42.4084 70.8317 43.5986 70.8363 45.3702L70.8317 45.3656ZM68.378 47.8468C69.2936 46.1255 69.3165 44.5828 68.378 42.9531H66.9589C65.8694 44.7385 65.8877 47.0365 67.0505 47.8468H68.378Z"
      fill="white"
    />
    <path
      d="M71.6426 48.2772C71.9768 46.3225 71.931 44.4502 71.6792 42.4863H76.0968C76.1425 42.9762 76.1837 43.4431 76.2616 44.3312C75.3872 43.4248 74.8745 42.5092 73.6476 43.0906C73.6248 43.617 73.5973 44.2213 73.5607 45.0957C74.1192 44.6745 74.4762 44.4044 74.902 44.084V46.5148C74.8333 46.5789 74.76 46.6384 74.6914 46.7025C74.5174 46.3088 74.3389 45.9151 74.1649 45.5168C74.0184 45.6084 73.872 45.6954 73.73 45.7869V47.6913C74.9065 48.2864 75.6069 47.4349 76.5683 46.4919C76.4218 47.2838 76.3348 47.7416 76.2341 48.2726H71.6426V48.2772Z"
      fill="white"
    />
    <path
      d="M28.7531 48.3406C29.1055 46.3401 29.133 44.4633 28.7256 42.4902H33.1477C33.1706 42.9663 33.1935 43.3783 33.2118 43.7903C32.5206 42.9068 31.6966 42.7786 30.6391 42.9938V44.9302C31.1564 45.0034 31.6554 45.0492 32.1406 45.1453C33.1203 45.3376 33.6192 45.8869 33.6192 46.7247C33.6192 47.6219 33.0333 48.2537 31.9804 48.3223C30.9138 48.391 29.8426 48.3361 28.7531 48.3361V48.3406ZM30.6849 45.6535V47.7821C31.5043 47.9195 31.7652 47.5487 31.8019 46.9673C31.8476 46.2944 31.6874 45.7405 30.6849 45.6535Z"
      fill="white"
    />
    <path
      d="M49.522 48.3773C49.1283 47.5441 48.849 46.9536 48.5103 46.244C47.8099 46.8574 47.0088 47.1916 47.6313 48.272H45.7773C46.5601 47.393 47.2605 46.6011 48.025 45.745C47.3109 44.6601 46.6654 43.6759 45.9101 42.5223H48.1532C48.4096 43.0853 48.6797 43.6759 49.1145 44.6372C49.5265 43.7629 49.7875 43.2135 50.1308 42.4902H51.4126C50.758 43.2913 50.1629 44.0238 49.4853 44.8569C50.1766 45.951 50.8312 46.9948 51.6461 48.2811C50.8083 48.3177 50.1262 48.3498 49.5265 48.3773H49.522Z"
      fill="white"
    />
    <path
      d="M44.9679 48.2824H40.3901C40.6923 46.3552 40.7884 44.4691 40.3535 42.4961H44.6887C44.7436 42.9493 44.8031 43.4116 44.8992 44.2265C44.0386 43.2972 43.4023 42.606 42.2533 43.0409V45.1741C42.7202 44.7392 43.0269 44.46 43.4344 44.0846V46.6573C43.0864 46.2682 42.8209 45.9706 42.4547 45.5632C41.9695 46.3414 42.1572 47.0189 42.2579 47.7102C43.4847 48.1863 44.2721 47.6003 45.1693 46.8587C45.1052 47.3165 45.0412 47.7697 44.9679 48.2916V48.2824Z"
      fill="white"
    />
    <path
      d="M61.3057 45.997C61.3606 46.7432 61.4109 47.4482 61.4659 48.2539H59.6348V42.4447C60.4588 42.4447 61.4521 42.3714 62.4272 42.4676C63.3657 42.5591 63.9608 43.232 64.002 43.9965C64.0432 44.7564 63.5488 45.3149 62.5554 45.6216C62.1846 45.7361 61.8092 45.846 61.3057 45.997ZM61.4705 42.9482V45.228C62.5783 44.1156 62.5645 43.7814 61.4705 42.9482Z"
      fill="white"
    />
    <path
      d="M20.8198 42.6887C20.7969 43.1419 20.774 43.5677 20.7419 44.2223C20.1789 43.705 19.7806 43.3388 19.419 43.0046C18.3432 42.6704 17.5787 42.9497 17.098 43.9202C16.4892 45.1516 16.7913 46.6256 18.0685 47.9532C18.7369 47.8159 19.5334 47.6556 20.5039 47.4542C20.2338 47.7335 20.0461 48.0448 19.7669 48.1912C18.4622 48.8779 16.8005 48.5895 15.8529 47.5641C14.9602 46.5936 14.7862 45.2706 15.4042 44.085C16.0955 42.7528 17.4871 42.057 18.9429 42.3271C19.4968 42.4278 20.0461 42.5377 20.8243 42.6887H20.8198Z"
      fill="white"
    />
    <path
      d="M37.8907 48.2817H35.7346V42.6968C35.1075 43.0813 34.6634 43.356 34.0866 43.7085C34.0637 43.3148 34.0408 42.9806 34.0088 42.5137H39.2778C39.3099 42.9714 39.3373 43.388 39.3739 43.9053C38.8338 43.4979 38.4126 43.182 37.7122 42.6556C37.5749 44.711 37.4009 46.4506 37.8907 48.2771V48.2817Z"
      fill="white"
    />
    <path
      d="M85.3389 48.2494V42.6737C84.6339 43.1314 84.1167 43.4702 83.5078 43.8685C83.5353 43.429 83.5582 43.049 83.5902 42.4814C85.316 42.3258 86.9961 42.4036 88.7951 42.4265C88.873 42.9163 88.9416 43.3329 89.0378 43.9188C88.3831 43.4748 87.8796 43.136 87.0739 42.5867C86.9915 44.697 86.9045 46.4137 87.1746 48.2494H85.3435H85.3389Z"
      fill="white"
    />
  </svg>
)

export default SibtehproektWhiteLogo
