import React from 'react'

import Section from '../Common/Section'
import Container from '../Common/Container'
import Typography from '../Common/Typography'
import PartnersBtn from './PartnersBtn'
import { toLink } from '../../utils'

import MrGroupLogo from './svg/mr-group-logo'
import MrGroupWhiteLogo from './svg/mr-group-white-logo'
import X5GroupWhiteLogo from './svg/X5-group-white-logo'
import X5GroupLogo from './svg/X5-group-logo'
import HalsLogo from './svg/hals-logo'
import HalsWhiteLogo from './svg/hals-white-logo'
import MosinzhproektLogo from './svg/mosinzhproekt-logo'
import MosinzhproektWhiteLogo from './svg/mosinzhproekt-white-logo'
import GreenLogo from './svg/green-logo'
import GreenWhiteLogo from './svg/green-white-logo'
import AkvilonLogo from './svg/akvilon-logo'
import AkvilonWhiteLogo from './svg/akvilon-white-logo'
import MavisLogo from './svg/mavis-logo'
import MavisWhiteLogo from './svg/mavis-white-logo'
import SberbankDevelopmentLogo from './svg/sberbank-development-logo'
import SberbankDevelopmentWhiteLogo from './svg/sberbank-development-white-logo'
import KpUgsLogo from './svg/kp-ugs-logo'
import KpUgsWhiteLogo from './svg/kp-ugs-white-logo'
import SminexLogo from './svg/sminex-logo'
import SminexWhiteLogo from './svg/sminex-white-logo'
import PioneerWhiteLogo from './svg/pioneer-white-logo'
import PioneerLogo from './svg/pioneer-logo'
import TretiiTrestLogo from './svg/tretii-trest-logo'
import TretiiTrestWhiteLogo from './svg/tretii-trest-white-logo'
import KsmLogo from './svg/ksm-logo'
import KsmWhiteLogo from './svg/ksm-white-logo'
import SibtehproektLogo from './svg/sibtehproekt-logo'
import SibtehproektWhiteLogo from './svg/sibtehproekt-white-logo'

import * as s from './PartnersBlock.module.scss'

const partners = [
  {
    title: 'MR Group',
    link: 'https://www.mr-group.ru',
    Icon: MrGroupLogo,
    IconHover: MrGroupWhiteLogo,
  },
  {
    title: 'Х5 Group',
    link: 'https://www.x5.ru/ru/about',
    Icon: X5GroupLogo,
    IconHover: X5GroupWhiteLogo,
  },
  {
    title: 'ГАЛС',
    link: 'https://hals-development.ru',
    Icon: HalsLogo,
    IconHover: HalsWhiteLogo,
  },
  {
    title: 'Мосинжпроект',
    link: 'https://mosinzhproekt.ru',
    Icon: MosinzhproektLogo,
    IconHover: MosinzhproektWhiteLogo,
  },
  {
    title: 'Green',
    link: 'https://greenfingroup.com/en',
    Icon: GreenLogo,
    IconHover: GreenWhiteLogo,
  },
  {
    title: 'Аквилон',
    link: 'https://group-akvilon.ru',
    Icon: AkvilonLogo,
    IconHover: AkvilonWhiteLogo,
  },
  {
    title: 'Мавис',
    link: 'https://grafikadom.ru',
    Icon: MavisLogo,
    IconHover: MavisWhiteLogo,
  },
  {
    title: 'Сбербанк development',
    link: 'https://sbdevelop.ru',
    Icon: SberbankDevelopmentLogo,
    IconHover: SberbankDevelopmentWhiteLogo,
  },
  {
    title: 'КП УГС',
    link: 'https://kpugs.ru',
    Icon: KpUgsLogo,
    IconHover: KpUgsWhiteLogo,
  },
  {
    title: 'Sminex',
    link: 'https://www.sminex.com',
    Icon: SminexLogo,
    IconHover: SminexWhiteLogo,
  },
  {
    title: 'Pioneer',
    link: 'https://pioneer.ru',
    Icon: PioneerLogo,
    IconHover: PioneerWhiteLogo,
  },
  {
    title: 'Третий Трест',
    link: 'https://tretiitrest.ru',
    Icon: TretiiTrestLogo,
    IconHover: TretiiTrestWhiteLogo,
  },
  {
    title: 'KSM',
    link: 'https://ksm.company',
    Icon: KsmLogo,
    IconHover: KsmWhiteLogo,
  },
  {
    title: 'Сибтехпроект',
    link: 'https://main.sibtehproekt.com',
    Icon: SibtehproektLogo,
    IconHover: SibtehproektWhiteLogo,
  },
]

const PartnersBlock = () => {
  return (
    <Section>
      <Container>
        <Typography variant="h2" color="blue" size={40} mb={48}>
          С нами работают
        </Typography>
        <div className={s.content}>
          {partners.map(({ title, Icon, IconHover, link }, idx) => (
            <PartnersBtn
              key={title + idx}
              title={title}
              Icon={<Icon />}
              IconHover={<IconHover />}
              onClick={() => toLink(link)}
            />
          ))}
        </div>
      </Container>
    </Section>
  )
}

export default PartnersBlock
