import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

import { ClientsCard } from './Card'
import { IClients } from './types'
import { useTranslations } from '../../hooks/use-translations'

import './Clients.styles.scss'

const clients: IClients[] = [
  {
    photoId: 'autor1',
    name: 'Paul',
    position: 'client_position1',
    path: '/making-bim-for-construction-site/',
    text: 'client_text1',
  },
  {
    photoId: 'autor2',
    name: 'Tim',
    position: 'client_position2',
    text: 'client_text2',
  },
  {
    photoId: 'autor3',
    name: 'Helen',
    position: 'client_position3',
    text: 'client_text3',
  },
]

const ClientsSection: React.FC = () => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)

  return (
    <section className="clients-section" id="clients-section">
      <div className="clients-container">
        <h2 className="clients-title">{t('Clients')}</h2>
        <Swiper
          freeMode={true}
          wrapperClass="clients-wrap"
          pagination={{
            clickable: true,
            bulletClass: 'clients-pagination',
            bulletActiveClass: 'active',
          }}
          slidesPerView="auto"
          breakpoints={{
            1440: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination]}
        >
          <div className="flex justify-between w-100 h-100">
            {clients.map((item, idx) => (
              <SwiperSlide className="clients-slide" key={idx}>
                <ClientsCard
                  key={item.name}
                  image={
                    item.photoId &&
                    images[item.photoId].childImageSharp.gatsbyImageData
                  }
                  name={item.name}
                  position={item.position}
                  path={item.path}
                  text={item.text}
                />
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    </section>
  )
}

export default ClientsSection

const imagesQuery = graphql`
  query {
    autor1: file(relativePath: { eq: "author/Paul.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    autor2: file(relativePath: { eq: "author/Timofey_Lyutomsky.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    autor3: file(relativePath: { eq: "author/Helen.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
  }
`
