import React from 'react'

const TelegramLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      color="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_15526_2851)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.09992 10.7081C9.39488 8.83689 12.2589 7.60327 13.6919 7.00725C17.7834 5.30548 18.6335 5.00987 19.1877 5.0001C19.3095 4.99796 19.5821 5.02816 19.7586 5.17139C19.9076 5.29233 19.9486 5.45571 19.9683 5.57037C19.9879 5.68504 20.0123 5.94625 19.9929 6.15035C19.7712 8.47994 18.8118 14.1332 18.3237 16.7424C18.1172 17.8465 17.7105 18.2166 17.3169 18.2529C16.4613 18.3316 15.8116 17.6875 14.9829 17.1443C13.6862 16.2943 12.9537 15.7652 11.695 14.9357C10.2404 13.9772 11.1834 13.4504 12.0124 12.5894C12.2293 12.364 15.999 8.9353 16.0719 8.62426C16.0811 8.58536 16.0895 8.44035 16.0034 8.36379C15.9172 8.28722 15.7901 8.3134 15.6983 8.33423C15.5683 8.36374 13.4968 9.73289 9.48389 12.4417C8.89591 12.8454 8.36333 13.0421 7.88616 13.0318C7.36012 13.0205 6.34822 12.7344 5.59598 12.4899C4.67333 12.19 3.94002 12.0314 4.00388 11.5221C4.03714 11.2568 4.40248 10.9854 5.09992 10.7081Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_15526_2851">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TelegramLogo
