import React from 'react'

const GreenLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="158"
    height="36"
    viewBox="0 0 158 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_23266_6933)">
      <path
        d="M65.4291 28.8043L44.1953 15.3584L36.6578 22.1941L43.6045 28.8043H65.4291Z"
        fill="#34B78F"
      />
      <path
        d="M19.891 0H13.2845L31.1882 17.017L39.1554 12.1834L19.891 0Z"
        fill="#34B78F"
      />
      <path
        d="M53.1919 7.26855H47.2121L39.1554 12.1924L44.1953 15.3668L53.1919 7.26855Z"
        fill="#34B78F"
      />
      <path
        d="M0 35.9825H21.3412L36.6488 22.1939L31.1882 17.0176L0 35.9825Z"
        fill="#34B78F"
      />
      <path
        d="M81.2735 28.5235C80.0471 29.0105 78.7938 29.263 77.5495 29.263C75.9561 29.263 74.5327 28.9744 73.2795 28.3792C72.0262 27.775 70.943 26.9814 70.0658 25.9804C69.1795 24.9794 68.526 23.8341 68.0695 22.5175C67.613 21.2009 67.3892 19.8572 67.3892 18.4594C67.3892 16.8902 67.613 15.4023 68.0426 13.9955C68.4723 12.5977 69.099 11.3351 69.9494 10.28C70.7998 9.22491 71.8561 8.35918 73.1094 7.73693C74.3895 7.10567 75.8666 6.79004 77.5495 6.79004C78.7132 6.79004 79.7964 6.93433 80.8259 7.18683C81.8464 7.44836 82.7595 7.8722 83.583 8.47641C84.3798 9.04455 85.0601 9.79305 85.633 10.6768C86.1701 11.5606 86.5461 12.6518 86.743 13.9053H83.8964C83.7531 13.0756 83.5025 12.3902 83.1265 11.786C82.7595 11.1818 82.2761 10.6948 81.73 10.3251C81.1571 9.95537 80.5305 9.66679 79.8233 9.4684C79.1161 9.27 78.3731 9.17982 77.5764 9.17982C76.2694 9.17982 75.1594 9.44134 74.2463 9.95537C73.3063 10.4694 72.5633 11.1548 71.9725 12.0115C71.3727 12.8682 70.952 13.8421 70.6655 14.9333C70.3791 16.0245 70.2358 17.1337 70.2358 18.279C70.2358 19.4243 70.4059 20.4794 70.7192 21.5075C71.0326 22.5355 71.516 23.4554 72.1157 24.258C72.7424 25.0606 73.5122 25.6918 74.3895 26.1427C75.3026 26.6027 76.35 26.8552 77.5495 26.8552C78.6595 26.8552 79.6532 26.6838 80.5126 26.3141C81.363 25.9443 82.106 25.4574 82.6789 24.8261C83.2787 24.1948 83.6994 23.4554 84.0127 22.5987C84.326 21.7419 84.4424 20.8221 84.4066 19.8482H77.5764V17.4494H86.94V28.749H85.1496L84.4066 26.0616C83.5562 27.2339 82.4999 28.0365 81.2735 28.5235Z"
        fill="#5B6771"
      />
      <path
        d="M99.3742 7.29492C101.424 7.29492 102.991 7.80895 104.154 8.80995C105.291 9.81095 105.864 11.1817 105.864 12.9312C105.864 14.2478 105.578 15.3931 104.978 16.3671C104.378 17.341 103.438 18.0264 102.158 18.3691V18.4232C102.785 18.5404 103.268 18.7388 103.662 19.0274C104.065 19.316 104.378 19.6586 104.602 20.0284C104.826 20.4252 104.996 20.858 105.112 21.345C105.229 21.832 105.309 22.319 105.372 22.8601C105.399 23.3741 105.426 23.9152 105.461 24.4653C105.488 25.0063 105.515 25.5564 105.605 26.0705C105.694 26.5845 105.802 27.0985 105.945 27.5585C106.088 28.0184 106.312 28.4422 106.625 28.821H103.465C103.268 28.5955 103.125 28.3069 103.071 27.9011C103.018 27.5314 102.955 27.0985 102.928 26.6116C102.901 26.1516 102.901 25.6376 102.874 25.0965C102.848 24.5554 102.794 24.0053 102.704 23.4913C102.615 22.9773 102.534 22.4633 102.391 22.0033C102.275 21.5434 102.078 21.1196 101.818 20.7769C101.558 20.4342 101.218 20.1456 100.798 19.9472C100.368 19.7488 99.8308 19.6316 99.1146 19.6316H92.168V28.812H89.3213V7.29492H99.3653H99.3742ZM99.974 17.0524C100.574 16.9623 101.084 16.7639 101.541 16.4482C101.997 16.1597 102.337 15.7629 102.624 15.2759C102.883 14.7889 103.018 14.1576 103.018 13.355C103.018 12.2639 102.704 11.3801 102.105 10.6947C101.505 10.0094 100.538 9.66667 99.2041 9.66667H92.1501V17.1607H98.0672C98.7476 17.1877 99.3742 17.1336 99.974 17.0434V17.0524Z"
        fill="#5B6771"
      />
      <path
        d="M122.801 7.29492V9.69372H110.877V16.5294H121.977V18.9282H110.877V26.3681H122.891V28.7669H108.039V7.29492H122.81H122.801Z"
        fill="#5B6771"
      />
      <path
        d="M139.272 7.29492V9.69372H127.349V16.5294H138.449V18.9282H127.349V26.3681H139.362V28.7669H124.502V7.29492H139.272Z"
        fill="#5B6771"
      />
      <path
        d="M143.999 7.29492L155.243 24.7178H155.296V7.29492H158V28.7759H154.903L143.749 11.5244H143.695V28.7759H140.991V7.29492H144.008H143.999Z"
        fill="#5B6771"
      />
      <path
        d="M77.165 35.9095V32.0498H79.9401V32.5097H77.6753V33.6911H79.7969V34.142H77.6753V35.4586H80.0296V35.9185H77.165V35.9095Z"
        fill="#5B6771"
      />
      <path
        d="M80.8709 35.9095V32.0498H81.3901L83.4043 35.0799V32.0498H83.8877V35.9095H83.3684L81.3543 32.8704V35.9095H80.8619H80.8709Z"
        fill="#5B6771"
      />
      <path
        d="M86.6985 34.3949V33.944H88.3187V35.3779C88.0681 35.5763 87.8085 35.7296 87.5489 35.8288C87.2803 35.928 87.0118 35.9821 86.7343 35.9821C86.3583 35.9821 86.0181 35.9009 85.7048 35.7386C85.4004 35.5763 85.1677 35.3418 85.0066 35.0352C84.8454 34.7286 84.7738 34.3859 84.7738 34.0071C84.7738 33.6284 84.8544 33.2767 85.0066 32.952C85.1587 32.6274 85.3915 32.3839 85.6779 32.2306C85.9734 32.0682 86.3046 31.9961 86.6895 31.9961C86.967 31.9961 87.2177 32.0412 87.4415 32.1314C87.6653 32.2215 87.8443 32.3478 87.9696 32.5101C88.0949 32.6724 88.1934 32.8799 88.2561 33.1414L87.7995 33.2676C87.7458 33.0692 87.6742 32.9159 87.5847 32.8077C87.4952 32.6995 87.3788 32.6003 87.2177 32.5372C87.0565 32.465 86.8864 32.438 86.6895 32.438C86.4567 32.438 86.2598 32.4741 86.0897 32.5462C85.9196 32.6183 85.7854 32.7085 85.6779 32.8258C85.5705 32.943 85.49 33.0692 85.4362 33.2045C85.3378 33.439 85.293 33.7005 85.293 33.9801C85.293 34.3227 85.3557 34.6113 85.4721 34.8458C85.5884 35.0803 85.7585 35.2516 85.9823 35.3598C86.2061 35.4771 86.4478 35.5312 86.6985 35.5312C86.9222 35.5312 87.1281 35.4861 87.343 35.4049C87.5489 35.3147 87.71 35.2246 87.8174 35.1344V34.4129H86.6895L86.6985 34.3949Z"
        fill="#5B6771"
      />
      <path
        d="M89.3396 35.9095V32.0498H89.8499V35.9095H89.3396Z"
        fill="#5B6771"
      />
      <path
        d="M90.9328 35.9095V32.0498H91.452L93.4662 35.0799V32.0498H93.9495V35.9095H93.4303L91.4162 32.8704V35.9095H90.9238H90.9328Z"
        fill="#5B6771"
      />
      <path
        d="M95.0056 35.9095V32.0498H97.7807V32.5097H95.5159V33.6911H97.6375V34.142H95.5159V35.4586H97.8702V35.9185H95.0056V35.9095Z"
        fill="#5B6771"
      />
      <path
        d="M98.7386 35.9095V32.0498H101.514V32.5097H99.2489V33.6911H101.37V34.142H99.2489V35.4586H101.603V35.9185H98.7386V35.9095Z"
        fill="#5B6771"
      />
      <path
        d="M102.463 35.9095V32.0498H104.164C104.504 32.0498 104.763 32.0859 104.942 32.158C105.122 32.2302 105.265 32.3474 105.372 32.5278C105.48 32.6991 105.533 32.8975 105.533 33.1049C105.533 33.3755 105.444 33.6099 105.265 33.7993C105.086 33.9887 104.817 34.1059 104.45 34.16C104.584 34.2232 104.692 34.2863 104.754 34.3494C104.907 34.4847 105.041 34.656 105.175 34.8634L105.838 35.9185H105.202L104.692 35.1159C104.549 34.8815 104.423 34.7101 104.325 34.5839C104.226 34.4576 104.146 34.3765 104.065 34.3224C103.985 34.2682 103.913 34.2412 103.832 34.2232C103.779 34.2141 103.68 34.2051 103.555 34.2051H102.964V35.9185H102.454L102.463 35.9095ZM102.973 33.7542H104.065C104.298 33.7542 104.477 33.7272 104.611 33.6821C104.746 33.637 104.844 33.5558 104.907 33.4476C104.978 33.3394 105.005 33.2312 105.005 33.1049C105.005 32.9246 104.942 32.7712 104.808 32.654C104.674 32.5368 104.468 32.4737 104.182 32.4737H102.964V33.7452L102.973 33.7542Z"
        fill="#5B6771"
      />
      <path
        d="M106.536 35.9095V32.0498H107.046V35.9095H106.536Z"
        fill="#5B6771"
      />
      <path
        d="M108.129 35.9095V32.0498H108.648L110.662 35.0799V32.0498H111.146V35.9095H110.627L108.612 32.8704V35.9095H108.12H108.129Z"
        fill="#5B6771"
      />
      <path
        d="M113.957 34.3949V33.944H115.578V35.3779C115.327 35.5763 115.067 35.7296 114.808 35.8288C114.539 35.928 114.271 35.9821 113.993 35.9821C113.617 35.9821 113.277 35.9009 112.964 35.7386C112.659 35.5763 112.427 35.3418 112.265 35.0352C112.104 34.7286 112.033 34.3859 112.033 34.0071C112.033 33.6284 112.113 33.2767 112.265 32.952C112.418 32.6274 112.65 32.3839 112.937 32.2306C113.232 32.0682 113.563 31.9961 113.948 31.9961C114.226 31.9961 114.477 32.0412 114.7 32.1314C114.924 32.2215 115.103 32.3478 115.229 32.5101C115.354 32.6724 115.452 32.8799 115.515 33.1414L115.058 33.2676C115.005 33.0692 114.933 32.9159 114.844 32.8077C114.754 32.6995 114.638 32.6003 114.477 32.5372C114.315 32.465 114.145 32.438 113.948 32.438C113.716 32.438 113.519 32.4741 113.349 32.5462C113.179 32.6183 113.044 32.7085 112.937 32.8258C112.829 32.943 112.749 33.0692 112.695 33.2045C112.597 33.439 112.552 33.7005 112.552 33.9801C112.552 34.3227 112.615 34.6113 112.731 34.8458C112.847 35.0803 113.017 35.2516 113.241 35.3598C113.465 35.4771 113.707 35.5312 113.957 35.5312C114.181 35.5312 114.387 35.4861 114.602 35.4049C114.808 35.3147 114.969 35.2246 115.076 35.1344V34.4129H113.948L113.957 34.3949Z"
        fill="#5B6771"
      />
      <path
        d="M119.695 35.4595C119.543 35.6308 119.373 35.7661 119.185 35.8473C119.006 35.9374 118.8 35.9735 118.585 35.9735C118.192 35.9735 117.887 35.8382 117.654 35.5767C117.466 35.3603 117.377 35.1168 117.377 34.8463C117.377 34.6028 117.458 34.3863 117.61 34.197C117.762 34.0076 117.986 33.8362 118.29 33.6919C118.12 33.4935 118.004 33.3312 117.941 33.205C117.887 33.0787 117.851 32.9615 117.851 32.8442C117.851 32.6098 117.941 32.4114 118.12 32.24C118.299 32.0687 118.532 31.9785 118.8 31.9785C119.069 31.9785 119.275 32.0597 119.445 32.222C119.615 32.3843 119.695 32.5827 119.695 32.8082C119.695 33.1779 119.454 33.4845 118.97 33.7461L119.66 34.6298C119.74 34.4765 119.794 34.2962 119.839 34.0887L120.331 34.197C120.25 34.5306 120.134 34.8102 119.991 35.0266C120.17 35.2611 120.367 35.4595 120.591 35.6218L120.277 36.0006C120.089 35.8743 119.892 35.6939 119.687 35.4505L119.695 35.4595ZM119.4 35.0897L118.532 34.0076C118.281 34.1609 118.111 34.3052 118.021 34.4314C117.932 34.5667 117.887 34.6929 117.887 34.8192C117.887 34.9725 117.95 35.1348 118.075 35.3062C118.201 35.4775 118.371 35.5587 118.603 35.5587C118.747 35.5587 118.89 35.5136 119.042 35.4234C119.194 35.3332 119.311 35.216 119.4 35.0807V35.0897ZM118.738 33.4485C118.944 33.3222 119.078 33.223 119.132 33.1328C119.194 33.0426 119.221 32.9434 119.221 32.8262C119.221 32.7 119.176 32.5917 119.096 32.5016C119.015 32.4114 118.908 32.3753 118.782 32.3753C118.657 32.3753 118.55 32.4204 118.469 32.5016C118.389 32.5827 118.344 32.6819 118.344 32.8082C118.344 32.8623 118.362 32.9254 118.389 32.9976C118.415 33.0607 118.46 33.1328 118.523 33.205L118.738 33.4485Z"
        fill="#5B6771"
      />
      <path
        d="M125.075 34.5579L125.585 34.6842C125.478 35.108 125.29 35.4236 125.012 35.6401C124.735 35.8565 124.395 35.9737 124.001 35.9737C123.607 35.9737 123.249 35.8926 122.989 35.7212C122.73 35.5499 122.533 35.3064 122.399 34.9818C122.264 34.6571 122.193 34.3144 122.193 33.9447C122.193 33.5389 122.273 33.1872 122.425 32.8896C122.578 32.592 122.792 32.3665 123.079 32.2042C123.365 32.0419 123.67 31.9697 124.01 31.9697C124.395 31.9697 124.717 32.0689 124.977 32.2673C125.236 32.4657 125.424 32.7453 125.523 33.097L125.021 33.2142C124.932 32.9347 124.807 32.7272 124.636 32.601C124.466 32.4747 124.252 32.4116 124.001 32.4116C123.705 32.4116 123.464 32.4838 123.267 32.628C123.07 32.7723 122.927 32.9617 122.846 33.2052C122.766 33.4487 122.73 33.6922 122.73 33.9447C122.73 34.2693 122.774 34.5579 122.873 34.8014C122.971 35.0449 123.115 35.2343 123.321 35.3515C123.517 35.4687 123.741 35.5319 123.974 35.5319C124.261 35.5319 124.502 35.4507 124.699 35.2884C124.896 35.126 125.03 34.8826 125.102 34.5579H125.075Z"
        fill="#5B6771"
      />
      <path
        d="M126.158 34.0344C126.158 33.3941 126.328 32.8891 126.668 32.5284C127.008 32.1677 127.447 31.9873 127.993 31.9873C128.351 31.9873 128.664 32.0685 128.951 32.2398C129.237 32.4112 129.452 32.6456 129.604 32.9522C129.756 33.2588 129.828 33.6015 129.828 33.9893C129.828 34.3771 129.748 34.7288 129.595 35.0354C129.434 35.342 129.219 35.5765 128.924 35.7388C128.638 35.9011 128.324 35.9733 127.993 35.9733C127.635 35.9733 127.313 35.8831 127.026 35.7118C126.74 35.5314 126.525 35.2969 126.382 34.9903C126.238 34.6837 126.158 34.3681 126.158 34.0254V34.0344ZM126.677 34.0344C126.677 34.5033 126.802 34.8641 127.053 35.1346C127.304 35.4051 127.617 35.5404 127.984 35.5404C128.351 35.5404 128.682 35.4051 128.933 35.1346C129.175 34.8641 129.3 34.4853 129.3 33.9803C129.3 33.6647 129.246 33.3941 129.139 33.1596C129.031 32.9252 128.879 32.7448 128.673 32.6095C128.467 32.4743 128.244 32.4111 127.993 32.4111C127.635 32.4111 127.331 32.5374 127.071 32.7899C126.811 33.0424 126.686 33.4572 126.686 34.0344H126.677Z"
        fill="#5B6771"
      />
      <path
        d="M130.616 35.9095V32.0498H131.135L133.149 35.0799V32.0498H133.633V35.9095H133.114L131.099 32.8704V35.9095H130.607H130.616Z"
        fill="#5B6771"
      />
      <path
        d="M134.465 34.6751L134.94 34.63C134.967 34.8194 135.02 34.9817 135.101 35.108C135.181 35.2343 135.316 35.3335 135.486 35.4056C135.656 35.4868 135.853 35.5228 136.077 35.5228C136.274 35.5228 136.444 35.4958 136.587 35.4327C136.739 35.3785 136.847 35.2974 136.918 35.1892C136.99 35.0809 137.026 34.9818 137.026 34.8555C137.026 34.7292 136.99 34.63 136.918 34.5399C136.847 34.4497 136.73 34.3775 136.578 34.3144C136.471 34.2783 136.247 34.2062 135.898 34.125C135.548 34.0439 135.298 33.9627 135.155 33.8815C134.976 33.7824 134.832 33.6651 134.743 33.5208C134.653 33.3765 134.609 33.2142 134.609 33.0429C134.609 32.8445 134.662 32.6641 134.77 32.4928C134.877 32.3214 135.038 32.1952 135.253 32.105C135.468 32.0148 135.701 31.9697 135.96 31.9697C136.247 31.9697 136.497 32.0148 136.712 32.105C136.927 32.1952 137.097 32.3304 137.214 32.5108C137.33 32.6912 137.393 32.8986 137.402 33.124L136.909 33.1601C136.882 32.9166 136.793 32.7363 136.641 32.61C136.488 32.4838 136.265 32.4206 135.978 32.4206C135.692 32.4206 135.441 32.4747 135.307 32.592C135.173 32.7092 135.092 32.8445 135.092 32.9978C135.092 33.1331 135.146 33.2503 135.244 33.3405C135.343 33.4306 135.602 33.5208 136.014 33.611C136.426 33.7012 136.712 33.7914 136.864 33.8545C137.088 33.9537 137.249 34.089 137.357 34.2513C137.464 34.4136 137.518 34.594 137.518 34.8014C137.518 35.0088 137.455 35.1982 137.339 35.3785C137.222 35.5589 137.052 35.7032 136.838 35.8024C136.614 35.9016 136.372 35.9557 136.104 35.9557C135.754 35.9557 135.459 35.9016 135.226 35.8024C134.993 35.7032 134.805 35.5499 134.671 35.3425C134.537 35.1351 134.465 34.9096 134.465 34.6481V34.6751Z"
        fill="#5B6771"
      />
      <path
        d="M140.884 32.0498H141.394V34.2773C141.394 34.665 141.349 34.9807 141.26 35.2061C141.17 35.4316 141.018 35.6209 140.785 35.7652C140.562 35.9095 140.257 35.9817 139.89 35.9817C139.523 35.9817 139.237 35.9185 139.004 35.7923C138.771 35.666 138.61 35.4857 138.512 35.2512C138.413 35.0167 138.368 34.6921 138.368 34.2863V32.0588H138.879V34.2863C138.879 34.6199 138.906 34.8634 138.968 35.0258C139.031 35.1881 139.138 35.3053 139.29 35.3955C139.443 35.4857 139.631 35.5217 139.845 35.5217C140.221 35.5217 140.481 35.4406 140.642 35.2692C140.803 35.0979 140.884 34.7733 140.884 34.2863V32.0588V32.0498Z"
        fill="#5B6771"
      />
      <path
        d="M142.397 35.9095V32.0498H142.907V35.4586H144.796V35.9185H142.397V35.9095Z"
        fill="#5B6771"
      />
      <path
        d="M146.443 35.9098V32.5009H145.181V32.041H148.225V32.5009H146.954V35.9098H146.443Z"
        fill="#5B6771"
      />
      <path
        d="M148.959 35.9095V32.0498H149.469V35.9095H148.959Z"
        fill="#5B6771"
      />
      <path
        d="M150.552 35.9095V32.0498H151.071L153.085 35.0799V32.0498H153.569V35.9095H153.05L151.035 32.8704V35.9095H150.543H150.552Z"
        fill="#5B6771"
      />
      <path
        d="M156.379 34.3949V33.944H158V35.3779C157.749 35.5763 157.489 35.7296 157.23 35.8288C156.961 35.928 156.693 35.9821 156.415 35.9821C156.039 35.9821 155.699 35.9009 155.386 35.7386C155.081 35.5763 154.849 35.3418 154.687 35.0352C154.526 34.7286 154.455 34.3859 154.455 34.0071C154.455 33.6284 154.535 33.2767 154.687 32.952C154.84 32.6274 155.072 32.3839 155.359 32.2306C155.654 32.0682 155.985 31.9961 156.37 31.9961C156.648 31.9961 156.899 32.0412 157.122 32.1314C157.346 32.2215 157.525 32.3478 157.651 32.5101C157.776 32.6724 157.874 32.8799 157.937 33.1414L157.48 33.2676C157.427 33.0692 157.355 32.9159 157.266 32.8077C157.176 32.6995 157.06 32.6003 156.899 32.5372C156.737 32.465 156.567 32.438 156.37 32.438C156.138 32.438 155.941 32.4741 155.771 32.5462C155.601 32.6183 155.466 32.7085 155.359 32.8258C155.251 32.943 155.171 33.0692 155.117 33.2045C155.019 33.439 154.974 33.7005 154.974 33.9801C154.974 34.3227 155.037 34.6113 155.153 34.8458C155.269 35.0803 155.439 35.2516 155.663 35.3598C155.887 35.4771 156.129 35.5312 156.379 35.5312C156.603 35.5312 156.809 35.4861 157.024 35.4049C157.23 35.3147 157.391 35.2246 157.498 35.1344V34.4129H156.37L156.379 34.3949Z"
        fill="#5B6771"
      />
    </g>
    <defs>
      <clipPath id="clip0_23266_6933">
        <rect width="158" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default GreenLogo
