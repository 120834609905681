import React from 'react'

const HalsWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="148"
    height="36"
    viewBox="0 0 148 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M88.1227 5.05703C96.1854 12.9306 104.171 20.7278 112.26 28.6262C111.931 28.6578 111.734 28.6926 111.538 28.6926C110.415 28.6976 109.274 28.5698 108.172 28.7225C106.302 28.9846 105.044 28.2015 103.76 26.9125C98.7425 21.8791 93.6184 16.952 88.5338 11.9866C88.4187 11.8755 88.2904 11.7776 88.1079 11.62C86.9897 12.7 85.8862 13.7551 84.796 14.8218C80.203 19.3094 75.615 23.8019 71.0122 28.2795C70.8018 28.4852 70.4646 28.6694 70.1818 28.6744C68.1838 28.7075 66.1858 28.6926 63.928 28.6926C72.063 20.7444 80.0501 12.9405 88.121 5.05371L88.1227 5.05703Z"
      fill="white"
    />
    <path
      d="M142.916 10.842C141.755 12.2754 140.709 13.6009 139.61 14.8817C139.467 15.0492 139.051 15.0177 138.762 15.0177C132.842 15.0243 126.922 15.021 121.002 15.0293C120.568 15.0293 120.122 15.0642 119.703 15.167C118.721 15.4059 118.252 15.9749 118.239 16.9869C118.214 18.7753 118.206 20.5654 118.241 22.3538C118.267 23.6478 119.214 24.449 120.693 24.4623C122.913 24.4806 125.133 24.4673 127.353 24.4673C131.189 24.4673 135.027 24.4756 138.864 24.4557C139.349 24.4524 139.668 24.5884 139.967 24.9766C140.888 26.1694 141.85 27.3291 142.884 28.6114C142.527 28.6397 142.297 28.6728 142.069 28.6728C134.669 28.6878 127.27 28.6961 119.87 28.7126C118.017 28.7176 116.346 28.2581 115.034 26.8579C114.235 26.0052 113.788 25.0098 113.796 23.8103C113.812 21.3053 113.788 18.8019 113.804 16.2968C113.824 13.1198 116.085 10.8105 119.249 10.8022C126.895 10.7807 134.542 10.7939 142.189 10.7956C142.371 10.7956 142.554 10.8172 142.916 10.842Z"
      fill="white"
    />
    <path
      d="M42.3035 9.875C48.7201 16.1426 55.0693 22.3439 61.5846 28.7095C59.557 28.7095 57.7629 28.7244 55.9704 28.6912C55.7435 28.6879 55.4853 28.4772 55.3011 28.2997C54.4871 27.5233 53.7225 26.6921 52.8805 25.9489C52.5862 25.6885 52.1208 25.4877 51.7343 25.4861C45.4624 25.4579 39.1889 25.4579 32.917 25.4861C32.5305 25.4877 32.0767 25.6885 31.7708 25.939C31.0933 26.4931 30.4174 27.0803 29.8747 27.7605C29.2153 28.5867 28.4293 28.7758 27.4377 28.7277C26.0465 28.6614 24.6487 28.7111 23.0256 28.7111C29.5179 22.3672 35.877 16.1559 42.3035 9.875ZM47.9028 21.5858C46.1416 19.673 44.2653 17.6357 42.3429 15.5471C40.3235 17.6457 38.4094 19.6348 36.5331 21.5858H47.9044H47.9028Z"
      fill="white"
    />
    <path
      d="M32.1176 10.8242C30.6606 12.2062 29.3434 13.4852 27.9818 14.7145C27.7565 14.9186 27.3438 14.9899 27.0165 14.9916C21.6474 15.0082 16.2799 15.0032 10.9108 15.0032H9.87482V28.6533H5.49731V10.8242H32.1159H32.1176Z"
      fill="white"
    />
  </svg>
)

export default HalsWhiteLogo
