import React from 'react'

const SignalMiddleIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="55"
    height="36"
    viewBox="0 0 55 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.953125 35.0398L42.7971 35.2586C39.2089 35.0128 35.7297 33.936 32.6409 32.1155C29.5522 30.295 26.9407 27.7818 25.0176 24.7793L36.2063 18.6959C36.7945 20.3697 37.9185 21.8087 39.4082 22.7954C40.8979 23.7821 42.6724 24.2627 44.4633 24.1647C47.416 24.1647 48.924 23.4251 48.924 21.9564C48.924 21.1543 48.5971 20.4772 47.3527 19.9876C46.8207 19.7654 46.2748 19.5775 45.7182 19.4251C45.2542 19.248 44.4633 19.0605 43.356 18.748L40.7935 18.0709C31.6718 15.4876 27.0845 10.873 27.0845 4.22707C27.084 3.59996 27.1262 2.97353 27.2111 2.35205"
      fill="white"
    />
    <path
      d="M45.3877 5.02294C44.3538 2.51689 42.6041 1.23637 40.1546 1.23637C38.2459 1.23637 37.2598 2.05339 37.2598 3.12966C37.2598 3.89954 37.6972 4.45731 38.5163 4.86583C39.3912 5.27433 41.0295 5.78497 43.479 6.45273C44.998 6.87695 45.6819 7.05764 46.7715 7.46615L54.462 0.081543H54.4063L45.3877 5.02294Z"
      fill="white"
    />
  </svg>
)

export default SignalMiddleIcon
