import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Card from './Card'
import { IFeedback } from './types'

const feedbacks: IFeedback[] = [
  {
    photoId: 'autor1',
    name: '_feedback1_name',
    position: '_feedback1_position',
    text: '_feedback1_text',
  },
  {
    photoId: 'autor2',
    name: '_feedback2_name',
    position: '_feedback2_position',
    text: '_feedback2_text',
  },
  {
    photoId: 'autor3',
    name: '_feedback3_name',
    position: '_feedback3_position',
    text: '_feedback3_text',
  },
]

const FeedbackSgnx: React.FC = () => {
  const images = useStaticQuery(imagesQuery)

  return (
    <div className="columns is-multiline pb-4">
      {feedbacks.map(feedback => (
        <div
          className="column is-12-mobile is-6-tablet is-4-desktop flex"
          key={feedback.name}
        >
          <Card
            key={feedback.name}
            image={
              feedback.photoId &&
              images[feedback.photoId].childImageSharp.gatsbyImageData
            }
            className="mt-6"
            name={feedback.name}
            position={feedback.position}
            text={feedback.text}
          />
        </div>
      ))}
    </div>
  )
}

export default FeedbackSgnx

const imagesQuery = graphql`
  query {
    autor1: file(relativePath: { eq: "author/Nadine.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    autor2: file(relativePath: { eq: "author/Antony_S.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    autor3: file(relativePath: { eq: "author/Andrew_S.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
  }
`
