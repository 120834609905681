import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'

import Card from './Card'
import { IFeedback } from './types'
import Reviews from './Reviews'
import ArrowButton from '../Common/ArrowButton'

const feedbacks: IFeedback[] = [
  {
    photoId: 'author1',
    name: 'feedback_name1',
    position: 'feedback_position1',
    text: 'feedback_title1',
    reviews: {
      task: ['feedback_task1'],
      decision: ['feedback_decision1'],
      possibilities: ['feedback_possibilities1', 'feedback_possibilities1.2'],
    },
  },
  {
    photoId: 'author2',
    name: 'feedback_name2',
    position: 'feedback_position2',
    text: 'feedback_title2',
    reviews: {
      task: ['feedback_task2'],
      decision: ['feedback_decision2'],
      possibilities: [
        'feedback_possibilities2',
        ['feedback_possibilities2.1', 'feedback_possibilities2.2'],
      ],
    },
  },
  {
    name: 'feedback_name3',
    position: 'feedback_position3',
    text: 'feedback_title3',
    reviews: {
      task: ['feedback_task3'],
      decision: ['feedback_decision3'],
      possibilities: ['feedback_possibilities3', 'feedback_possibilities3.1'],
    },
  },
  {
    photoId: 'author4',
    name: 'feedback_name4',
    position: 'feedback_position4',
    text: 'feedback_title4',
    reviews: {
      task: ['feedback_task4'],
      decision: ['feedback_decision4', 'feedback_decision4.1'],
    },
  },
  {
    photoId: 'author5',
    name: 'feedback_name5',
    position: 'feedback_position5',
    text: 'feedback_title5',
    reviews: {
      task: ['feedback_task5'],
      decision: ['feedback_decision5'],
      possibilities: ['feedback_possibilities5'],
    },
  },
  {
    photoId: 'author6',
    name: 'feedback_name6',
    position: 'feedback_position6',
    text: 'feedback_title6',
    reviews: {
      task: ['feedback_task6'],
      decision: ['feedback_decision6'],
      possibilities: ['feedback_possibilities6', 'feedback_possibilities6.1'],
    },
  },
  {
    photoId: 'author7',
    name: 'feedback_name7',
    position: 'feedback_position7',
    text: 'feedback_title7',
    reviews: {
      task: ['feedback_task7'],
      decision: ['feedback_decision7'],
      possibilities: ['feedback_possibilities7', 'feedback_possibilities7.1'],
    },
  },
  {
    photoId: 'author8',
    name: 'feedback_name8',
    position: 'feedback_position8',
    text: 'feedback_title8',
    reviews: {
      task: ['feedback_task8'],
      decision: [
        'feedback_decision8',
        'feedback_decision8.1',
        'feedback_decision8.2',
      ],
    },
  },
  {
    photoId: 'author9',
    name: 'feedback_name9',
    position: 'feedback_position9',
    text: 'feedback_title9',
    reviews: {
      task: ['feedback_task9'],
      decision: [
        'feedback_decision9',
        'feedback_decision9.1',
        'feedback_decision9.2',
      ],
    },
  },
  {
    photoId: 'author10',
    name: 'feedback_name10',
    position: 'feedback_position10',
    text: 'feedback_title10',
    reviews: {
      task: ['feedback_task10'],
      decision: ['feedback_decision10', 'feedback_decision10.1'],
    },
  },
]

const FeedbackSgnl: React.FC = () => {
  const images = useStaticQuery(imagesQuery)
  const swiperRef = React.useRef<SwiperRef>(null)
  const [disabledNavigationPrev, setDisabledNavigationPrev] =
    React.useState(true)
  const [disabledNavigationNext, setDisabledNavigationNext] =
    React.useState(false)

  return (
    <>
      <Swiper
        ref={swiperRef}
        freeMode={true}
        grabCursor={true}
        navigation={true}
        pagination={{
          clickable: true,
          bulletClass: 'feedback_pagination',
          bulletActiveClass: 'active',
        }}
        onSlideChange={swiper => {
          setDisabledNavigationPrev(swiper.isBeginning)
          setDisabledNavigationNext(swiper.isEnd)
        }}
        slidesPerView="auto"
        className="feedback_swiper"
        wrapperClass="feedback_wrap"
        modules={[Pagination, Navigation]}
      >
        {feedbacks.map(feedback => (
          <SwiperSlide className="feedback_slide" key={feedback.name}>
            <div className="feedback_wrapper">
              <Card
                key={feedback.name}
                image={
                  feedback.photoId &&
                  images[feedback.photoId].childImageSharp.gatsbyImageData
                }
                className="feedback__card"
                name={feedback.name}
                position={feedback.position}
                text={feedback.text}
              >
                {feedback.reviews && <Reviews {...feedback.reviews} />}
              </Card>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <ArrowButton
        onClick={() => swiperRef.current?.swiper.slidePrev()}
        disabled={disabledNavigationPrev}
        arrowType="left"
        className="feedback_btn feedback_btn_prev"
      />
      <ArrowButton
        arrowType="right"
        onClick={() => swiperRef.current?.swiper.slideNext()}
        disabled={disabledNavigationNext}
        className="feedback_btn feedback_btn_next"
      />
    </>
  )
}

export default FeedbackSgnl

const imagesQuery = graphql`
  query {
    author1: file(relativePath: { eq: "author/Igor_Svibovich.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    author2: file(relativePath: { eq: "author/Timofey_Lyutomsky.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    author4: file(relativePath: { eq: "author/Mahamat_Rahmanali-Uulu.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    author5: file(relativePath: { eq: "author/Alexander_Polyankin.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    author6: file(relativePath: { eq: "author/Maria_Shemetova.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    author7: file(relativePath: { eq: "author/Evgeniy_Silyaev.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    author8: file(relativePath: { eq: "author/Azrael_Poshev.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    author9: file(relativePath: { eq: "author/Valentina_Suvorova.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    author10: file(relativePath: { eq: "author/Sergey_Kiselev.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
  }
`
