import React from 'react'
import cn from 'classnames'
import ArrowLeft from '../../../svg/arrow-left'
import ArrowRight from '../../../svg/arrow-right'
import * as s from './ArrowButton.module.scss'

interface ArrowButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  arrowType: 'left' | 'right'
}

const ArrowButton: React.FC<ArrowButtonProps> = ({
  arrowType,
  className,
  ...props
}) => {
  return (
    <button className={cn(s.button, className)} {...props}>
      {arrowType === 'left' ? (
        <ArrowLeft color={props.disabled ? '#DCDCDC' : '#0695D7'} />
      ) : (
        <ArrowRight color={props.disabled ? '#DCDCDC' : '#0695D7'} />
      )}
    </button>
  )
}

export default ArrowButton
