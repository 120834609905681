import { graphql, useStaticQuery } from 'gatsby'
import { INewsItem } from '../types'

export const useSolutionsData = (): INewsItem[] => {
  const images = useStaticQuery(imagesQuery)
  return [
    {
      id: '__6-REASONS-TO-USE-INSPECTION__',
      title: '6 reasons to digitaze construction QA/QC with SIGNAX INSPECTION',
      to: '/6-reasons-to-use-inspection/',
      image:
        images.six_reasons_to_use_inspection.childImageSharp.gatsbyImageData,
      date: '05.08.2024',
    },
    {
      id: '__BIM-MANAGEMENT-AUTOMATION__',
      title: 'SIGNAX TOOLS: Checker – Automated BIM manager for your company',
      to: '/bim-management-automation/',
      image: images.bim_management_automation.childImageSharp.gatsbyImageData,
      date: '09.07.2024',
    },
    {
      id: '__PLANNING-PHASE__',
      title: 'BIM services: Planning phase',
      to: '/planning-phase/',
      image: images.planning_phase.childImageSharp.gatsbyImageData,
      date: '13.05.2024',
    },
    {
      id: '__EXECUTION-PHASE__',
      title: 'BIM services: Execution phase',
      to: '/execution-phase/',
      image: images.execution_phase.childImageSharp.gatsbyImageData,
      date: '13.05.2024',
    },
    {
      id: '__OPERATIONAL-PHASE__',
      title: 'BIM services: Operational phase',
      to: '/operational-phase/',
      image: images.operational_phase.childImageSharp.gatsbyImageData,
      date: '13.05.2024',
    },
    {
      id: '__LASER-SCANNING__',
      title: 'Digital services: Laser scanning',
      to: '/laser-scanning/',
      image: images.laser_scanning.childImageSharp.gatsbyImageData,
      date: '15.04.2024',
    },
    {
      id: '__AERIAL-DRONE-SURVEY__',
      title: 'Digital services: Aerial Drone Survey',
      to: '/aerial-drone-survey/',
      image: images.aerial_drone_survey.childImageSharp.gatsbyImageData,
      date: '15.04.2024',
    },
    {
      id: '__REMOTE-INSPECTION__',
      title: 'Digital services: Remote Inspection (Photo360)',
      to: '/remote-inspection/',
      image: images.remote_inspection.childImageSharp.gatsbyImageData,
      date: '15.04.2024',
    },
    {
      id: '__MAKING-BIM-FOR-CONSTRUCTION-SITE__',
      title: 'Making BIM as great power of construction phase',
      to: '/making-bim-for-construction-site/',
      image:
        images.making_bim_for_construction_site.childImageSharp.gatsbyImageData,
      date: '29.03.2024',
    },
    {
      id: '__WORKING-WITH-REVIT-SHARED-COORDINATES__',
      title:
        'Working with Revit Shared Coordinates: Basic Tricks for Effective BIM Modeling',
      to: '/working-with-revit-shared-coordinates/',
      image:
        images.working_with_revit_shared_coordinates.childImageSharp
          .gatsbyImageData,
      date: '31.01.2024',
    },
    {
      id: '__BIM_CONSTRUCTION_PROJECT_MANAGEMENT__',
      title: 'BIM for construction project management',
      to: '/bim-construction-project-management/',
      image:
        images.bim_construction_project_management.childImageSharp
          .gatsbyImageData,
      date: '10.10.2023',
    },
    {
      id: '__BIM_PAPERLESS_CONSTRUCTION__',
      title: 'A paperless construction based on the bim model',
      to: '/bim-paperless-construction/',
      image: images.bim_paperless_construction.childImageSharp.gatsbyImageData,
      date: '17.08.2023',
    },
    {
      id: '__AUTOMATED-JOB-SITE-DOCUMENTATION__',
      title: 'The Real Power of Automated Job Site Documentation',
      to: '/automated-job-site-documentation/',
      image:
        images.automated_job_site_documentation.childImageSharp.gatsbyImageData,
    },
    {
      id: '__PROJECT-MANAGEMENT__',
      title:
        'The leader of construction management software in the United Arab Emirates',
      to: '/project-management/',
      image: images.project_management.childImageSharp.gatsbyImageData,
    },
    {
      id: '__BIM__',
      title: 'BIM 360 document management & BIM modeling',
      to: '/building-information-modeling/',
      image:
        images.building_information_modeling.childImageSharp.gatsbyImageData,
    },
    {
      id: '__DOCUMENT-MANAGEMENT__',
      title: 'Construction Document Management',
      to: '/document-management/',
      image: images.document_management.childImageSharp.gatsbyImageData,
    },
    {
      id: '__ERP-SOFTWARE-SYSTEM__',
      title: 'All-in-one ERP software for construction in Dubai',
      to: '/erp-software-system/',
      image: images.erp_software_system.childImageSharp.gatsbyImageData,
    },
  ]
}

const imagesQuery = graphql`
  query {
    six_reasons_to_use_inspection: file(
      relativePath: { eq: "solutions/6-reasons-to-use-inspection/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    bim_management_automation: file(
      relativePath: { eq: "solutions/bim-management-automation/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    planning_phase: file(
      relativePath: { eq: "solutions/planning-phase/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    execution_phase: file(
      relativePath: { eq: "solutions/execution-phase/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    operational_phase: file(
      relativePath: { eq: "solutions/operational-phase/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    laser_scanning: file(
      relativePath: { eq: "solutions/laser-scanning/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    aerial_drone_survey: file(
      relativePath: { eq: "solutions/aerial-drone-survey/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    remote_inspection: file(
      relativePath: { eq: "solutions/remote-inspection/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    making_bim_for_construction_site: file(
      relativePath: { eq: "making-bim-for-construction-site/preview.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    working_with_revit_shared_coordinates: file(
      relativePath: {
        eq: "solutions/working-with-revit-shared-coordinates/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    bim_construction_project_management: file(
      relativePath: {
        eq: "solutions/bim-construction-project-management/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    bim_paperless_construction: file(
      relativePath: { eq: "solutions/bim-paperless-construction/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    automated_job_site_documentation: file(
      relativePath: {
        eq: "solutions/automated-job-site-documentation/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    project_management: file(
      relativePath: { eq: "solutions/project-management/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    building_information_modeling: file(
      relativePath: {
        eq: "solutions/building-information-modeling/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
    document_management: file(
      relativePath: { eq: "solutions/document-management/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    erp_software_system: file(
      relativePath: { eq: "solutions/erp-software-system/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 716, placeholder: BLURRED)
      }
    }
  }
`
