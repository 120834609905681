import React, { HTMLAttributes } from 'react'
import cn from 'classnames'

import * as s from './List.module.scss'

const List: React.FC<HTMLAttributes<HTMLUListElement>> = ({
  className,
  ...props
}) => {
  return (
    <ul className={cn(s.wrapper, className)} {...props}>
      {props.children}
    </ul>
  )
}

export default List
