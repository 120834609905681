import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Container from '../Common/Container'
import Typography from '../Common/Typography'
import { Button } from '../Common/Button'

import ArrowLinkButton from '../../svg/arrow-link-button'

import * as s from './SwitchfromAutodesktoSignalDocsBlock.module.scss'
import { useTranslations } from '../../hooks/use-translations'

const SwitchfromAutodesktoSignalDocsBlock = () => {
  const images = useStaticQuery(imagesQuery)
  const { getPath } = useTranslations()

  return (
    <section className={s.section}>
      <GatsbyImage
        image={images.image_bg.childImageSharp.gatsbyImageData}
        className={s.imageBg}
        alt=""
      />
      <div className={s.overlay} />
      <Container className={s.container}>
        <div className={s.content}>
          <div className={s.column}>
            <Typography variant="h2" size={40} color="white" mb={16}>
              {`Перейдите c Autodesk BIM\u00A0360 / АСС на SIGNAL DOCS за 48 часов!`}
            </Typography>
            <Typography variant="body1" size={18} color="white" mb={32}>
              {`Подключитесь к SIGNAL DOCS в пятницу и\u00A0к\u00A0понедельнику получите уже готовую рабочую среду! Наша техническая поддержка обо всем позаботится. Трафик входит в стоимость подключения.`}
            </Typography>
            <Button
              className={s.btn}
              onClick={() => navigate(getPath('/bim-360-migrate/'))}
            >
              Подробнее
              <ArrowLinkButton className={s.arrow} />
            </Button>
          </div>
          <div className={s.image}>
            <GatsbyImage
              image={images.docs_image.childImageSharp.gatsbyImageData}
              alt=""
            />
          </div>
          <div className={s.image_wrap_mobile}>
            <GatsbyImage
              image={images.docs_image_mobile.childImageSharp.gatsbyImageData}
              className={s.image_mobile}
              alt=""
            />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default SwitchfromAutodesktoSignalDocsBlock

const imagesQuery = graphql`
  query {
    image_bg: file(
      relativePath: { eq: "home/switch_from_autodesk_to_signal_docs_bg.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    docs_image: file(relativePath: { eq: "home/mockup_docs_issues.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    docs_image_mobile: file(
      relativePath: { eq: "home/mockup_docs_issues_mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
