import React from 'react'
import { Link } from 'gatsby'
import { useTranslations } from '../../../hooks/use-translations'
import ArrowLinkButton from '../../../svg/arrow-link-button'
import * as s from './NavLinkButton.module.scss'

interface NavLinkButtonProps {
  title: string
  to: string
  download?: string | boolean
  isArrowIcon?: boolean
}

const NavLinkButton: React.FC<NavLinkButtonProps> = ({
  title,
  to,
  download,
  isArrowIcon,
}) => {
  const { getPath } = useTranslations()
  const isExternal = /^https?:\/\//.test(to)

  if (isExternal) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer">
        {title}
        {isArrowIcon && <ArrowLinkButton className={s.arrow} />}
      </a>
    )
  }

  if (download) {
    return (
      <a href={to} download={download} className={s.link}>
        {title}
        {isArrowIcon && <ArrowLinkButton className={s.arrow} />}
      </a>
    )
  }

  return (
    <Link to={getPath(to)} className={s.link}>
      {title}
      {isArrowIcon && <ArrowLinkButton className={s.arrow} />}
    </Link>
  )
}

export default NavLinkButton
