import React from 'react'

const X5GroupWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="126"
    height="40"
    viewBox="0 0 126 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_21870_120444)">
      <mask
        id="mask0_21870_120444"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="126"
        height="40"
      >
        <path d="M126 0H0V40H126V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_21870_120444)">
        <path
          d="M30.0517 33.0612L26.5082 26.932L23.0382 33.0612H18.8854L24.221 23.8283L18.7825 15.2568H23.1216L26.6062 20.9796L29.9291 15.2568H34.082L28.7316 24.1072L34.229 33.0612H30.0517Z"
          fill="white"
        />
        <path
          d="M47.7318 27.0086C47.7318 30.9201 44.8974 33.3674 41.1841 33.3674C37.9166 33.3674 35.1541 31.4865 34.8845 27.3895H38.428C38.6714 29.1701 40.0404 29.9405 41.532 29.9405C43.3895 29.9405 44.19 28.6429 44.19 26.9643C44.19 25.2093 42.9451 24.1157 41.2102 24.1157C39.7676 24.1157 38.8152 24.8283 38.3757 25.5919H34.9874L36.1571 15.2637H47.2956L46.9248 18.7228H38.9557L38.6403 22.1413C38.6403 22.1413 39.4768 21.1208 42.0123 21.1208C45.0689 21.1208 47.7302 23.2569 47.7302 27.0222"
          fill="white"
        />
        <path
          d="M35.3024 13.0392C33.4155 5.38606 27.428 0 15.0022 0H0L2.03722 14.3351C3.56799 27.2704 8.3204 31.432 16.7306 32.9643C16.7696 32.9752 16.8105 32.9759 16.8499 32.9665C16.8891 32.957 16.9257 32.9378 16.9562 32.9102C16.9866 32.8827 17.0102 32.8478 17.0247 32.8086C17.0392 32.7694 17.0442 32.7271 17.0394 32.6854C17.0398 32.63 17.0245 32.5758 16.9955 32.5296C16.9664 32.4833 16.9248 32.447 16.876 32.4251C11.1908 29.9337 10.5422 21.7619 9.32841 13.5629L8.389 7.02891H16.9528C24.8484 7.02891 30.9453 6.89966 34.8041 13.2449C34.8309 13.2903 34.8681 13.3282 34.9122 13.3553C34.9563 13.3822 35.0062 13.3975 35.0573 13.3996C35.0923 13.3994 35.1268 13.392 35.1588 13.3777C35.1909 13.3633 35.2198 13.3425 35.244 13.3164C35.2683 13.2902 35.2873 13.2593 35.2998 13.2254C35.3123 13.1915 35.3182 13.1553 35.3172 13.1191C35.3177 13.0919 35.3138 13.0649 35.3057 13.0392"
          fill="white"
        />
        <path
          d="M68.1237 23.7869C68.1237 23.7869 69.0107 33.6883 59.3981 33.6883C54.3336 33.6883 50.4128 29.6491 50.4128 24.1644C50.4128 18.6797 54.3435 14.6406 59.5452 14.6406C63.5526 14.6406 66.7497 17.0419 67.9668 20.7392H65.7156C64.6488 18.3107 62.342 16.7256 59.5255 16.7256C55.5181 16.7256 52.5497 19.8957 52.5497 24.2256C52.5497 28.4246 55.3662 31.6712 59.5255 31.6712C63.0265 31.6712 65.6895 29.3481 65.995 25.678H59.1612V23.8072H68.1237V23.7869Z"
          fill="white"
        />
        <path
          d="M78.9297 18.6362V20.8811C78.6346 20.8461 78.3379 20.8285 78.0409 20.8284C75.1232 20.8284 73.0942 22.808 73.0942 26.5325V33.3965H70.99V18.876H73.0942V22.1243C73.9307 19.9594 75.6052 18.5869 78.0655 18.5869C78.3543 18.5868 78.6429 18.6044 78.9297 18.6397"
          fill="white"
        />
        <path
          d="M94.3356 26.1367C94.3356 30.4938 91.1649 33.6877 87.1314 33.6877C83.1223 33.6877 79.9529 30.4667 79.9529 26.1367C79.9529 21.7796 83.1745 18.584 87.1314 18.584C91.1143 18.584 94.3356 21.7796 94.3356 26.1367ZM82.0832 26.1367C82.0832 29.3306 84.3149 31.6027 87.1314 31.6027C89.9478 31.6027 92.1955 29.3306 92.1955 26.1367C92.1955 22.9429 89.9381 20.6708 87.1314 20.6708C84.341 20.6708 82.0832 22.9156 82.0832 26.1367Z"
          fill="white"
        />
        <path
          d="M108.895 18.877V33.4008H106.783V30.5215C105.845 32.4228 104.221 33.6899 101.812 33.6899C98.7177 33.6899 96.8645 31.6303 96.8645 28.0912V18.877H98.9688V27.6694C98.9688 30.2579 100.137 31.6049 102.42 31.6049C105.185 31.6049 106.783 29.5453 106.783 26.3225V18.877H108.889H108.895Z"
          fill="white"
        />
        <path
          d="M126 26.1388C126 30.5232 123.261 33.6898 119.456 33.6898C118.367 33.7124 117.292 33.4242 116.348 32.8564C115.405 32.2886 114.629 31.4629 114.104 30.4687V40.001H111.998V18.8786H114.104V21.8616C114.627 20.8562 115.405 20.0193 116.354 19.4421C117.302 18.8649 118.383 18.5694 119.482 18.5877C123.261 18.5877 126 21.7306 126 26.1405V26.1388ZM123.877 26.1405C123.877 22.9704 121.898 20.6745 119.081 20.6745C116.265 20.6745 114.086 22.9449 114.086 26.1405C114.086 29.3361 116.242 31.6064 119.081 31.6064C121.921 31.6064 123.877 29.3343 123.877 26.1405Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_21870_120444">
        <rect width="126" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default X5GroupWhiteLogo
