// extracted by mini-css-extract-plugin
export var section = "SwitchfromAutodesktoSignalDocsBlock-module--section--aQQaK";
export var overlay = "SwitchfromAutodesktoSignalDocsBlock-module--overlay--BiDAd";
export var content = "SwitchfromAutodesktoSignalDocsBlock-module--content--EtCZQ";
export var container = "SwitchfromAutodesktoSignalDocsBlock-module--container--tjyWk";
export var imageBg = "SwitchfromAutodesktoSignalDocsBlock-module--imageBg--R89Fr";
export var column = "SwitchfromAutodesktoSignalDocsBlock-module--column--IcoB-";
export var image = "SwitchfromAutodesktoSignalDocsBlock-module--image--kA+NM";
export var image_wrap_mobile = "SwitchfromAutodesktoSignalDocsBlock-module--image_wrap_mobile--56hin";
export var image_mobile = "SwitchfromAutodesktoSignalDocsBlock-module--image_mobile--E7NB+";
export var btn = "SwitchfromAutodesktoSignalDocsBlock-module--btn--A-K1a";
export var arrow = "SwitchfromAutodesktoSignalDocsBlock-module--arrow--o-AF6";