import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import * as s from './EmployeeCard.module.scss'

interface EmployeeCardProps {
  author: IGatsbyImageData
  position: string
}

const EmployeeCard: React.FC<EmployeeCardProps> = ({
  author,
  position,
  children,
}) => {
  return (
    <div className={s.card}>
      <div className="flex">
        <div className={s.avatar}>
          <GatsbyImage image={author} alt={position} title={position} />
        </div>

        <div className={s.content}>
          <span className={s.position}>{position}</span>
          <div className={s.description}>{children}</div>
        </div>
      </div>
      <div className={s.description}>{children}</div>
    </div>
  )
}

export default EmployeeCard
