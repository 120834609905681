import React from 'react'

const HalsLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="148"
    height="36"
    viewBox="0 0 148 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M88.1228 5.05703C96.1855 12.9306 104.171 20.7278 112.26 28.6262C111.931 28.6578 111.734 28.6926 111.538 28.6926C110.415 28.6976 109.274 28.5698 108.172 28.7225C106.302 28.9846 105.044 28.2015 103.76 26.9125C98.7426 21.8791 93.6185 16.952 88.5339 11.9866C88.4188 11.8755 88.2905 11.7776 88.108 11.62C86.9898 12.7 85.8864 13.7551 84.7961 14.8218C80.2032 19.3094 75.6152 23.8019 71.0124 28.2795C70.8019 28.4852 70.4648 28.6694 70.1819 28.6744C68.1839 28.7075 66.1859 28.6926 63.9281 28.6926C72.0632 20.7444 80.0502 12.9405 88.1212 5.05371L88.1228 5.05703Z"
      fill="#E9292C"
    />
    <path
      d="M142.916 10.842C141.755 12.2754 140.709 13.6009 139.61 14.8817C139.467 15.0492 139.051 15.0177 138.762 15.0177C132.842 15.0243 126.922 15.021 121.002 15.0293C120.568 15.0293 120.122 15.0642 119.703 15.167C118.721 15.4059 118.252 15.9749 118.239 16.9869C118.214 18.7753 118.206 20.5654 118.241 22.3538C118.267 23.6478 119.214 24.449 120.693 24.4623C122.913 24.4806 125.133 24.4673 127.353 24.4673C131.189 24.4673 135.027 24.4756 138.864 24.4557C139.349 24.4524 139.668 24.5884 139.967 24.9766C140.888 26.1694 141.85 27.3291 142.884 28.6114C142.528 28.6397 142.297 28.6728 142.069 28.6728C134.669 28.6878 127.27 28.6961 119.87 28.7126C118.017 28.7176 116.346 28.2581 115.034 26.8579C114.235 26.0052 113.788 25.0098 113.796 23.8103C113.812 21.3053 113.788 18.8019 113.804 16.2968C113.824 13.1198 116.085 10.8105 119.249 10.8022C126.895 10.7807 134.542 10.7939 142.189 10.7956C142.371 10.7956 142.554 10.8172 142.916 10.842Z"
      fill="#3A3A3C"
    />
    <path
      d="M42.3036 9.875C48.7202 16.1426 55.0694 22.3439 61.5847 28.7095C59.5571 28.7095 57.763 28.7244 55.9706 28.6912C55.7436 28.6879 55.4854 28.4772 55.3013 28.2997C54.4873 27.5233 53.7226 26.6921 52.8806 25.9489C52.5863 25.6885 52.1209 25.4877 51.7345 25.4861C45.4626 25.4579 39.189 25.4579 32.9171 25.4861C32.5306 25.4877 32.0768 25.6885 31.7709 25.939C31.0934 26.4931 30.4175 27.0803 29.8749 27.7605C29.2154 28.5867 28.4294 28.7758 27.4378 28.7277C26.0466 28.6614 24.6488 28.7111 23.0258 28.7111C29.518 22.3672 35.8771 16.1559 42.3036 9.875ZM47.9029 21.5858C46.1417 19.673 44.2654 17.6357 42.343 15.5471C40.3237 17.6457 38.4095 19.6348 36.5332 21.5858H47.9046H47.9029Z"
      fill="#3A3A3C"
    />
    <path
      d="M32.1176 10.8242C30.6606 12.2062 29.3434 13.4852 27.9818 14.7145C27.7565 14.9186 27.3438 14.9899 27.0165 14.9916C21.6474 15.0082 16.2799 15.0032 10.9108 15.0032H9.87482V28.6533H5.49731V10.8242H32.1159H32.1176Z"
      fill="#3A3A3C"
    />
  </svg>
)

export default HalsLogo
