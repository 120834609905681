import React from 'react'

const PioneerWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="147"
    height="38"
    viewBox="0 0 147 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_21870_121544)">
      <path
        d="M11.4308 0C11.4442 0.0847422 11.4672 0.167514 11.4672 0.252256C11.4672 12.7586 11.4672 25.267 11.4672 37.7734V38.002H9.16603C9.1622 37.9231 9.15454 37.8384 9.15454 37.7556C9.15454 25.2571 9.15454 12.7567 9.15454 0.258168C9.15454 0.173426 9.16986 0.086713 9.17751 0.00197075C9.92797 0.00197075 10.6784 0.00197075 11.4289 0.00197075L11.4308 0Z"
        fill="white"
      />
      <path
        d="M97.3628 21.9719V24.2816H110.871V26.6189H97.3628V28.9128H110.867V31.2521H95.0923V19.6582H110.867V21.9719H97.3628Z"
        fill="white"
      />
      <path
        d="M128.916 19.6621V21.9659H115.419V24.2717H128.91V26.6149H115.412V28.9128H128.912V31.2462H113.137V19.6621H128.916Z"
        fill="white"
      />
      <path
        d="M145.861 26.9914C146.43 27.6003 146.822 28.296 146.914 29.1139C146.992 29.8135 146.973 30.5249 147 31.2482H144.718C144.718 30.7969 144.741 30.3495 144.71 29.9061C144.691 29.6145 144.642 29.305 144.521 29.0449C144.255 28.4734 143.77 28.1699 143.158 28.1679C139.974 28.1581 136.79 28.164 133.607 28.164C133.561 28.164 133.517 28.1699 133.444 28.1758V31.2502H131.191V19.6444H131.43C135.303 19.6444 139.174 19.6425 143.047 19.6444C145.12 19.6444 146.722 21.0772 146.954 23.1898C147.107 24.595 146.92 25.9311 145.863 26.9914H145.861ZM133.446 25.8188C133.505 25.8247 133.536 25.8306 133.566 25.8306C136.773 25.8306 139.978 25.8346 143.185 25.8286C143.933 25.8286 144.584 25.2295 144.682 24.4609C144.722 24.1437 144.731 23.8145 144.703 23.4972C144.619 22.569 143.964 21.9739 143.056 21.9739C139.942 21.9739 136.829 21.9739 133.714 21.9739C133.626 21.9739 133.538 21.9739 133.446 21.9739V25.8188Z"
        fill="white"
      />
      <path
        d="M66.8827 19.6521C68.3052 19.6521 69.7295 19.6324 71.1519 19.658C72.8768 19.6895 74.4716 21.1597 74.7224 22.9176C74.7568 23.1659 74.774 23.4182 74.776 23.6685C74.7817 24.8805 74.7798 26.0925 74.7779 27.3045C74.776 29.492 73.0817 31.2539 70.949 31.2638C68.2401 31.2756 65.5311 31.2736 62.8222 31.2638C60.6666 31.2559 58.978 29.492 58.978 27.271C58.978 26.0748 58.978 24.8785 58.978 23.6823C58.978 21.3804 60.6474 19.6541 62.8892 19.6462C64.2198 19.6403 65.5522 19.6462 66.8827 19.6462C66.8827 19.6482 66.8827 19.6521 66.8827 19.6541V19.6521ZM66.877 28.9186C68.2152 28.9186 69.5553 28.9186 70.8935 28.9186C71.8354 28.9186 72.5131 28.2209 72.5131 27.2552C72.5131 26.059 72.5131 24.8628 72.5131 23.6665C72.5131 22.6752 71.8316 21.9736 70.8648 21.9736C68.2018 21.9736 65.5407 21.9736 62.8777 21.9736C61.9397 21.9736 61.2505 22.6713 61.2447 23.635C61.239 24.847 61.239 26.059 61.2447 27.271C61.2505 28.2308 61.9282 28.9186 62.8586 28.9186C64.1968 28.9186 65.5369 28.9186 66.8751 28.9186H66.877Z"
        fill="white"
      />
      <path
        d="M38.6814 28.1629V31.2471H36.4338V19.6552C36.5142 19.6512 36.5889 19.6453 36.6636 19.6453C40.5441 19.6453 44.4228 19.6414 48.3033 19.6453C50.2331 19.6473 51.7934 20.9046 52.136 22.834C52.4021 24.3219 52.2567 25.7625 51.1673 26.9312C50.4054 27.749 49.4654 28.1629 48.3589 28.1629C45.2364 28.1629 42.114 28.1629 38.9934 28.1629C38.9015 28.1629 38.8116 28.1629 38.6833 28.1629H38.6814ZM38.689 25.8197C38.7541 25.8256 38.7982 25.8315 38.8441 25.8315C42.0355 25.8315 45.2249 25.8354 48.4163 25.8315C49.1668 25.8315 49.8234 25.2383 49.923 24.4756C49.9632 24.1741 49.9727 23.8627 49.9536 23.5592C49.8981 22.6172 49.2261 21.9787 48.311 21.9787C45.1886 21.9787 42.068 21.9787 38.9456 21.9787C38.8633 21.9787 38.7829 21.9787 38.689 21.9787V25.8236V25.8197Z"
        fill="white"
      />
      <path d="M4.59692 3.45898H6.8617V36.9755H4.59692V3.45898Z" fill="white" />
      <path
        d="M79.2917 21.975V31.2513H77.0327V19.6436C77.117 19.6436 77.1974 19.6436 77.2778 19.6436C81.1507 19.6436 85.0236 19.6436 88.8965 19.6436C90.9334 19.6436 92.4975 21.031 92.7981 23.0963C92.8211 23.2599 92.8268 23.4254 92.8268 23.591C92.8268 26.0702 92.8268 28.5494 92.8268 31.0305C92.8268 31.0995 92.8192 31.1685 92.8153 31.2513H90.562C90.562 31.1488 90.562 31.0562 90.562 30.9655C90.562 28.5415 90.562 26.1155 90.562 23.6915C90.562 22.6687 89.8862 21.975 88.8888 21.975C85.7893 21.975 82.6899 21.975 79.5885 21.975C79.4985 21.975 79.4085 21.975 79.2898 21.975H79.2917Z"
        fill="white"
      />
      <path d="M16.0296 35.9487H13.7515V3.4668H16.0296V35.9487Z" fill="white" />
      <path d="M18.3496 6.87012H20.6182V33.897H18.3496V6.87012Z" fill="white" />
      <path d="M0 17.9609H2.27626V33.8945H0V17.9609Z" fill="white" />
      <path
        d="M56.7116 31.2521H54.4871V19.6602H56.7116V31.2521Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_21870_121544">
        <rect width="147" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default PioneerWhiteLogo
