import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import Section from '../Common/Section'
import Typography from '../Common/Typography'
import ContactForm from '../contact-form'
import Container from '../Common/Container'
import List from '../Common/List'
import ModalDownloadFile from '../Common/ModalDownloadFile'
import { Button } from '../Common/Button'
import { isBrowser, toLink } from '../../utils'
import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'
import { signalMainDownloadPresentationLink } from '../../data'

import * as s from './BaseDescription.module.scss'

const BaseDescriptionSection: React.FC = () => {
  const images = useStaticQuery(imagesQuery)
  const { t } = useTranslations()
  const { region, location } = React.useContext(GlobalContext)
  const locationState = location?.state

  const [modal, setModal] = React.useState(false)
  const [presentationModal, setPresentationModal] = React.useState(false)

  useEffect(() => {
    if (isBrowser()) {
      if (locationState?.tryModalOpened) {
        setModal(true)
        locationState.tryModalOpened = false
      }
    }
  }, [locationState, t])

  useEffect(() => {
    if (isBrowser()) {
      if (locationState?.presentationModalOpened) {
        setPresentationModal(true)
        locationState.presentationModalOpened = false
      }
    }
  }, [locationState, t])

  return (
    <>
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a demo of Signal')}
          requestType="Demo"
        />
      )}
      {region === AppRegionEnum.Ru && presentationModal && (
        <ModalDownloadFile
          fileUrl={signalMainDownloadPresentationLink}
          title={t('Download presentation')}
          image={images.presentatio_preview.childImageSharp.gatsbyImageData}
          imageTitle="Стандарт цифрового строительства"
          onClose={() => setPresentationModal(false)}
        />
      )}
      <Section>
        <Container>
          <Typography variant="h2" size={40}>
            {t('Base description title')}
          </Typography>
          <div className={s.content}>
            <div className={s.column_description}>
              <List>
                <li>{t('Base description list 1')}</li>
                <li>{t('Base description list 2')}</li>
                <li>{t('Base description list 3')}</li>
                <li>{t('Base description list 4')}</li>
                <li>{t('Base description list 5')}</li>
              </List>
              <div className={s.buttons}>
                <Button
                  type="button"
                  onClick={() =>
                    region === AppRegionEnum.Ru
                      ? setPresentationModal(true)
                      : toLink(
                          'https://drive.google.com/file/d/14R5kJOcjjnN8wQQQt-bR38kW3AsFhmjh/view?usp=sharing'
                        )
                  }
                >
                  {t('Download presentation')}
                </Button>
                <Button color="white" onClick={() => setModal(true)}>
                  {region === AppRegionEnum.Ru
                    ? t('Request a demo_1')
                    : t('Request demo')}
                </Button>
              </div>
            </div>
            <div>
              <GatsbyImage
                image={
                  images[`screens_${region}`].childImageSharp.gatsbyImageData
                }
                alt={t('_image_alt1')}
                title={t('_image_title1')}
                className={cn(
                  region === AppRegionEnum.Us && 'is-hidden-mobile'
                )}
              />
              {region === AppRegionEnum.Us && (
                <GatsbyImage
                  image={images.screens_mobile.childImageSharp.gatsbyImageData}
                  alt={t('_image_alt1')}
                  title={t('_image_title1')}
                  className="is-hidden-tablet"
                />
              )}
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default BaseDescriptionSection

const imagesQuery = graphql`
  query {
    screens_us: file(relativePath: { eq: "home/signax-products.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600, placeholder: BLURRED)
      }
    }
    screens_ru: file(relativePath: { eq: "home/signal-products.png" }) {
      childImageSharp {
        gatsbyImageData(width: 546, placeholder: BLURRED)
      }
    }
    screens_mobile: file(
      relativePath: { eq: "home/signax-products-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 328, placeholder: BLURRED)
      }
    }
    presentatio_preview: file(
      relativePath: { eq: "signal-preview-presentation.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 552, placeholder: BLURRED)
      }
    }
  }
`
