import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import Modal from '../Modal'
import { Button } from '../Button'
import { useTranslations } from '../../../hooks/use-translations'

interface ModalDownloadFileProps {
  fileUrl: string
  title: string
  image: IGatsbyImageData
  imageTitle?: string
  onClose: () => void
}

const ModalDownloadFile: React.FC<ModalDownloadFileProps> = ({
  fileUrl,
  title,
  image,
  imageTitle,
  onClose,
}) => {
  const { t } = useTranslations()

  const onDownloadFile = () => {
    const link = document.createElement('a')
    link.href = fileUrl
    link.setAttribute('download', '')
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
  }

  return (
    <Modal onClose={onClose} title={title}>
      <GatsbyImage
        image={image}
        alt={imageTitle || title}
        title={imageTitle || title}
      />
      <Button className="mt-8" onClick={onDownloadFile} isFullWidth>
        {t('Download')}
      </Button>
    </Modal>
  )
}

export default ModalDownloadFile
