import React from 'react'

import Section from '../Common/Section'
import Container from '../Common/Container'
import Typography from '../Common/Typography'
import FeedbackSgnl from './FeedbackSgnl'
import FeedbackSgnx from './FeedbackSgnx'

import { GlobalContext } from '../../context/GlobalContext'
import { useTranslations } from '../../hooks/use-translations'
import { AppRegionEnum } from '../../types/app-region.enum'

import './feedback.styles.scss'

const Feedback: React.FC = () => {
  const { t } = useTranslations()
  const { region } = React.useContext(GlobalContext)
  return (
    <Section bgColor="light">
      <Container className="feedback_container">
        <Typography variant="h2" color="blue" size={40}>
          {t('Feedbacks')}
        </Typography>
        {region === AppRegionEnum.Ru ? <FeedbackSgnl /> : <FeedbackSgnx />}
      </Container>
    </Section>
  )
}

export default Feedback
