import React from 'react'

const MosinzhproektWhiteLogo: React.FC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    width="163"
    height="32"
    viewBox="0 0 163 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_21870_120464)">
      <path
        d="M15.8826 20.395L10.0327 31.8965H19.368L27.7274 15.8748L20.7622 3.28125H11.979L20.9016 20.395H15.8826Z"
        fill="white"
      />
      <path
        d="M8.3594 0L0 16.0217L6.96524 28.6153H15.7429L6.82582 11.5015H11.8448L17.6947 0H8.3594Z"
        fill="white"
      />
      <path
        d="M115.894 22.3359H119.497V14.5885H121.761C123.495 14.5287 125.107 12.8554 125.107 10.5246V6.1783C125.185 3.02176 123.032 2.0221 121.225 1.96777H115.9V22.3413L115.894 22.3359ZM119.497 11.4265C119.513 9.26421 119.525 7.10733 119.541 4.94502H120.032C120.896 4.90156 121.449 5.46658 121.476 6.81939C121.476 8.07439 121.46 8.37321 121.46 9.62278C121.46 10.7909 121.231 11.4157 120.199 11.4374C119.881 11.4374 119.815 11.4211 119.491 11.4211"
        fill="white"
      />
      <path
        d="M130.388 4.92805C129.936 4.92805 129.557 5.35182 129.557 5.86795V18.3691C129.557 18.8853 129.931 19.309 130.388 19.309H130.488C130.946 19.309 131.314 18.8853 131.314 18.3691V5.86795C131.314 5.35182 130.946 4.92805 130.488 4.92805H130.388ZM125.916 5.82991H125.955C126.021 3.48832 127.722 1.59766 129.797 1.59766H131.297C133.411 1.59766 135.145 3.56438 135.145 5.97117V18.2876C135.145 20.6944 133.411 22.6612 131.297 22.6612H129.797C127.873 22.6612 126.267 21.0313 125.994 18.9233L125.916 18.4778V5.82991Z"
        fill="white"
      />
      <path
        d="M37.9439 22.3354H34.8934V1.96191H40.3084L41.6468 15.1639H41.9312L43.2584 1.96191H48.6733V22.3354H45.3218V7.42202H45.0039L43.3086 22.3354H39.8678L38.2171 7.42202H37.9439V22.3354Z"
        fill="white"
      />
      <path
        d="M49.5433 5.82991V18.4724L49.6158 18.9179C49.8891 21.0258 51.4952 22.6557 53.4191 22.6557H54.9192C57.0328 22.6557 58.7615 20.689 58.7615 18.2822V5.97117C58.7615 3.56438 57.0328 1.59766 54.9192 1.59766H53.4191C51.3446 1.59766 49.6437 3.48832 49.5768 5.82991H49.5433ZM54.0158 4.92805H54.1162C54.5679 4.92805 54.9415 5.35182 54.9415 5.86795V18.3691C54.9415 18.8853 54.5679 19.309 54.1162 19.309H54.0158C53.5641 19.309 53.1905 18.8853 53.1905 18.3691V5.86795C53.1905 5.35182 53.5641 4.92805 54.0158 4.92805Z"
        fill="white"
      />
      <path
        d="M59.7316 5.82991H59.7706C59.8375 3.48832 61.5384 1.59766 63.6129 1.59766H65.113C67.2266 1.59766 68.9554 3.56438 68.9554 5.97117V9.33416H65.1353V5.86795C65.1353 5.35182 64.7617 4.92805 64.31 4.92805H64.2096C63.7579 4.92805 63.3843 5.35182 63.3843 5.86795V18.3691C63.3843 18.8853 63.7579 19.309 64.2096 19.309H64.31C64.7673 19.309 65.1353 18.8853 65.1353 18.3691V14.2727H68.9554V18.2822C68.9554 20.689 67.2266 22.6557 65.113 22.6557H63.6129C61.689 22.6557 60.0829 21.0258 59.8096 18.9179L59.7371 18.4724V5.82991H59.7316Z"
        fill="white"
      />
      <path
        d="M75.397 1.96191H79.0999V22.3354H75.7706V11.6488L73.4675 22.3354H69.6642V1.96191H73.0938V12.442L75.397 1.96191Z"
        fill="white"
      />
      <path
        d="M85.5298 1.96191H89.3387V22.3354H85.5298V12.6865H83.7062V22.3354H79.903V1.96191H83.7062V9.39417H85.5298V1.96191Z"
        fill="white"
      />
      <path
        d="M93.6606 1.96191H89.8182L92.1214 10.2471V11.7738L89.9242 22.3354H93.5211L95.3949 13.056H95.6068V22.3354H99.3599V13.056H99.5941L101.401 22.3354H105.26L102.845 11.8933V10.4319L105.204 1.96191H101.507L99.4993 10.1005V1.96191H95.6068V10.1276L93.6606 1.96191Z"
        fill="white"
      />
      <path
        d="M105.773 22.3354V1.96191H115.075V22.3354H111.243V5.25427H109.559V22.3354H105.773Z"
        fill="white"
      />
      <path
        d="M143.543 5.00979V1.96191H136.187V22.3354H143.543V19.1572H139.779V12.5344H143.543V9.35614H139.779V5.00979H143.543Z"
        fill="white"
      />
      <path
        d="M144.118 22.3354V1.96191H147.826V10.3286H148.166L150.219 1.96191H153.927L151.306 10.5894V11.3066L153.754 22.3354H150.046L148.049 12.692L147.765 12.6974V22.3354H144.118Z"
        fill="white"
      />
      <path
        d="M154.178 1.96191V5.24341H156.66V22.3354H160.519V5.19451H163V1.96191H154.178Z"
        fill="white"
      />
      <path
        d="M38.2004 27.1373V27.7023H35.5738V31.8639H34.8934V27.1318H38.2004V27.1373Z"
        fill="white"
      />
      <path
        d="M40.1526 31.8698H39.4723V27.1377H41.7476C42.6789 27.1377 43.2421 27.7027 43.2421 28.4688C43.2421 29.1262 42.8406 29.8759 41.7476 29.8759H40.1526V31.8698ZM40.1526 29.3272H41.5078C42.1212 29.3272 42.5394 29.1099 42.5394 28.4742C42.5394 27.882 42.0989 27.6864 41.5357 27.6864H40.1526V29.3272Z"
        fill="white"
      />
      <path
        d="M48.7849 27.1377L46.8888 31.12C46.5486 31.8426 46.2084 32.0002 45.7958 32.0002C45.6229 32.0002 45.4389 31.973 45.2995 31.9458V31.4188C45.4166 31.4406 45.5281 31.446 45.6954 31.446C45.9519 31.446 46.0244 31.3754 46.2531 30.9896L44.2957 27.1377H45.0262L46.5765 30.3703L48.0432 27.1377H48.7737H48.7849Z"
        fill="white"
      />
      <path
        d="M54.0992 27.1377V31.8698H53.4188V27.7082H50.7811V31.8698H50.1007V27.1377H54.1047H54.0992Z"
        fill="white"
      />
      <path
        d="M59.8271 27.1377V31.8698H59.1467V27.7082H56.509V31.8698H55.8286V27.1377H59.8327H59.8271Z"
        fill="white"
      />
      <path
        d="M62.3696 30.4898L61.8399 31.8698H61.1372L63.0388 27.1377H63.814L65.6376 31.8698H64.8903L64.3884 30.4898H62.3696ZM64.143 29.9302L63.4013 27.844H63.3846L62.576 29.9302H64.143Z"
        fill="white"
      />
      <path
        d="M70.2658 29.43L72.714 27.1373H73.6564L71.554 29.0442L73.7122 31.8639H72.8311L71.0633 29.4897L70.2714 30.2014V31.8639H69.5911V27.1318H70.2714V29.4245L70.2658 29.43Z"
        fill="white"
      />
      <path
        d="M77.0302 31.9941C75.1453 31.9941 74.5709 30.5273 74.5709 29.5059C74.5709 28.4845 75.1397 27.0176 77.0302 27.0176C78.9207 27.0176 79.4895 28.4845 79.4895 29.5059C79.4895 30.5273 78.9207 31.9941 77.0302 31.9941ZM77.0302 27.5826C75.9316 27.5826 75.2736 28.3758 75.2736 29.4896C75.2736 30.6033 75.9261 31.4237 77.0302 31.4237C78.1344 31.4237 78.7869 30.6142 78.7869 29.5004C78.7869 28.3867 78.1344 27.5772 77.0302 27.5772V27.5826Z"
        fill="white"
      />
      <path
        d="M81.9826 27.1377L83.4158 31.1363H83.4326L84.8713 27.1377H85.8417V31.8698H85.1781V29.0718C85.1781 28.936 85.2004 28.3275 85.2004 27.8929H85.1948L83.7672 31.8698H83.0924L81.6703 27.8929H81.648C81.648 28.3221 81.6703 28.936 81.6703 29.0718V31.8698H81.0067V27.1377H81.9826Z"
        fill="white"
      />
      <path
        d="M91.569 27.1377V31.8698H90.8887V27.7082H88.2509V31.8698H87.5706V27.1377H91.5746H91.569Z"
        fill="white"
      />
      <path
        d="M94.1063 30.4898L93.5766 31.8698H92.8739L94.7755 27.1377H95.5507L97.3743 31.8698H96.627L96.1251 30.4898H94.1063ZM95.8797 29.9302L95.138 27.844H95.1213L94.3127 29.9302H95.8797Z"
        fill="white"
      />
      <path
        d="M99.3596 29.0936H101.997V27.1377H102.678V31.8698H101.997V29.6586H99.3596V31.8698H98.6792V27.1377H99.3596V29.0936Z"
        fill="white"
      />
      <path
        d="M105.092 31.0108H105.12L107.68 27.1426H108.46V31.8747H107.78V28.0553H107.763L105.193 31.8747H104.406V27.1426H105.087V31.0108H105.092Z"
        fill="white"
      />
      <path
        d="M110.864 31.0111H110.892L113.452 27.1428H114.233V31.8749H113.552V28.0556H113.536L110.965 31.8749H110.178V27.1428H110.859V31.0111H110.864ZM112.158 26.3714C112.632 26.3714 112.777 26.1649 112.861 25.877H113.201C113.173 26.1106 113.084 26.7788 112.158 26.7788C111.232 26.7788 111.138 26.116 111.11 25.877H111.456C111.545 26.1649 111.679 26.3714 112.158 26.3714Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_21870_120464">
        <rect width="163" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default MosinzhproektWhiteLogo
