import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import cn from 'classnames'

import NavLinkButton from '../Common/NavLinkButton'
import { useTranslations } from '../../hooks/use-translations'
import { IClients } from './types'

import './Clients.styles.scss'

interface CardProps extends Omit<IClients, 'photoId'> {
  className?: string
  image: IGatsbyImageData
}

export const ClientsCard: React.FC<CardProps> = ({
  name,
  image,
  className,
  position,
  text,
  path,
}) => {
  const { t } = useTranslations()

  return (
    <div className={cn('clients-card', className)}>
      <div>
        <div className="clients-photo">
          {image && (
            <GatsbyImage
              image={image}
              imgStyle={{ borderRadius: '50%', backgroundColor: 'c4c4c4' }}
              alt={t(name)}
              title={t(name)}
            />
          )}
        </div>
        <div className="clients-author">{t(name)}</div>
        <div className="clients-position">{t(position)}</div>
        <p className="clients-text">{t(text)}</p>
      </div>
      {path && <NavLinkButton to={path} title={t('Read full')} isArrowIcon />}
    </div>
  )
}
