import React from 'react'

const AkvilonLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="135"
    height="40"
    viewBox="0 0 135 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_23266_6292)">
      <mask
        id="mask0_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_23266_6292)">
        <path
          d="M21.0851 24.5159L13.1955 6.5896V6.54351C12.7341 5.48361 11.6268 4.7002 10.3811 4.7002H8.85851C8.81237 4.7002 8.72009 4.74628 8.72009 4.79236L0 24.5159V24.5619C0 24.608 0.0461381 24.6541 0.0922761 24.6541H3.69105C3.73718 24.6541 3.82946 24.608 3.82946 24.5619L5.8134 20.0919H13.2416C13.2416 18.3868 11.8575 17.0043 10.1504 17.0043H7.1514L10.4733 9.07808L14.1182 17.7417L17.1172 24.5619C17.1634 24.608 17.2095 24.6541 17.2556 24.6541H20.9005C21.039 24.7002 21.0851 24.6541 21.0851 24.5159C21.0851 24.5619 21.0851 24.5619 21.0851 24.5159Z"
          fill="#64696F"
        />
      </g>
      <mask
        id="mask1_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask1_23266_6292)">
        <path
          d="M50.5214 16.7286C51.8133 16.1756 52.7822 15.1617 52.7822 13.2263C52.7822 10.876 50.7983 9.40137 47.7531 9.40137H40.0481C39.9558 9.40137 39.9097 9.49353 39.9097 9.53962V24.4705C39.9097 24.5627 40.0019 24.6087 40.0481 24.6087H47.7993C51.5365 24.6087 53.4281 23.0419 53.4281 20.4613C53.4743 18.5719 52.3669 17.2816 50.5214 16.7286ZM43.4162 12.2124H47.1533C48.4913 12.2124 49.2296 12.9037 49.2296 13.8714C49.2296 14.9774 48.4913 15.6226 47.1533 15.6226H43.4162V12.2124ZM47.6147 21.9359H43.4162V18.111H47.6147C49.0911 18.111 49.9216 18.8023 49.9216 20.0465C49.9216 21.1064 49.1373 21.9359 47.6147 21.9359Z"
          fill="#64696F"
        />
      </g>
      <mask
        id="mask2_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask2_23266_6292)">
        <path
          d="M99.9812 9.12402C95.3674 9.12402 91.8148 12.5342 91.8148 17.0964C91.8148 21.6586 95.3674 25.0687 99.9812 25.0687C104.595 25.0687 108.148 21.6586 108.148 17.0964C108.148 12.5342 104.595 9.12402 99.9812 9.12402ZM99.9812 22.0273C97.1668 22.0273 95.3213 19.9535 95.3213 17.0964C95.3213 14.2853 97.1668 12.1655 99.9812 12.1655C102.796 12.1655 104.641 14.2853 104.641 17.0964C104.641 19.9074 102.749 22.0273 99.9812 22.0273Z"
          fill="#64696F"
        />
      </g>
      <mask
        id="mask3_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask3_23266_6292)">
        <path
          d="M113.269 9.44629H111.47C111.378 9.44629 111.331 9.53845 111.331 9.58454V24.5154C111.331 24.6076 111.424 24.6537 111.47 24.6537H114.653C114.746 24.6537 114.792 24.5615 114.792 24.5154V11.0131C114.838 10.1375 114.146 9.44629 113.269 9.44629Z"
          fill="#64696F"
        />
      </g>
      <mask
        id="mask4_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask4_23266_6292)">
        <path
          d="M123.973 9.44629H122.219C122.127 9.44629 122.081 9.53845 122.081 9.58454V15.6214H116.222C116.222 17.2343 117.513 18.5246 119.128 18.5246H122.035V24.5154C122.035 24.6076 122.127 24.6537 122.173 24.6537H125.357C125.449 24.6537 125.495 24.5615 125.495 24.5154V11.0131C125.541 10.1375 124.849 9.44629 123.973 9.44629Z"
          fill="#64696F"
        />
      </g>
      <mask
        id="mask5_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask5_23266_6292)">
        <path
          d="M87.0162 9.44629H79.3112H79.265C78.6191 9.44629 78.0193 9.90712 77.8348 10.5062L73.3594 24.5615C73.3594 24.5615 73.3594 24.5615 73.3594 24.6076C73.3594 24.6537 73.4055 24.6997 73.4516 24.6997H75.8047C76.4968 24.6997 77.0966 24.2389 77.2811 23.5938L80.7876 12.4417H85.0323V24.5154C85.0323 24.6076 85.1246 24.6537 85.1707 24.6537H88.3542C88.4465 24.6537 88.4927 24.5615 88.4927 24.5154V11.0131C88.6311 10.1375 87.8929 9.44629 87.0162 9.44629Z"
          fill="#64696F"
        />
      </g>
      <mask
        id="mask6_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask6_23266_6292)">
        <path
          d="M70.73 9.44629H67.6849C67.5926 9.44629 67.5465 9.49237 67.5003 9.53846L60.3028 19.4463V11.0131C60.3028 10.1375 59.6107 9.44629 58.7341 9.44629H56.9809C56.8886 9.44629 56.8424 9.53845 56.8424 9.58454V24.5154C56.8424 24.6076 56.9347 24.6537 56.9809 24.6537H59.9798C60.0721 24.6537 60.1182 24.6076 60.1644 24.5615L67.3619 14.6997V24.5154C67.3619 24.6076 67.4542 24.6537 67.5003 24.6537H70.6839C70.7761 24.6537 70.8223 24.5615 70.8223 24.5154V9.58454C70.8684 9.49237 70.8223 9.44629 70.73 9.44629Z"
          fill="#64696F"
        />
      </g>
      <mask
        id="mask7_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask7_23266_6292)">
        <path
          d="M32.1582 16.6813L36.8182 9.63062V9.58454C36.8182 9.53845 36.772 9.49237 36.7259 9.49237H33.2194C33.1733 9.49237 33.1271 9.53845 33.081 9.58454L29.067 15.5753H26.7139V11.0131C26.7139 10.1375 26.0219 9.44629 25.1452 9.44629H23.392C23.2997 9.44629 23.2536 9.53845 23.2536 9.58454V24.5154C23.2536 24.6076 23.3458 24.6537 23.392 24.6537H26.5755C26.6678 24.6537 26.7139 24.5615 26.7139 24.5154V18.3403H29.3899L33.4501 24.6076C33.4962 24.6537 33.5424 24.6997 33.5885 24.6997H37.095C37.1411 24.6997 37.1873 24.6537 37.1873 24.6076V24.5615L32.1582 16.6813Z"
          fill="#64696F"
        />
      </g>
      <mask
        id="mask8_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask8_23266_6292)">
        <path
          d="M125.542 4.60829C128.08 4.60829 130.156 2.53456 130.156 0H130.34C130.34 2.53456 132.417 4.60829 134.954 4.60829V4.79263C132.417 4.79263 130.34 6.86636 130.34 9.40092H130.156C130.156 6.86636 128.08 4.79263 125.542 4.79263V4.60829Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask9_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask9_23266_6292)">
        <path
          d="M15.6405 33.2256C16.1942 33.2256 16.6556 33.4099 16.9785 33.7325C17.3015 34.0551 17.4861 34.5159 17.4861 35.0689C17.4861 35.6219 17.3015 36.0827 16.9785 36.4053C16.6556 36.7279 16.1942 36.8661 15.6405 36.8661H14.2564V38.5251C14.2564 38.6173 14.2564 38.7095 14.2103 38.7095C14.1641 38.7555 14.118 38.7555 13.9796 38.7555H13.5182C13.4259 38.7555 13.3336 38.7555 13.2875 38.7095C13.2414 38.6634 13.2414 38.6173 13.2414 38.5251V33.5021C13.2414 33.4099 13.2414 33.3178 13.2875 33.3178C13.3336 33.2717 13.3798 33.2717 13.5182 33.2717L15.6405 33.2256ZM15.5483 35.9445C15.8251 35.9445 16.0558 35.8523 16.2403 35.7141C16.3788 35.5758 16.471 35.3454 16.471 35.0689C16.471 34.7924 16.3788 34.562 16.2403 34.4237C16.1019 34.2855 15.8712 34.1933 15.5483 34.1933H14.2564V35.9445H15.5483Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask10_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask10_23266_6292)">
        <path
          d="M22.6542 38.5263C22.7003 38.7106 22.6542 38.8028 22.4696 38.8028H22.0544C21.8698 38.8028 21.7776 38.8028 21.7314 38.7567C21.6853 38.7106 21.6391 38.6645 21.593 38.5724L21.2239 37.5585H19.0554L18.6863 38.5724C18.6402 38.6645 18.594 38.7106 18.5479 38.7567C18.5018 38.8028 18.3633 38.8028 18.2249 38.8028H17.8558C17.6713 38.8028 17.6251 38.7106 17.6713 38.5263L19.6552 33.4111C19.7013 33.3189 19.7475 33.2728 19.7936 33.2267C19.8398 33.1807 19.8859 33.1807 20.0243 33.1807H20.4396C20.5318 33.1807 20.6241 33.1807 20.6702 33.2267C20.7164 33.2728 20.7625 33.3189 20.8087 33.4111L22.6542 38.5263ZM20.3473 35.1161C20.3011 34.9779 20.255 34.8396 20.2089 34.6553C20.1627 34.5171 20.1627 34.3788 20.1627 34.2867V34.1484H20.1166C20.1166 34.4249 20.0705 34.7475 19.932 35.1161L19.3322 36.7751H20.9471L20.3473 35.1161Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask11_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask11_23266_6292)">
        <path
          d="M27.1289 34.7002C27.1289 35.2532 26.8982 35.668 26.483 35.8984C27.0366 36.1288 27.2673 36.5896 27.2673 37.2348C27.2673 37.7417 27.0828 38.1565 26.7598 38.479C26.4369 38.7555 25.8832 38.9399 25.1911 38.9399C23.9454 38.9399 23.161 38.479 22.8381 37.6035C22.7919 37.4191 22.8381 37.327 23.0226 37.2809L23.4379 37.1426C23.5302 37.0966 23.6224 37.0966 23.6686 37.1426C23.7147 37.1887 23.7608 37.2348 23.807 37.327C23.8993 37.5574 24.0377 37.7417 24.2684 37.88C24.4991 38.0182 24.822 38.0643 25.2373 38.0643C25.9293 38.0643 26.2523 37.7878 26.2523 37.2809C26.2523 37.0505 26.2062 36.8201 26.0677 36.6818C25.9293 36.5436 25.7448 36.4514 25.5141 36.4514H24.4068C24.3145 36.4514 24.2222 36.4514 24.1761 36.4053C24.1299 36.3592 24.1299 36.3131 24.1299 36.221V35.8523C24.1299 35.7602 24.1299 35.668 24.1761 35.6219C24.2222 35.5758 24.2684 35.5758 24.4068 35.5758H25.3757C25.6064 35.5758 25.7909 35.5297 25.9293 35.3915C26.0677 35.2532 26.1139 35.0689 26.1139 34.8385C26.1139 34.3316 25.7909 34.0551 25.145 34.0551C24.7759 34.0551 24.4529 34.1012 24.2684 34.2394C24.0838 34.3316 23.9454 34.5159 23.8531 34.7924C23.807 34.8846 23.7608 34.9307 23.7147 34.9767C23.6686 35.0228 23.5763 34.9767 23.484 34.9767L23.0688 34.8385C22.8842 34.7924 22.8381 34.6542 22.8842 34.5159C23.2072 33.6403 23.9454 33.2256 25.1911 33.2256C25.7909 33.2256 26.2523 33.3638 26.5753 33.6403C26.9905 33.8247 27.1289 34.1933 27.1289 34.7002Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask12_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask12_23266_6292)">
        <path
          d="M32.1124 33.2267C32.297 33.2267 32.297 33.3189 32.2509 33.5032L29.8978 38.5724C29.8517 38.6645 29.8055 38.7567 29.7594 38.7567C29.7133 38.7567 29.621 38.8028 29.4826 38.8028H28.9289C28.7444 38.8028 28.6982 38.7106 28.7905 38.5263L29.4826 37.1438L27.5909 33.4572C27.4986 33.2728 27.5448 33.1807 27.7293 33.1807H28.1907C28.3291 33.1807 28.4214 33.1807 28.4675 33.1807C28.5137 33.1807 28.5598 33.2267 28.6059 33.2267C28.6521 33.2728 28.6982 33.3189 28.7444 33.4111L30.0362 35.9917L31.2358 33.4111C31.282 33.3189 31.3281 33.2267 31.3742 33.2267C31.4204 33.1807 31.5588 33.1807 31.6972 33.1807H32.1124V33.2267Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask13_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask13_23266_6292)">
        <path
          d="M37.7871 33.2256C37.8794 33.2256 37.9716 33.2256 38.0178 33.2717C38.0639 33.3178 38.0639 33.3638 38.0639 33.456V38.479C38.0639 38.5712 38.0639 38.6634 38.0178 38.7095C37.9716 38.7555 37.9255 38.7555 37.7871 38.7555H37.3718C37.2796 38.7555 37.1873 38.7555 37.1873 38.7095C37.1873 38.6634 37.1411 38.6173 37.1411 38.479V36.0827C37.1411 35.6219 37.1873 35.115 37.3257 34.5159H37.2796C37.1411 34.9767 36.9105 35.4376 36.6336 35.9445L35.8493 37.2809C35.757 37.4191 35.6647 37.4652 35.5263 37.4652H35.2956C35.2033 37.4652 35.1572 37.4652 35.1111 37.4191C35.0649 37.3731 35.0188 37.327 34.9727 37.2809L34.1883 35.8984C33.9576 35.4837 33.7269 35.0228 33.5885 34.4698H33.5424C33.6346 35.0228 33.7269 35.5297 33.7269 36.0366V38.433C33.7269 38.5251 33.7269 38.6173 33.6808 38.6634C33.6346 38.7095 33.5885 38.7095 33.4962 38.7095H33.1271C33.0349 38.7095 32.9426 38.7095 32.8964 38.6634C32.758 38.7095 32.758 38.6173 32.758 38.5251V33.5021C32.758 33.4099 32.758 33.3178 32.8042 33.3178C32.8503 33.2717 32.8964 33.2717 33.0349 33.2717H33.3578C33.4501 33.2717 33.5424 33.2717 33.5885 33.3178C33.6347 33.3638 33.6808 33.4099 33.7269 33.5021L35.3418 36.4053L36.9566 33.5021C37.0027 33.4099 37.0489 33.3638 37.095 33.3178C37.1411 33.2717 37.2334 33.2717 37.3257 33.2717L37.7871 33.2256Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask14_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask14_23266_6292)">
        <path
          d="M43.0468 33.2256C43.1391 33.2256 43.2314 33.2256 43.2775 33.2717C43.3236 33.3178 43.3236 33.3638 43.3236 33.456V38.479C43.3236 38.5712 43.3236 38.6634 43.2775 38.7095C43.2314 38.7555 43.1852 38.7555 43.0468 38.7555H42.5854C42.4932 38.7555 42.4009 38.7555 42.3547 38.7095C42.3086 38.6634 42.3086 38.6173 42.3086 38.479V36.4053H40.0017V38.479C40.0017 38.5712 40.0017 38.6634 39.9556 38.7095C39.9094 38.7555 39.8633 38.7555 39.7249 38.7555H39.2635C39.1712 38.7555 39.0789 38.7555 39.0328 38.7095C38.9867 38.6634 38.9867 38.6173 38.9867 38.479V33.5021C38.9867 33.4099 38.9867 33.3178 39.0328 33.3178C39.0789 33.2717 39.1251 33.2717 39.2635 33.2717H39.7249C39.8171 33.2717 39.9094 33.2717 39.9556 33.3178C40.0017 33.3638 40.0017 33.4099 40.0017 33.5021V35.5297H42.3086V33.5021C42.3086 33.4099 42.3086 33.3178 42.3547 33.3178C42.4009 33.2717 42.447 33.2717 42.5854 33.2717C42.5854 33.2256 43.0468 33.2256 43.0468 33.2256Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask15_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask15_23266_6292)">
        <path
          d="M44.5696 38.8014C44.4774 38.8014 44.3851 38.8014 44.339 38.7554C44.2928 38.7093 44.2928 38.6171 44.2928 38.5249V33.5019C44.2928 33.4097 44.2928 33.3176 44.339 33.3176C44.3851 33.2715 44.4312 33.2715 44.5696 33.2715H45.031C45.1233 33.2715 45.2156 33.2715 45.2617 33.3176C45.3079 33.3637 45.3079 33.4097 45.3079 33.5019V35.1609H46.692C47.2457 35.1609 47.707 35.2991 48.03 35.6217C48.353 35.9443 48.5375 36.4051 48.5375 36.9581C48.5375 37.5111 48.353 37.9719 48.03 38.2945C47.707 38.6171 47.2457 38.8014 46.692 38.8014H44.5696ZM45.2617 36.0825V37.8798H46.5536C46.8304 37.8798 47.0611 37.7876 47.2457 37.6494C47.3841 37.5111 47.4763 37.2807 47.4763 37.0042C47.4763 36.7277 47.3841 36.4973 47.2457 36.359C47.1072 36.2208 46.8765 36.1286 46.5536 36.1286L45.2617 36.0825ZM49.3219 33.5019C49.3219 33.4097 49.3219 33.3176 49.368 33.3176C49.4141 33.2715 49.4603 33.2715 49.5987 33.2715H50.0601C50.1524 33.2715 50.2446 33.2715 50.2908 33.3176C50.3369 33.3637 50.3369 33.4097 50.3369 33.5019V38.5249C50.3369 38.6171 50.3369 38.7093 50.2908 38.7554C50.2446 38.8014 50.1985 38.8014 50.0601 38.8014H49.5987C49.5064 38.8014 49.4141 38.8014 49.368 38.7554C49.3219 38.7093 49.3219 38.6632 49.3219 38.5249V33.5019Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask16_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask16_23266_6292)">
        <path
          d="M55.4119 33.2255C55.5042 33.2255 55.5965 33.2255 55.6426 33.2716C55.6888 33.3177 55.6888 33.3638 55.6888 33.4559V38.479C55.6888 38.5711 55.6888 38.6633 55.6426 38.7094C55.5965 38.7555 55.5504 38.7555 55.4119 38.7555H54.9506C54.8583 38.7555 54.766 38.7555 54.7199 38.7094C54.6737 38.6633 54.6737 38.6172 54.6737 38.479V36.1748C54.6737 35.7601 54.6737 35.4836 54.7199 35.2532C54.766 35.0228 54.8121 34.7463 54.8583 34.3776H54.8122C54.6276 34.8384 54.3508 35.3453 54.0278 35.8523L52.2284 38.5711C52.1823 38.6633 52.09 38.7094 51.9516 38.7094H51.4902C51.3979 38.7094 51.3057 38.7094 51.2595 38.6633C51.2134 38.6172 51.2134 38.5711 51.2134 38.4329V33.502C51.2134 33.4099 51.2134 33.3177 51.2595 33.3177C51.3057 33.2716 51.3518 33.2716 51.4902 33.2716H51.9516C52.0439 33.2716 52.1361 33.2716 52.1823 33.3177C52.2284 33.3638 52.2284 33.4099 52.2284 33.502V35.7601C52.2284 36.1748 52.2284 36.4513 52.1823 36.6817C52.1361 36.9122 52.09 37.1887 52.0439 37.5573H52.09C52.2746 37.0504 52.5514 36.5435 52.9205 35.9905L54.6737 33.3177C54.7199 33.2255 54.8121 33.1794 54.9506 33.1794L55.4119 33.2255ZM54.8583 32.0274C54.766 32.3039 54.5815 32.4882 54.3508 32.6264C54.1201 32.7647 53.8432 32.8569 53.4741 32.8569C53.1512 32.8569 52.8743 32.7647 52.5975 32.6264C52.3668 32.4882 52.1823 32.2578 52.09 32.0274C52.0439 31.9352 52.09 31.843 52.2284 31.797L52.5514 31.6587C52.6898 31.6126 52.7821 31.6587 52.8282 31.7509C52.9205 32.0274 53.1512 32.1656 53.428 32.1656C53.751 32.1656 53.9355 32.0274 54.0278 31.7509C54.0739 31.6587 54.1662 31.6126 54.2585 31.6587L54.6737 31.797C54.7199 31.843 54.766 31.843 54.8122 31.8891C54.9044 31.8891 54.9044 31.9352 54.8583 32.0274Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask17_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask17_23266_6292)">
        <path
          d="M62.6557 33.2256C62.748 33.2256 62.8403 33.2256 62.8864 33.2717C62.9326 33.3178 62.9325 33.3638 62.9325 33.456V37.8339H63.3478C63.4401 37.8339 63.5323 37.8339 63.5323 37.88C63.5323 37.926 63.5785 37.9721 63.5785 38.1104V39.6772C63.5785 39.7694 63.5785 39.8615 63.5323 39.8615C63.4862 39.8615 63.4401 39.9076 63.3478 39.9076H62.9325C62.8403 39.9076 62.748 39.9076 62.7019 39.8615C62.6557 39.8154 62.6557 39.7694 62.6557 39.6772V38.7555H59.057V39.6772C59.057 39.7694 59.057 39.8615 59.0108 39.8615C58.9647 39.9076 58.9185 39.9076 58.8263 39.9076H58.411C58.3187 39.9076 58.2265 39.9076 58.2265 39.8615C58.2265 39.8154 58.1803 39.7694 58.1803 39.6772V38.1104C58.1803 38.0182 58.1803 37.926 58.2265 37.88C58.2726 37.8339 58.3187 37.8339 58.411 37.8339H58.5956C58.6878 37.7878 58.7801 37.6956 58.8724 37.6035C58.9647 37.5113 59.0108 37.3731 59.1031 37.1426C59.1954 36.9583 59.2415 36.6818 59.2876 36.3131C59.3338 35.9445 59.3338 35.5297 59.3338 35.0689V33.456C59.3338 33.3638 59.3338 33.2717 59.3799 33.2717C59.4261 33.2256 59.4722 33.2256 59.6106 33.2256H62.6557ZM60.2104 34.1472V35.115C60.2104 36.4053 60.0258 37.327 59.7029 37.88H61.9637V34.1472H60.2104Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask18_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask18_23266_6292)">
        <path
          d="M67.7307 33.2256C67.823 33.2256 67.9152 33.2256 67.9614 33.2717C68.0075 33.3178 68.0075 33.3638 68.0075 33.456V33.8247C68.0075 33.9168 68.0075 34.009 67.9614 34.009C67.9152 34.009 67.8691 34.0551 67.7307 34.0551H65.3315V35.4376H67.3616C67.4539 35.4376 67.5461 35.4376 67.5461 35.4837C67.5461 35.5297 67.5923 35.5758 67.5923 35.7141V36.0827C67.5923 36.1749 67.5923 36.2671 67.5461 36.3131C67.5 36.3592 67.4539 36.3592 67.3616 36.3592H65.3315V37.7878H67.823C67.9152 37.7878 68.0075 37.7878 68.0537 37.8339C68.0998 37.88 68.0998 37.926 68.0998 38.0643V38.433C68.0998 38.5251 68.0998 38.6173 68.0537 38.6634C68.0075 38.7095 67.9614 38.7095 67.823 38.7095H64.5933C64.501 38.7095 64.4087 38.7095 64.3626 38.6634C64.3165 38.6173 64.3165 38.5712 64.3165 38.433V33.5021C64.3165 33.4099 64.3165 33.3178 64.3626 33.3178C64.4087 33.2717 64.4549 33.2717 64.5933 33.2717C64.5933 33.2256 67.7307 33.2256 67.7307 33.2256Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask19_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask19_23266_6292)">
        <path
          d="M71.376 33.2256C71.8835 33.2256 72.2987 33.3638 72.5756 33.5943C72.8524 33.8707 72.9908 34.1933 72.9908 34.7002C72.9908 35.2532 72.8063 35.668 72.391 35.8984V35.9445C72.9447 36.1288 73.1754 36.5896 73.1754 37.2809C73.1754 37.7417 72.9908 38.1565 72.6679 38.3869C72.3449 38.6634 71.9296 38.8016 71.376 38.8016H69.2075C69.1152 38.8016 69.0229 38.8016 68.9768 38.7555C68.9307 38.7095 68.9307 38.6634 68.9307 38.5251V33.5021C68.9307 33.4099 68.9307 33.3178 68.9768 33.2717C69.0229 33.2256 69.0691 33.2256 69.2075 33.2256H71.376ZM71.9758 34.8385C71.9758 34.3777 71.7451 34.1472 71.2376 34.1472H69.8996V35.5297H71.2376C71.7451 35.5297 71.9758 35.2993 71.9758 34.8385ZM72.1142 37.1426C72.1142 36.6357 71.8835 36.4053 71.376 36.4053H69.8996V37.88H71.376C71.8835 37.88 72.1142 37.6495 72.1142 37.1426Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask20_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask20_23266_6292)">
        <path
          d="M77.42 33.2256C77.5123 33.2256 77.6045 33.2256 77.6507 33.2717C77.6968 33.3178 77.6968 33.3638 77.6968 33.456V33.8247C77.6968 33.9168 77.6968 34.009 77.6507 34.009C77.6045 34.009 77.5584 34.0551 77.42 34.0551H75.0208V35.4376H77.0509C77.1432 35.4376 77.2354 35.4376 77.2816 35.4837C77.3277 35.5297 77.3277 35.5758 77.3277 35.7141V36.0827C77.3277 36.1749 77.3277 36.2671 77.2816 36.3131C77.2354 36.3592 77.1893 36.3592 77.0509 36.3592H75.0208V37.7878H77.5123C77.6045 37.7878 77.6968 37.7878 77.743 37.8339C77.7891 37.88 77.7891 37.926 77.7891 38.0643V38.433C77.7891 38.5251 77.7891 38.6173 77.743 38.6634C77.6968 38.7095 77.6507 38.7095 77.5123 38.7095H74.3287C74.2365 38.7095 74.1442 38.7095 74.0981 38.6634C74.0519 38.6173 74.0519 38.5712 74.0519 38.433V33.5021C74.0519 33.4099 74.0519 33.3178 74.0981 33.3178C74.1442 33.2717 74.1903 33.2717 74.3287 33.2717C74.3287 33.2256 77.42 33.2256 77.42 33.2256Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask21_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask21_23266_6292)">
        <path
          d="M82.5871 33.2256C82.6794 33.2256 82.7717 33.2256 82.7717 33.2717C82.8178 33.3178 82.8178 33.3638 82.8178 33.456V38.479C82.8178 38.5712 82.8178 38.6634 82.7717 38.7095C82.7256 38.7555 82.6794 38.7555 82.5871 38.7555H82.1258C82.0335 38.7555 81.9412 38.7555 81.8951 38.7095C81.8489 38.6634 81.8489 38.6173 81.8489 38.479V34.1012H80.188V35.115C80.188 35.668 80.1418 36.1288 80.0957 36.5436C80.0496 36.9583 79.9573 37.2809 79.865 37.5113C79.7727 37.7417 79.6804 37.9721 79.542 38.1565C79.4036 38.3408 79.2652 38.479 79.1268 38.5712C78.9884 38.6634 78.85 38.7555 78.6654 38.8477C78.5731 38.8938 78.527 38.8938 78.4347 38.8938C78.3886 38.8938 78.3424 38.8016 78.2963 38.7555L78.1118 38.3408C78.0195 38.1565 78.0656 38.0643 78.2502 37.9721C78.3424 37.926 78.4347 37.88 78.527 37.8339C78.5731 37.7878 78.6654 37.6956 78.7577 37.5574C78.85 37.4191 78.9422 37.2809 78.9884 37.0966C79.0345 36.9122 79.0806 36.6357 79.1268 36.3131C79.1729 35.9906 79.1729 35.5758 79.1729 35.1611V33.5021C79.1729 33.4099 79.1729 33.3178 79.2191 33.3178C79.2652 33.2717 79.3113 33.2717 79.4498 33.2717C79.4959 33.2256 82.5871 33.2256 82.5871 33.2256Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask22_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask22_23266_6292)">
        <path
          d="M83.8329 35.1616C83.8329 34.5165 84.0174 34.0095 84.4327 33.6409C84.8018 33.2722 85.3554 33.0879 86.0937 33.0879C86.7857 33.0879 87.3394 33.2722 87.7546 33.6409C88.1237 34.0095 88.3544 34.5165 88.3544 35.1616V36.8667C88.3544 37.5119 88.1699 38.0188 87.7546 38.3874C87.3855 38.7561 86.8319 38.9404 86.0937 38.9404C85.3554 38.9404 84.8018 38.7561 84.4327 38.3874C84.0636 38.0188 83.8329 37.5119 83.8329 36.8667V35.1616ZM87.2932 35.1616C87.2932 34.793 87.201 34.5165 86.9703 34.3321C86.7396 34.1478 86.4628 34.0556 86.0475 34.0556C85.6323 34.0556 85.3554 34.1478 85.1248 34.3321C84.8941 34.5165 84.8018 34.793 84.8018 35.1616V36.8667C84.8018 37.2354 84.8941 37.5119 85.1248 37.6962C85.3554 37.8805 85.6323 37.9727 86.0475 37.9727C86.4628 37.9727 86.7396 37.8805 86.9703 37.6962C87.201 37.5119 87.2932 37.2354 87.2932 36.8667V35.1616Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask23_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask23_23266_6292)">
        <path
          d="M93.2446 33.2256C93.3369 33.2256 93.4292 33.2256 93.4292 33.2717C93.4753 33.3178 93.4753 33.3638 93.4753 33.456V38.479C93.4753 38.5712 93.4753 38.6634 93.4292 38.7095C93.3831 38.7555 93.3369 38.7555 93.2446 38.7555H92.7833C92.691 38.7555 92.5987 38.7555 92.5987 38.7095C92.5987 38.6634 92.5526 38.6173 92.5526 38.479V34.1012H90.2918V38.479C90.2918 38.5712 90.2918 38.6634 90.2457 38.7095C90.1995 38.7555 90.1534 38.7555 90.0611 38.7555H89.5997C89.5075 38.7555 89.4152 38.7555 89.369 38.7095C89.3229 38.6634 89.3229 38.6173 89.3229 38.479V33.5021C89.3229 33.4099 89.3229 33.3178 89.369 33.3178C89.4152 33.2717 89.4613 33.2717 89.5997 33.2717C89.5536 33.2256 93.2446 33.2256 93.2446 33.2256Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask24_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask24_23266_6292)">
        <path
          d="M99.6582 33.2256C99.7505 33.2256 99.8428 33.2256 99.8428 33.2717C99.8889 33.3178 99.8889 33.3638 99.8889 33.456V38.479C99.8889 38.5712 99.8889 38.6634 99.8428 38.7095C99.7966 38.7555 99.7505 38.7555 99.6582 38.7555H99.243C99.1507 38.7555 99.0584 38.7555 99.0123 38.7095C98.9662 38.6634 98.9662 38.6173 98.9662 38.479V36.0827C98.9662 35.6219 99.0123 35.115 99.1507 34.5159H99.1046C98.9662 34.9767 98.7355 35.4376 98.4586 35.9445L97.6743 37.2809C97.582 37.4191 97.4897 37.4652 97.3513 37.4652H97.1206C97.0284 37.4652 96.9822 37.4652 96.9361 37.4191C96.89 37.3731 96.8438 37.327 96.7977 37.2809L96.0133 35.8984C95.7826 35.4837 95.5519 35.0228 95.4135 34.4698H95.3674C95.4597 35.0228 95.552 35.5297 95.552 36.0366V38.433C95.552 38.5251 95.5519 38.6173 95.5058 38.6634C95.4597 38.7095 95.4135 38.7095 95.3213 38.7095H94.9522C94.8599 38.7095 94.7676 38.7095 94.7215 38.6634C94.6753 38.6173 94.6753 38.5712 94.6753 38.433V33.5021C94.6753 33.4099 94.6753 33.3178 94.7215 33.3178C94.7676 33.2717 94.8137 33.2717 94.9522 33.2717H95.2751C95.3674 33.2717 95.4597 33.2717 95.5058 33.3178C95.5519 33.3638 95.5981 33.4099 95.6442 33.5021L97.2591 36.4053L98.8739 33.5021C98.92 33.4099 98.9662 33.3638 99.0123 33.3178C99.0584 33.2717 99.1507 33.2717 99.243 33.2717L99.6582 33.2256Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask25_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask25_23266_6292)">
        <path
          d="M104.457 33.2256C104.549 33.2256 104.641 33.2256 104.688 33.2717C104.734 33.3178 104.734 33.3638 104.734 33.456V33.8247C104.734 33.9168 104.734 34.009 104.688 34.009C104.641 34.0551 104.595 34.0551 104.457 34.0551H102.058V35.4376H104.088C104.18 35.4376 104.272 35.4376 104.272 35.4837C104.272 35.5297 104.319 35.5758 104.319 35.7141V36.0827C104.319 36.1749 104.319 36.2671 104.272 36.3131C104.226 36.3592 104.18 36.3592 104.088 36.3592H102.058V37.7878H104.549C104.641 37.7878 104.734 37.7878 104.78 37.8339C104.826 37.88 104.826 37.926 104.826 38.0643V38.433C104.826 38.5251 104.826 38.6173 104.78 38.6634C104.734 38.7095 104.688 38.7095 104.549 38.7095H101.366C101.273 38.7095 101.181 38.7095 101.135 38.6634C101.089 38.6173 101.089 38.5712 101.089 38.433V33.5021C101.089 33.4099 101.089 33.3178 101.135 33.3178C101.181 33.2717 101.227 33.2717 101.366 33.2717C101.32 33.2256 104.457 33.2256 104.457 33.2256Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask26_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask26_23266_6292)">
        <path
          d="M109.67 33.2256C109.762 33.2256 109.855 33.2256 109.901 33.2717C109.947 33.3178 109.947 33.3638 109.947 33.456V38.479C109.947 38.5712 109.947 38.6634 109.901 38.7095C109.855 38.7555 109.809 38.7555 109.67 38.7555H109.209C109.117 38.7555 109.024 38.7555 109.024 38.7095C109.024 38.6634 108.978 38.6173 108.978 38.479V36.4053H106.671V38.479C106.671 38.5712 106.671 38.6634 106.625 38.7095C106.579 38.7555 106.533 38.7555 106.441 38.7555H105.979C105.887 38.7555 105.795 38.7555 105.748 38.7095C105.702 38.6634 105.702 38.6173 105.702 38.479V33.5021C105.702 33.4099 105.702 33.3178 105.748 33.3178C105.795 33.2717 105.841 33.2717 105.979 33.2717H106.441C106.533 33.2717 106.625 33.2717 106.625 33.3178C106.671 33.3638 106.671 33.4099 106.671 33.5021V35.5297H108.978V33.5021C108.978 33.4099 108.978 33.3178 109.024 33.3178C109.07 33.2717 109.117 33.2717 109.209 33.2717C109.209 33.2256 109.67 33.2256 109.67 33.2256Z"
          fill="#77BC1F"
        />
      </g>
      <mask
        id="mask27_23266_6292"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask27_23266_6292)">
        <path
          d="M114.561 33.2256C114.653 33.2256 114.745 33.2256 114.745 33.2717C114.791 33.3178 114.791 33.3638 114.791 33.456V33.8247C114.791 33.9168 114.791 34.009 114.745 34.009C114.699 34.009 114.653 34.0551 114.561 34.0551H113.223V38.433C113.223 38.5251 113.223 38.6173 113.176 38.6634C113.13 38.7095 113.084 38.7095 112.946 38.7095H112.484C112.392 38.7095 112.3 38.7095 112.254 38.6634C112.208 38.6173 112.208 38.5712 112.208 38.433V34.0551H110.87C110.777 34.0551 110.685 34.0551 110.685 34.009C110.685 33.9629 110.639 33.9168 110.639 33.8247V33.456C110.639 33.3638 110.639 33.2717 110.685 33.2717C110.731 33.2256 110.777 33.2256 110.87 33.2256H114.561Z"
          fill="#77BC1F"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_23266_6292">
        <rect width="135" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default AkvilonLogo
