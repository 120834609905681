import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { INewsItem } from '../../types'
import LogoEn from '../../svg/logo-en'
import { useTranslations } from '../../hooks/use-translations'

import './UpdatesSection.styles.scss'

interface IProps {
  data: INewsItem
}

export const UpdatesCard: React.FC<IProps> = ({ data }) => {
  const { t, getPath } = useTranslations()
  if (!data) return null
  return (
    <Link to={getPath(data.to)} className="updates-card" title={data.title}>
      <div className="updates-image_wrap">
        {!data.image ? (
          <LogoEn className="updates-logo" />
        ) : (
          <GatsbyImage
            image={data.image}
            alt={data.title}
            className="updates-image"
            title={data.title}
          />
        )}
      </div>
      <div className="updates-body">
        {data.type && <div className="updates-body-text">{t(data.type)}</div>}
        {data.date && <div className="updates-body-text">{data.date}</div>}
        <p className="updates-card_title">{data.title}</p>
      </div>
    </Link>
  )
}
