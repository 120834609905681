import React from 'react'
import cn from 'classnames'

import * as s from './Section.module.scss'

interface SectionProps {
  className?: string
  bgColor?: 'white' | 'light' | 'dark'
  id?: string
}

const Section: React.FC<SectionProps> = ({
  className,
  id,
  bgColor,
  children,
}) => {
  return (
    <section
      className={cn(s.section, bgColor && s[`bg_${bgColor}`], className)}
      id={id}
    >
      {children}
    </section>
  )
}

export default Section
