import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from '../Common/Container'
import Typography from '../Common/Typography'
import EmployeeCard from './EmployeeCard'
import { useTranslations } from '../../hooks/use-translations'
import { AppRegionEnum } from '../../types/app-region.enum'
import { GlobalContext } from '../../context/GlobalContext'

import * as s from './Benefits.module.scss'

const BenefitsSection: React.FC = () => {
  const images = useStaticQuery(imagesQuery)
  const { t } = useTranslations()
  const { region } = React.useContext(GlobalContext)

  return (
    <section className={s.section}>
      <GatsbyImage
        image={images[`image_bg_${region}`].childImageSharp.gatsbyImageData}
        alt={t('Building')}
        title={t('Building')}
        className={s.imageBg}
      />
      <GatsbyImage
        image={
          images[`image_mobile_bg_${region}`].childImageSharp.gatsbyImageData
        }
        alt={t('Building')}
        title={t('Building')}
        className={s.mobileImageBg}
      />
      <Container className={s.container}>
        <Typography variant="h2" size={40} color="white" className={s.title}>
          {t('Benefits title')}
        </Typography>
        <EmployeeCard
          author={
            images[region === AppRegionEnum.Ru ? 'avatar4' : 'avatar']
              .childImageSharp.gatsbyImageData
          }
          position={
            region === AppRegionEnum.Ru
              ? t('Construction Director')
              : t('Project manager')
          }
        >
          {region === AppRegionEnum.Ru ? (
            <p>
              {`Производите удалённую инспекцию на площадке, как если\u00A0бы ваш специалист находился прямо на стройке.`}
            </p>
          ) : (
            <p>
              {t('benefits_text1_1')}
              <span style={{ color: '#8EDDFF' }}>{t('benefits_text1_2')}</span>
              {t('benefits_text1_3')}
            </p>
          )}
        </EmployeeCard>
        <EmployeeCard
          author={images.avatar2.childImageSharp.gatsbyImageData}
          position={t('BIM-manager')}
        >
          {region === AppRegionEnum.Ru ? (
            <p>
              {`Обеспечьте ежедневный мониторинг ситуации на\u00A0строительной площадке, отслеживайте динамику строительства, обеспечение порядка на площадке и\u00A0соблюдение мер безопасности.`}
            </p>
          ) : (
            <p>
              {t('benefits_text2_1')}
              {` `}
              <span style={{ color: '#8EDDFF' }}>
                {t('benefits_text2_2')}
                {` `}
              </span>
              {t('benefits_text2_3')}
            </p>
          )}
        </EmployeeCard>
        <EmployeeCard
          author={images.avatar3.childImageSharp.gatsbyImageData}
          position={t('QA/QC engineer')}
        >
          {region === AppRegionEnum.Ru ? (
            <p>
              {`Сократите количество выходов непосредственно на\u00A0площадку специалистов и руководителей. Постоянный доступ к нужным данным обеспечивается благодаря панорамным фотографиям. При необходимости руководитель может задавать уточняющие вопросы строительному контролю.`}
            </p>
          ) : (
            <p>
              {t('benefits_text3_1')}{' '}
              <span style={{ color: '#8EDDFF' }}>{t('benefits_text3_2')}</span>{' '}
              {t('benefits_text3_3')}
            </p>
          )}
        </EmployeeCard>
      </Container>
    </section>
  )
}

export default BenefitsSection

const imagesQuery = graphql`
  query {
    image_bg_ru: file(relativePath: { eq: "home/signal-stroika-back.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image_mobile_bg_ru: file(
      relativePath: { eq: "home/signal-stroika-mobile-back.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image_bg_us: file(relativePath: { eq: "home/signax-stroika-back.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image_mobile_bg_us: file(
      relativePath: { eq: "home/signax-stroika-mobile-back.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    avatar: file(relativePath: { eq: "author/Sergey_Kazantsev.png" }) {
      childImageSharp {
        gatsbyImageData(width: 72, placeholder: BLURRED)
      }
    }
    avatar2: file(relativePath: { eq: "author/author_20.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    avatar3: file(relativePath: { eq: "author/author_11.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    avatar4: file(relativePath: { eq: "author/author_19.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
