import React from 'react'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import cn from 'classnames'

import Section from '../Common/Section'
import Typography from '../Common/Typography'
import ModalIframe from '../Common/ModalIframe'
import ArrowButton from '../Common/ArrowButton'
import { GlobalContext } from '../../context/GlobalContext'
import { useTranslations } from '../../hooks/use-translations'
import { AppRegionEnum } from '../../types/app-region.enum'
import { getIframeYoutubeSrc } from '../../utils'
import YoutubePlayIcon from '../../svg/youtube-play-icon'

import './HowItWorks.styles.scss'

interface YoutubeData {
  id: string
  title: string
}

const youtubeVideo: Record<AppRegionEnum, YoutubeData[]> = {
  [AppRegionEnum.Ru]: [
    {
      id: 'cDG4ITIMqG0',
      title: 'Вебинар. SIGNAL DOCS: Больше, чем просто среда общих данных',
    },
    {
      id: 'Eznivw1F2AE',
      title:
        'ONLINE КОНФЕРЕНЦИЯ SIGNAL. Опыт ведущих компаний в использовании SIGNAL TOOLS',
    },
    { id: 'bdbFQxMMzcE', title: 'Применение SIGNAL в MR GROUP' },
    { id: 'g4hHGjCXaxM', title: 'SIGNAL INSPECTION' },
    { id: 'IZ6jC3GU8O4', title: 'SIGNAL TOOLS' },
    { id: 'ym06b56HHvg', title: 'SIGNAL DOCS' },
  ],
  [AppRegionEnum.Us]: [
    {
      id: 'K-kniPokeIA',
      title: 'SIGNAX – Your digital construction',
    },
    {
      id: 'zSOtWR4ChfE',
      title: `SIGNAX aerial drone surveying\nwith INSPECTION Module`,
    },
    {
      id: 'xo_JKkP1w3c',
      title: 'SIGNAX DASHBOARD. Construction project reports',
    },
    {
      id: 'VlZVRCGc6gM',
      title: 'SIGNAX INSPECTION. Spherical 360 degree cloud service',
    },
    {
      id: 'oonWw-RsmIw',
      title: 'SIGNAX TOOLS. BIM-LINK',
    },
    {
      id: 'yDntZYb4D4A',
      title:
        'SIGNAX As-built BIM model development for industrial and infrastructure projects_',
    },
  ],
}

const HowItWorksSection: React.FC = () => {
  const { t } = useTranslations()
  const { region } = React.useContext(GlobalContext)
  const [youtubeData, setYoutubeData] = React.useState<YoutubeData>()
  const swiperRef = React.useRef<SwiperRef>(null)
  const [disabledNavigationPrev, setDisabledNavigationPrev] =
    React.useState(true)
  const [disabledNavigationNext, setDisabledNavigationNext] =
    React.useState(false)

  return (
    <>
      <Section className="z-1" id="how-it-works-section">
        <div className="how-it-works_container">
          <Typography variant="h2" size={40} color="blue">
            {t('How it works')}
          </Typography>
          <Swiper
            ref={swiperRef}
            freeMode={true}
            grabCursor={true}
            navigation={true}
            onSlideChange={swiper => {
              setDisabledNavigationPrev(swiper.isBeginning)
              setDisabledNavigationNext(swiper.isEnd)
            }}
            wrapperClass="how-it-works_wrap"
            pagination={{
              clickable: true,
              bulletClass: 'how-it-works_pagination',
              bulletActiveClass: 'active',
            }}
            slidesPerView="auto"
            modules={[Pagination]}
          >
            {youtubeVideo[region as AppRegionEnum].map(item => (
              <SwiperSlide key={item.id} className="how-it-works_slide">
                <div
                  onClick={() => setYoutubeData(item)}
                  className="how-it-works_poster"
                  style={{
                    backgroundImage: `url(https://img.youtube.com/vi/${item.id}/0.jpg)`,
                  }}
                >
                  <YoutubePlayIcon style={{ zIndex: 1 }} />
                </div>
                <span
                  style={{
                    fontSize: '18px',
                    lineHeight: '28px',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {item.title}
                </span>
              </SwiperSlide>
            ))}
          </Swiper>
          <ArrowButton
            onClick={() => swiperRef.current?.swiper.slidePrev()}
            disabled={disabledNavigationPrev}
            arrowType="left"
            className={cn('how-it-works_btn how-it-works_btn_prev')}
          />
          <ArrowButton
            arrowType="right"
            onClick={() => swiperRef.current?.swiper.slideNext()}
            disabled={disabledNavigationNext}
            className={cn('how-it-works_btn how-it-works_btn_next')}
          />
        </div>
      </Section>
      {youtubeData && (
        <ModalIframe
          title="SIGNAX Premiere"
          src={getIframeYoutubeSrc(youtubeData.id)}
          srcTitle={youtubeData.title}
          onClose={() => setYoutubeData(undefined)}
        />
      )}
    </>
  )
}

export default HowItWorksSection
