import React from 'react'
import cn from 'classnames'
import { useTranslations } from '../../hooks/use-translations'
import { IReviews } from './types'
import ArrowLinkButton from '../../svg/arrow-link-button'

const Reviews: React.FC<IReviews> = ({ task, decision, possibilities }) => {
  const [visibleReviews, setVisibleReviews] = React.useState(false)
  const { t } = useTranslations()

  const renderText = (reviews: Array<string | string[]>, title: string) => (
    <p className="feedback_text">
      <span className="has-text-primary">{title}. </span>
      {reviews.map((review, idx) =>
        Array.isArray(review) ? (
          <ul className="feedback_list">
            {review.map(text => (
              <li key={text}>{t(text)}</li>
            ))}
          </ul>
        ) : (
          <span className={cn(idx && `is-block mt-1`)} key={review}>
            {t(review)}
          </span>
        )
      )}
    </p>
  )

  return (
    <>
      <div className={cn('feedback_description', visibleReviews && 'active')}>
        {renderText(task, 'Задача')}
        {renderText(decision, 'Решение')}
        {possibilities && renderText(possibilities, 'Другие возможности')}
      </div>
      <div
        className="feedback_button"
        onClick={() => setVisibleReviews(!visibleReviews)}
      >
        <span>
          {visibleReviews ? 'Свернуть отзыв' : 'Читать весь отзыв'}
          <ArrowLinkButton
            className={cn('feedback_arrow', {
              active: visibleReviews,
            })}
          />
        </span>
      </div>
    </>
  )
}

export default Reviews
