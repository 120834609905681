import React from 'react'

const AkvilonWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="135"
    height="40"
    viewBox="0 0 135 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_21870_120883)">
      <mask
        id="mask0_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_21870_120883)">
        <path
          d="M21.0851 24.5159L13.1955 6.5896V6.54351C12.7341 5.48361 11.6268 4.7002 10.3811 4.7002H8.85851C8.81237 4.7002 8.72009 4.74628 8.72009 4.79236L0 24.5159V24.5619C0 24.608 0.0461381 24.6541 0.0922761 24.6541H3.69105C3.73718 24.6541 3.82946 24.608 3.82946 24.5619L5.8134 20.0919H13.2416C13.2416 18.3868 11.8575 17.0043 10.1504 17.0043H7.1514L10.4733 9.07808L14.1182 17.7417L17.1172 24.5619C17.1634 24.608 17.2095 24.6541 17.2556 24.6541H20.9005C21.039 24.7002 21.0851 24.6541 21.0851 24.5159C21.0851 24.5619 21.0851 24.5619 21.0851 24.5159Z"
          fill="white"
        />
      </g>
      <mask
        id="mask1_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask1_21870_120883)">
        <path
          d="M50.5214 16.7286C51.8133 16.1756 52.7822 15.1617 52.7822 13.2263C52.7822 10.876 50.7983 9.40137 47.7531 9.40137H40.0481C39.9558 9.40137 39.9097 9.49353 39.9097 9.53962V24.4705C39.9097 24.5627 40.0019 24.6087 40.0481 24.6087H47.7993C51.5365 24.6087 53.4281 23.0419 53.4281 20.4613C53.4743 18.5719 52.3669 17.2816 50.5214 16.7286ZM43.4162 12.2124H47.1533C48.4913 12.2124 49.2296 12.9037 49.2296 13.8714C49.2296 14.9774 48.4913 15.6226 47.1533 15.6226H43.4162V12.2124ZM47.6147 21.9359H43.4162V18.111H47.6147C49.0911 18.111 49.9216 18.8023 49.9216 20.0465C49.9216 21.1064 49.1373 21.9359 47.6147 21.9359Z"
          fill="white"
        />
      </g>
      <mask
        id="mask2_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask2_21870_120883)">
        <path
          d="M99.9811 9.12402C95.3673 9.12402 91.8147 12.5342 91.8147 17.0964C91.8147 21.6586 95.3673 25.0687 99.9811 25.0687C104.595 25.0687 108.148 21.6586 108.148 17.0964C108.148 12.5342 104.595 9.12402 99.9811 9.12402ZM99.9811 22.0273C97.1667 22.0273 95.3212 19.9535 95.3212 17.0964C95.3212 14.2853 97.1667 12.1655 99.9811 12.1655C102.796 12.1655 104.641 14.2853 104.641 17.0964C104.641 19.9074 102.749 22.0273 99.9811 22.0273Z"
          fill="white"
        />
      </g>
      <mask
        id="mask3_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask3_21870_120883)">
        <path
          d="M113.269 9.44629H111.47C111.377 9.44629 111.331 9.53845 111.331 9.58454V24.5154C111.331 24.6076 111.424 24.6537 111.47 24.6537H114.653C114.746 24.6537 114.792 24.5615 114.792 24.5154V11.0131C114.838 10.1375 114.146 9.44629 113.269 9.44629Z"
          fill="white"
        />
      </g>
      <mask
        id="mask4_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask4_21870_120883)">
        <path
          d="M123.973 9.44629H122.219C122.127 9.44629 122.081 9.53845 122.081 9.58454V15.6214H116.221C116.221 17.2343 117.513 18.5246 119.128 18.5246H122.035V24.5154C122.035 24.6076 122.127 24.6537 122.173 24.6537H125.357C125.449 24.6537 125.495 24.5615 125.495 24.5154V11.0131C125.541 10.1375 124.849 9.44629 123.973 9.44629Z"
          fill="white"
        />
      </g>
      <mask
        id="mask5_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask5_21870_120883)">
        <path
          d="M87.0162 9.44629H79.3112H79.265C78.6191 9.44629 78.0193 9.90712 77.8348 10.5062L73.3594 24.5615C73.3594 24.5615 73.3594 24.5615 73.3594 24.6076C73.3594 24.6537 73.4055 24.6997 73.4516 24.6997H75.8047C76.4968 24.6997 77.0966 24.2389 77.2811 23.5938L80.7876 12.4417H85.0323V24.5154C85.0323 24.6076 85.1246 24.6537 85.1707 24.6537H88.3542C88.4465 24.6537 88.4927 24.5615 88.4927 24.5154V11.0131C88.6311 10.1375 87.8929 9.44629 87.0162 9.44629Z"
          fill="white"
        />
      </g>
      <mask
        id="mask6_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask6_21870_120883)">
        <path
          d="M70.7301 9.44629H67.685C67.5927 9.44629 67.5466 9.49237 67.5004 9.53846L60.3029 19.4463V11.0131C60.3029 10.1375 59.6108 9.44629 58.7342 9.44629H56.9809C56.8887 9.44629 56.8425 9.53845 56.8425 9.58454V24.5154C56.8425 24.6076 56.9348 24.6537 56.9809 24.6537H59.9799C60.0722 24.6537 60.1183 24.6076 60.1645 24.5615L67.362 14.6997V24.5154C67.362 24.6076 67.4543 24.6537 67.5004 24.6537H70.684C70.7762 24.6537 70.8224 24.5615 70.8224 24.5154V9.58454C70.8685 9.49237 70.8224 9.44629 70.7301 9.44629Z"
          fill="white"
        />
      </g>
      <mask
        id="mask7_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask7_21870_120883)">
        <path
          d="M32.1583 16.6813L36.8183 9.63062V9.58454C36.8183 9.53845 36.7721 9.49237 36.726 9.49237H33.2195C33.1733 9.49237 33.1272 9.53845 33.0811 9.58454L29.0671 15.5753H26.714V11.0131C26.714 10.1375 26.0219 9.44629 25.1453 9.44629H23.3921C23.2998 9.44629 23.2537 9.53845 23.2537 9.58454V24.5154C23.2537 24.6076 23.3459 24.6537 23.3921 24.6537H26.5756C26.6679 24.6537 26.714 24.5615 26.714 24.5154V18.3403H29.39L33.4502 24.6076C33.4963 24.6537 33.5425 24.6997 33.5886 24.6997H37.0951C37.1412 24.6997 37.1874 24.6537 37.1874 24.6076V24.5615L32.1583 16.6813Z"
          fill="white"
        />
      </g>
      <mask
        id="mask8_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask8_21870_120883)">
        <path
          d="M125.542 4.60829C128.08 4.60829 130.156 2.53456 130.156 0H130.34C130.34 2.53456 132.417 4.60829 134.954 4.60829V4.79263C132.417 4.79263 130.34 6.86636 130.34 9.40092H130.156C130.156 6.86636 128.08 4.79263 125.542 4.79263V4.60829Z"
          fill="white"
        />
      </g>
      <mask
        id="mask9_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask9_21870_120883)">
        <path
          d="M15.6406 33.2256C16.1943 33.2256 16.6557 33.4099 16.9786 33.7325C17.3016 34.0551 17.4862 34.5159 17.4862 35.0689C17.4862 35.6219 17.3016 36.0827 16.9786 36.4053C16.6557 36.7279 16.1943 36.8661 15.6406 36.8661H14.2565V38.5251C14.2565 38.6173 14.2565 38.7095 14.2104 38.7095C14.1642 38.7555 14.1181 38.7555 13.9797 38.7555H13.5183C13.426 38.7555 13.3337 38.7555 13.2876 38.7095C13.2415 38.6634 13.2415 38.6173 13.2415 38.5251V33.5021C13.2415 33.4099 13.2415 33.3178 13.2876 33.3178C13.3337 33.2717 13.3799 33.2717 13.5183 33.2717L15.6406 33.2256ZM15.5484 35.9445C15.8252 35.9445 16.0559 35.8523 16.2404 35.7141C16.3788 35.5758 16.4711 35.3454 16.4711 35.0689C16.4711 34.7924 16.3788 34.562 16.2404 34.4237C16.102 34.2855 15.8713 34.1933 15.5484 34.1933H14.2565V35.9445H15.5484Z"
          fill="white"
        />
      </g>
      <mask
        id="mask10_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask10_21870_120883)">
        <path
          d="M22.6541 38.5263C22.7002 38.7106 22.6541 38.8028 22.4695 38.8028H22.0543C21.8697 38.8028 21.7775 38.8028 21.7313 38.7567C21.6852 38.7106 21.6391 38.6645 21.5929 38.5724L21.2238 37.5585H19.0553L18.6862 38.5724C18.6401 38.6645 18.5939 38.7106 18.5478 38.7567C18.5017 38.8028 18.3633 38.8028 18.2248 38.8028H17.8557C17.6712 38.8028 17.625 38.7106 17.6712 38.5263L19.6551 33.4111C19.7013 33.3189 19.7474 33.2728 19.7935 33.2267C19.8397 33.1807 19.8858 33.1807 20.0242 33.1807H20.4395C20.5317 33.1807 20.624 33.1807 20.6702 33.2267C20.7163 33.2728 20.7624 33.3189 20.8086 33.4111L22.6541 38.5263ZM20.3472 35.1161C20.3011 34.9779 20.2549 34.8396 20.2088 34.6553C20.1626 34.5171 20.1626 34.3788 20.1626 34.2867V34.1484H20.1165C20.1165 34.4249 20.0704 34.7475 19.9319 35.1161L19.3322 36.7751H20.947L20.3472 35.1161Z"
          fill="white"
        />
      </g>
      <mask
        id="mask11_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask11_21870_120883)">
        <path
          d="M27.129 34.7002C27.129 35.2532 26.8983 35.668 26.4831 35.8984C27.0367 36.1288 27.2674 36.5896 27.2674 37.2348C27.2674 37.7417 27.0829 38.1565 26.7599 38.479C26.4369 38.7555 25.8833 38.9399 25.1912 38.9399C23.9455 38.9399 23.1611 38.479 22.8382 37.6035C22.792 37.4191 22.8382 37.327 23.0227 37.2809L23.438 37.1426C23.5302 37.0966 23.6225 37.0966 23.6687 37.1426C23.7148 37.1887 23.7609 37.2348 23.8071 37.327C23.8993 37.5574 24.0378 37.7417 24.2685 37.88C24.4991 38.0182 24.8221 38.0643 25.2374 38.0643C25.9294 38.0643 26.2524 37.7878 26.2524 37.2809C26.2524 37.0505 26.2063 36.8201 26.0678 36.6818C25.9294 36.5436 25.7449 36.4514 25.5142 36.4514H24.4069C24.3146 36.4514 24.2223 36.4514 24.1762 36.4053C24.13 36.3592 24.13 36.3131 24.13 36.221V35.8523C24.13 35.7602 24.13 35.668 24.1762 35.6219C24.2223 35.5758 24.2685 35.5758 24.4069 35.5758H25.3758C25.6065 35.5758 25.791 35.5297 25.9294 35.3915C26.0678 35.2532 26.114 35.0689 26.114 34.8385C26.114 34.3316 25.791 34.0551 25.1451 34.0551C24.776 34.0551 24.453 34.1012 24.2685 34.2394C24.0839 34.3316 23.9455 34.5159 23.8532 34.7924C23.8071 34.8846 23.7609 34.9307 23.7148 34.9767C23.6687 35.0228 23.5764 34.9767 23.4841 34.9767L23.0689 34.8385C22.8843 34.7924 22.8382 34.6542 22.8843 34.5159C23.2073 33.6403 23.9455 33.2256 25.1912 33.2256C25.791 33.2256 26.2524 33.3638 26.5754 33.6403C26.9906 33.8247 27.129 34.1933 27.129 34.7002Z"
          fill="white"
        />
      </g>
      <mask
        id="mask12_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask12_21870_120883)">
        <path
          d="M32.1123 33.2267C32.2969 33.2267 32.2969 33.3189 32.2508 33.5032L29.8977 38.5724C29.8516 38.6645 29.8054 38.7567 29.7593 38.7567C29.7132 38.7567 29.6209 38.8028 29.4825 38.8028H28.9288C28.7443 38.8028 28.6981 38.7106 28.7904 38.5263L29.4825 37.1438L27.5908 33.4572C27.4985 33.2728 27.5447 33.1807 27.7292 33.1807H28.1906C28.329 33.1807 28.4213 33.1807 28.4674 33.1807C28.5136 33.1807 28.5597 33.2267 28.6059 33.2267C28.652 33.2728 28.6981 33.3189 28.7443 33.4111L30.0361 35.9917L31.2357 33.4111C31.2819 33.3189 31.328 33.2267 31.3741 33.2267C31.4203 33.1807 31.5587 33.1807 31.6971 33.1807H32.1123V33.2267Z"
          fill="white"
        />
      </g>
      <mask
        id="mask13_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask13_21870_120883)">
        <path
          d="M37.7871 33.2256C37.8794 33.2256 37.9717 33.2256 38.0178 33.2717C38.0639 33.3178 38.0639 33.3638 38.0639 33.456V38.479C38.0639 38.5712 38.0639 38.6634 38.0178 38.7095C37.9717 38.7555 37.9255 38.7555 37.7871 38.7555H37.3719C37.2796 38.7555 37.1873 38.7555 37.1873 38.7095C37.1873 38.6634 37.1412 38.6173 37.1412 38.479V36.0827C37.1412 35.6219 37.1873 35.115 37.3257 34.5159H37.2796C37.1412 34.9767 36.9105 35.4376 36.6337 35.9445L35.8493 37.2809C35.757 37.4191 35.6648 37.4652 35.5263 37.4652H35.2957C35.2034 37.4652 35.1572 37.4652 35.1111 37.4191C35.065 37.3731 35.0188 37.327 34.9727 37.2809L34.1883 35.8984C33.9576 35.4837 33.727 35.0228 33.5885 34.4698H33.5424C33.6347 35.0228 33.727 35.5297 33.727 36.0366V38.433C33.727 38.5251 33.727 38.6173 33.6808 38.6634C33.6347 38.7095 33.5885 38.7095 33.4963 38.7095H33.1272C33.0349 38.7095 32.9426 38.7095 32.8965 38.6634C32.7581 38.7095 32.7581 38.6173 32.7581 38.5251V33.5021C32.7581 33.4099 32.7581 33.3178 32.8042 33.3178C32.8503 33.2717 32.8965 33.2717 33.0349 33.2717H33.3579C33.4501 33.2717 33.5424 33.2717 33.5885 33.3178C33.6347 33.3638 33.6808 33.4099 33.727 33.5021L35.3418 36.4053L36.9566 33.5021C37.0028 33.4099 37.0489 33.3638 37.095 33.3178C37.1412 33.2717 37.2334 33.2717 37.3257 33.2717L37.7871 33.2256Z"
          fill="white"
        />
      </g>
      <mask
        id="mask14_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask14_21870_120883)">
        <path
          d="M43.0467 33.2256C43.139 33.2256 43.2313 33.2256 43.2774 33.2717C43.3236 33.3178 43.3236 33.3638 43.3236 33.456V38.479C43.3236 38.5712 43.3236 38.6634 43.2774 38.7095C43.2313 38.7555 43.1851 38.7555 43.0467 38.7555H42.5853C42.4931 38.7555 42.4008 38.7555 42.3547 38.7095C42.3085 38.6634 42.3085 38.6173 42.3085 38.479V36.4053H40.0016V38.479C40.0016 38.5712 40.0016 38.6634 39.9555 38.7095C39.9093 38.7555 39.8632 38.7555 39.7248 38.7555H39.2634C39.1711 38.7555 39.0788 38.7555 39.0327 38.7095C38.9866 38.6634 38.9866 38.6173 38.9866 38.479V33.5021C38.9866 33.4099 38.9866 33.3178 39.0327 33.3178C39.0788 33.2717 39.125 33.2717 39.2634 33.2717H39.7248C39.8171 33.2717 39.9093 33.2717 39.9555 33.3178C40.0016 33.3638 40.0016 33.4099 40.0016 33.5021V35.5297H42.3085V33.5021C42.3085 33.4099 42.3085 33.3178 42.3547 33.3178C42.4008 33.2717 42.4469 33.2717 42.5853 33.2717C42.5853 33.2256 43.0467 33.2256 43.0467 33.2256Z"
          fill="white"
        />
      </g>
      <mask
        id="mask15_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask15_21870_120883)">
        <path
          d="M44.5696 38.8014C44.4773 38.8014 44.385 38.8014 44.3389 38.7554C44.2927 38.7093 44.2927 38.6171 44.2927 38.5249V33.5019C44.2927 33.4097 44.2927 33.3176 44.3389 33.3176C44.385 33.2715 44.4311 33.2715 44.5696 33.2715H45.0309C45.1232 33.2715 45.2155 33.2715 45.2616 33.3176C45.3078 33.3637 45.3078 33.4097 45.3078 33.5019V35.1609H46.6919C47.2456 35.1609 47.7069 35.2991 48.0299 35.6217C48.3529 35.9443 48.5374 36.4051 48.5374 36.9581C48.5374 37.5111 48.3529 37.9719 48.0299 38.2945C47.7069 38.6171 47.2456 38.8014 46.6919 38.8014H44.5696ZM45.2616 36.0825V37.8798H46.5535C46.8303 37.8798 47.061 37.7876 47.2456 37.6494C47.384 37.5111 47.4763 37.2807 47.4763 37.0042C47.4763 36.7277 47.384 36.4973 47.2456 36.359C47.1071 36.2208 46.8765 36.1286 46.5535 36.1286L45.2616 36.0825ZM49.3218 33.5019C49.3218 33.4097 49.3218 33.3176 49.3679 33.3176C49.4141 33.2715 49.4602 33.2715 49.5986 33.2715H50.06C50.1523 33.2715 50.2445 33.2715 50.2907 33.3176C50.3368 33.3637 50.3368 33.4097 50.3368 33.5019V38.5249C50.3368 38.6171 50.3368 38.7093 50.2907 38.7554C50.2445 38.8014 50.1984 38.8014 50.06 38.8014H49.5986C49.5063 38.8014 49.4141 38.8014 49.3679 38.7554C49.3218 38.7093 49.3218 38.6632 49.3218 38.5249V33.5019Z"
          fill="white"
        />
      </g>
      <mask
        id="mask16_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask16_21870_120883)">
        <path
          d="M55.4119 33.2255C55.5042 33.2255 55.5965 33.2255 55.6426 33.2716C55.6888 33.3177 55.6888 33.3638 55.6888 33.4559V38.479C55.6888 38.5711 55.6888 38.6633 55.6426 38.7094C55.5965 38.7555 55.5504 38.7555 55.4119 38.7555H54.9506C54.8583 38.7555 54.766 38.7555 54.7199 38.7094C54.6737 38.6633 54.6737 38.6172 54.6737 38.479V36.1748C54.6737 35.7601 54.6737 35.4836 54.7199 35.2532C54.766 35.0228 54.8121 34.7463 54.8583 34.3776H54.8122C54.6276 34.8384 54.3508 35.3453 54.0278 35.8523L52.2284 38.5711C52.1823 38.6633 52.09 38.7094 51.9516 38.7094H51.4902C51.3979 38.7094 51.3057 38.7094 51.2595 38.6633C51.2134 38.6172 51.2134 38.5711 51.2134 38.4329V33.502C51.2134 33.4099 51.2134 33.3177 51.2595 33.3177C51.3057 33.2716 51.3518 33.2716 51.4902 33.2716H51.9516C52.0439 33.2716 52.1361 33.2716 52.1823 33.3177C52.2284 33.3638 52.2284 33.4099 52.2284 33.502V35.7601C52.2284 36.1748 52.2284 36.4513 52.1823 36.6817C52.1361 36.9122 52.09 37.1887 52.0439 37.5573H52.09C52.2746 37.0504 52.5514 36.5435 52.9205 35.9905L54.6737 33.3177C54.7199 33.2255 54.8121 33.1794 54.9506 33.1794L55.4119 33.2255ZM54.8583 32.0274C54.766 32.3039 54.5815 32.4882 54.3508 32.6264C54.1201 32.7647 53.8432 32.8569 53.4741 32.8569C53.1512 32.8569 52.8743 32.7647 52.5975 32.6264C52.3668 32.4882 52.1823 32.2578 52.09 32.0274C52.0439 31.9352 52.09 31.843 52.2284 31.797L52.5514 31.6587C52.6898 31.6126 52.7821 31.6587 52.8282 31.7509C52.9205 32.0274 53.1512 32.1656 53.428 32.1656C53.751 32.1656 53.9355 32.0274 54.0278 31.7509C54.0739 31.6587 54.1662 31.6126 54.2585 31.6587L54.6737 31.797C54.7199 31.843 54.766 31.843 54.8122 31.8891C54.9044 31.8891 54.9044 31.9352 54.8583 32.0274Z"
          fill="white"
        />
      </g>
      <mask
        id="mask17_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask17_21870_120883)">
        <path
          d="M62.6558 33.2256C62.7481 33.2256 62.8404 33.2256 62.8865 33.2717C62.9326 33.3178 62.9326 33.3638 62.9326 33.456V37.8339H63.3479C63.4402 37.8339 63.5324 37.8339 63.5324 37.88C63.5324 37.926 63.5786 37.9721 63.5786 38.1104V39.6772C63.5786 39.7694 63.5786 39.8615 63.5324 39.8615C63.4863 39.8615 63.4402 39.9076 63.3479 39.9076H62.9326C62.8404 39.9076 62.7481 39.9076 62.7019 39.8615C62.6558 39.8154 62.6558 39.7694 62.6558 39.6772V38.7555H59.057V39.6772C59.057 39.7694 59.057 39.8615 59.0109 39.8615C58.9648 39.9076 58.9186 39.9076 58.8264 39.9076H58.4111C58.3188 39.9076 58.2266 39.9076 58.2266 39.8615C58.2266 39.8154 58.1804 39.7694 58.1804 39.6772V38.1104C58.1804 38.0182 58.1804 37.926 58.2266 37.88C58.2727 37.8339 58.3188 37.8339 58.4111 37.8339H58.5957C58.6879 37.7878 58.7802 37.6956 58.8725 37.6035C58.9648 37.5113 59.0109 37.3731 59.1032 37.1426C59.1955 36.9583 59.2416 36.6818 59.2877 36.3131C59.3339 35.9445 59.3339 35.5297 59.3339 35.0689V33.456C59.3339 33.3638 59.3339 33.2717 59.38 33.2717C59.4261 33.2256 59.4723 33.2256 59.6107 33.2256H62.6558ZM60.2105 34.1472V35.115C60.2105 36.4053 60.0259 37.327 59.703 37.88H61.9637V34.1472H60.2105Z"
          fill="white"
        />
      </g>
      <mask
        id="mask18_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask18_21870_120883)">
        <path
          d="M67.7306 33.2256C67.8229 33.2256 67.9152 33.2256 67.9613 33.2717C68.0075 33.3178 68.0075 33.3638 68.0075 33.456V33.8247C68.0075 33.9168 68.0075 34.009 67.9613 34.009C67.9152 34.009 67.869 34.0551 67.7306 34.0551H65.3314V35.4376H67.3615C67.4538 35.4376 67.5461 35.4376 67.5461 35.4837C67.5461 35.5297 67.5922 35.5758 67.5922 35.7141V36.0827C67.5922 36.1749 67.5922 36.2671 67.5461 36.3131C67.4999 36.3592 67.4538 36.3592 67.3615 36.3592H65.3314V37.7878H67.8229C67.9152 37.7878 68.0075 37.7878 68.0536 37.8339C68.0997 37.88 68.0997 37.926 68.0997 38.0643V38.433C68.0997 38.5251 68.0997 38.6173 68.0536 38.6634C68.0075 38.7095 67.9613 38.7095 67.8229 38.7095H64.5932C64.501 38.7095 64.4087 38.7095 64.3625 38.6634C64.3164 38.6173 64.3164 38.5712 64.3164 38.433V33.5021C64.3164 33.4099 64.3164 33.3178 64.3625 33.3178C64.4087 33.2717 64.4548 33.2717 64.5932 33.2717C64.5932 33.2256 67.7306 33.2256 67.7306 33.2256Z"
          fill="white"
        />
      </g>
      <mask
        id="mask19_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask19_21870_120883)">
        <path
          d="M71.376 33.2256C71.8835 33.2256 72.2987 33.3638 72.5756 33.5943C72.8524 33.8707 72.9908 34.1933 72.9908 34.7002C72.9908 35.2532 72.8063 35.668 72.391 35.8984V35.9445C72.9447 36.1288 73.1754 36.5896 73.1754 37.2809C73.1754 37.7417 72.9908 38.1565 72.6679 38.3869C72.3449 38.6634 71.9296 38.8016 71.376 38.8016H69.2075C69.1152 38.8016 69.0229 38.8016 68.9768 38.7555C68.9307 38.7095 68.9307 38.6634 68.9307 38.5251V33.5021C68.9307 33.4099 68.9307 33.3178 68.9768 33.2717C69.0229 33.2256 69.0691 33.2256 69.2075 33.2256H71.376ZM71.9758 34.8385C71.9758 34.3777 71.7451 34.1472 71.2376 34.1472H69.8996V35.5297H71.2376C71.7451 35.5297 71.9758 35.2993 71.9758 34.8385ZM72.1142 37.1426C72.1142 36.6357 71.8835 36.4053 71.376 36.4053H69.8996V37.88H71.376C71.8835 37.88 72.1142 37.6495 72.1142 37.1426Z"
          fill="white"
        />
      </g>
      <mask
        id="mask20_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask20_21870_120883)">
        <path
          d="M77.4201 33.2256C77.5124 33.2256 77.6046 33.2256 77.6508 33.2717C77.6969 33.3178 77.6969 33.3638 77.6969 33.456V33.8247C77.6969 33.9168 77.6969 34.009 77.6508 34.009C77.6046 34.009 77.5585 34.0551 77.4201 34.0551H75.0209V35.4376H77.051C77.1433 35.4376 77.2355 35.4376 77.2817 35.4837C77.3278 35.5297 77.3278 35.5758 77.3278 35.7141V36.0827C77.3278 36.1749 77.3278 36.2671 77.2817 36.3131C77.2355 36.3592 77.1894 36.3592 77.051 36.3592H75.0209V37.7878H77.5124C77.6046 37.7878 77.6969 37.7878 77.743 37.8339C77.7892 37.88 77.7892 37.926 77.7892 38.0643V38.433C77.7892 38.5251 77.7892 38.6173 77.743 38.6634C77.6969 38.7095 77.6508 38.7095 77.5124 38.7095H74.3288C74.2366 38.7095 74.1443 38.7095 74.0981 38.6634C74.052 38.6173 74.052 38.5712 74.052 38.433V33.5021C74.052 33.4099 74.052 33.3178 74.0981 33.3178C74.1443 33.2717 74.1904 33.2717 74.3288 33.2717C74.3288 33.2256 77.4201 33.2256 77.4201 33.2256Z"
          fill="white"
        />
      </g>
      <mask
        id="mask21_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask21_21870_120883)">
        <path
          d="M82.5872 33.2256C82.6795 33.2256 82.7718 33.2256 82.7718 33.2717C82.8179 33.3178 82.8179 33.3638 82.8179 33.456V38.479C82.8179 38.5712 82.8179 38.6634 82.7718 38.7095C82.7256 38.7555 82.6795 38.7555 82.5872 38.7555H82.1258C82.0335 38.7555 81.9413 38.7555 81.8951 38.7095C81.849 38.6634 81.849 38.6173 81.849 38.479V34.1012H80.188V35.115C80.188 35.668 80.1419 36.1288 80.0958 36.5436C80.0496 36.9583 79.9573 37.2809 79.8651 37.5113C79.7728 37.7417 79.6805 37.9721 79.5421 38.1565C79.4037 38.3408 79.2653 38.479 79.1268 38.5712C78.9884 38.6634 78.85 38.7555 78.6655 38.8477C78.5732 38.8938 78.5271 38.8938 78.4348 38.8938C78.3886 38.8938 78.3425 38.8016 78.2964 38.7555L78.1118 38.3408C78.0195 38.1565 78.0657 38.0643 78.2502 37.9721C78.3425 37.926 78.4348 37.88 78.5271 37.8339C78.5732 37.7878 78.6655 37.6956 78.7577 37.5574C78.85 37.4191 78.9423 37.2809 78.9884 37.0966C79.0346 36.9122 79.0807 36.6357 79.1268 36.3131C79.173 35.9906 79.173 35.5758 79.173 35.1611V33.5021C79.173 33.4099 79.173 33.3178 79.2191 33.3178C79.2653 33.2717 79.3114 33.2717 79.4498 33.2717C79.496 33.2256 82.5872 33.2256 82.5872 33.2256Z"
          fill="white"
        />
      </g>
      <mask
        id="mask22_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask22_21870_120883)">
        <path
          d="M83.8328 35.1616C83.8328 34.5165 84.0173 34.0095 84.4326 33.6409C84.8017 33.2722 85.3553 33.0879 86.0935 33.0879C86.7856 33.0879 87.3393 33.2722 87.7545 33.6409C88.1236 34.0095 88.3543 34.5165 88.3543 35.1616V36.8667C88.3543 37.5119 88.1697 38.0188 87.7545 38.3874C87.3854 38.7561 86.8317 38.9404 86.0935 38.9404C85.3553 38.9404 84.8017 38.7561 84.4326 38.3874C84.0635 38.0188 83.8328 37.5119 83.8328 36.8667V35.1616ZM87.2931 35.1616C87.2931 34.793 87.2008 34.5165 86.9702 34.3321C86.7395 34.1478 86.4626 34.0556 86.0474 34.0556C85.6321 34.0556 85.3553 34.1478 85.1246 34.3321C84.8939 34.5165 84.8017 34.793 84.8017 35.1616V36.8667C84.8017 37.2354 84.8939 37.5119 85.1246 37.6962C85.3553 37.8805 85.6321 37.9727 86.0474 37.9727C86.4626 37.9727 86.7395 37.8805 86.9702 37.6962C87.2008 37.5119 87.2931 37.2354 87.2931 36.8667V35.1616Z"
          fill="white"
        />
      </g>
      <mask
        id="mask23_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask23_21870_120883)">
        <path
          d="M93.2447 33.2256C93.337 33.2256 93.4293 33.2256 93.4293 33.2717C93.4754 33.3178 93.4754 33.3638 93.4754 33.456V38.479C93.4754 38.5712 93.4754 38.6634 93.4293 38.7095C93.3831 38.7555 93.337 38.7555 93.2447 38.7555H92.7834C92.6911 38.7555 92.5988 38.7555 92.5988 38.7095C92.5988 38.6634 92.5527 38.6173 92.5527 38.479V34.1012H90.2919V38.479C90.2919 38.5712 90.2919 38.6634 90.2458 38.7095C90.1996 38.7555 90.1535 38.7555 90.0612 38.7555H89.5998C89.5075 38.7555 89.4153 38.7555 89.3691 38.7095C89.323 38.6634 89.323 38.6173 89.323 38.479V33.5021C89.323 33.4099 89.323 33.3178 89.3691 33.3178C89.4153 33.2717 89.4614 33.2717 89.5998 33.2717C89.5537 33.2256 93.2447 33.2256 93.2447 33.2256Z"
          fill="white"
        />
      </g>
      <mask
        id="mask24_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask24_21870_120883)">
        <path
          d="M99.6582 33.2256C99.7505 33.2256 99.8428 33.2256 99.8428 33.2717C99.8889 33.3178 99.8889 33.3638 99.8889 33.456V38.479C99.8889 38.5712 99.8889 38.6634 99.8428 38.7095C99.7966 38.7555 99.7505 38.7555 99.6582 38.7555H99.243C99.1507 38.7555 99.0584 38.7555 99.0123 38.7095C98.9661 38.6634 98.9661 38.6173 98.9661 38.479V36.0827C98.9661 35.6219 99.0123 35.115 99.1507 34.5159H99.1046C98.9661 34.9767 98.7354 35.4376 98.4586 35.9445L97.6743 37.2809C97.582 37.4191 97.4897 37.4652 97.3513 37.4652H97.1206C97.0283 37.4652 96.9822 37.4652 96.9361 37.4191C96.8899 37.3731 96.8438 37.327 96.7976 37.2809L96.0133 35.8984C95.7826 35.4837 95.5519 35.0228 95.4135 34.4698H95.3674C95.4596 35.0228 95.5519 35.5297 95.5519 36.0366V38.433C95.5519 38.5251 95.5519 38.6173 95.5058 38.6634C95.4596 38.7095 95.4135 38.7095 95.3212 38.7095H94.9521C94.8598 38.7095 94.7676 38.7095 94.7214 38.6634C94.6753 38.6173 94.6753 38.5712 94.6753 38.433V33.5021C94.6753 33.4099 94.6753 33.3178 94.7214 33.3178C94.7676 33.2717 94.8137 33.2717 94.9521 33.2717H95.2751C95.3674 33.2717 95.4596 33.2717 95.5058 33.3178C95.5519 33.3638 95.5981 33.4099 95.6442 33.5021L97.259 36.4053L98.8739 33.5021C98.92 33.4099 98.9661 33.3638 99.0123 33.3178C99.0584 33.2717 99.1507 33.2717 99.243 33.2717L99.6582 33.2256Z"
          fill="white"
        />
      </g>
      <mask
        id="mask25_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask25_21870_120883)">
        <path
          d="M104.457 33.2256C104.549 33.2256 104.641 33.2256 104.688 33.2717C104.734 33.3178 104.734 33.3638 104.734 33.456V33.8247C104.734 33.9168 104.734 34.009 104.688 34.009C104.641 34.0551 104.595 34.0551 104.457 34.0551H102.058V35.4376H104.088C104.18 35.4376 104.272 35.4376 104.272 35.4837C104.272 35.5297 104.319 35.5758 104.319 35.7141V36.0827C104.319 36.1749 104.319 36.2671 104.272 36.3131C104.226 36.3592 104.18 36.3592 104.088 36.3592H102.058V37.7878H104.549C104.641 37.7878 104.734 37.7878 104.78 37.8339C104.826 37.88 104.826 37.926 104.826 38.0643V38.433C104.826 38.5251 104.826 38.6173 104.78 38.6634C104.734 38.7095 104.688 38.7095 104.549 38.7095H101.366C101.273 38.7095 101.181 38.7095 101.135 38.6634C101.089 38.6173 101.089 38.5712 101.089 38.433V33.5021C101.089 33.4099 101.089 33.3178 101.135 33.3178C101.181 33.2717 101.227 33.2717 101.366 33.2717C101.32 33.2256 104.457 33.2256 104.457 33.2256Z"
          fill="white"
        />
      </g>
      <mask
        id="mask26_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask26_21870_120883)">
        <path
          d="M109.67 33.2256C109.763 33.2256 109.855 33.2256 109.901 33.2717C109.947 33.3178 109.947 33.3638 109.947 33.456V38.479C109.947 38.5712 109.947 38.6634 109.901 38.7095C109.855 38.7555 109.809 38.7555 109.67 38.7555H109.209C109.117 38.7555 109.024 38.7555 109.024 38.7095C109.024 38.6634 108.978 38.6173 108.978 38.479V36.4053H106.671V38.479C106.671 38.5712 106.671 38.6634 106.625 38.7095C106.579 38.7555 106.533 38.7555 106.441 38.7555H105.979C105.887 38.7555 105.795 38.7555 105.749 38.7095C105.702 38.6634 105.702 38.6173 105.702 38.479V33.5021C105.702 33.4099 105.702 33.3178 105.749 33.3178C105.795 33.2717 105.841 33.2717 105.979 33.2717H106.441C106.533 33.2717 106.625 33.2717 106.625 33.3178C106.671 33.3638 106.671 33.4099 106.671 33.5021V35.5297H108.978V33.5021C108.978 33.4099 108.978 33.3178 109.024 33.3178C109.07 33.2717 109.117 33.2717 109.209 33.2717C109.209 33.2256 109.67 33.2256 109.67 33.2256Z"
          fill="white"
        />
      </g>
      <mask
        id="mask27_21870_120883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="135"
        height="40"
      >
        <path d="M135 0H0V40H135V0Z" fill="white" />
      </mask>
      <g mask="url(#mask27_21870_120883)">
        <path
          d="M114.561 33.2256C114.653 33.2256 114.745 33.2256 114.745 33.2717C114.791 33.3178 114.791 33.3638 114.791 33.456V33.8247C114.791 33.9168 114.791 34.009 114.745 34.009C114.699 34.009 114.653 34.0551 114.561 34.0551H113.223V38.433C113.223 38.5251 113.223 38.6173 113.177 38.6634C113.13 38.7095 113.084 38.7095 112.946 38.7095H112.484C112.392 38.7095 112.3 38.7095 112.254 38.6634C112.208 38.6173 112.208 38.5712 112.208 38.433V34.0551H110.87C110.777 34.0551 110.685 34.0551 110.685 34.009C110.685 33.9629 110.639 33.9168 110.639 33.8247V33.456C110.639 33.3638 110.639 33.2717 110.685 33.2717C110.731 33.2256 110.777 33.2256 110.87 33.2256H114.561Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_21870_120883">
        <rect width="135" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default AkvilonWhiteLogo
