import React from 'react'

const PioneerLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="147"
    height="38"
    viewBox="0 0 147 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_23278_7751)">
      <path
        d="M11.4307 0C11.4441 0.0847422 11.4671 0.167514 11.4671 0.252256C11.4671 12.7586 11.4671 25.267 11.4671 37.7734V38.002H9.16597C9.16214 37.9231 9.15448 37.8384 9.15448 37.7556C9.15448 25.2571 9.15448 12.7567 9.15448 0.258168C9.15448 0.173426 9.1698 0.086713 9.17745 0.00197075C9.92791 0.00197075 10.6784 0.00197075 11.4288 0.00197075L11.4307 0Z"
        fill="black"
      />
      <path
        d="M97.3628 21.9724V24.2821H110.871V26.6194H97.3628V28.9133H110.867V31.2526H95.0923V19.6587H110.867V21.9724H97.3628Z"
        fill="black"
      />
      <path
        d="M128.916 19.6616V21.9654H115.419V24.2712H128.91V26.6144H115.412V28.9123H128.912V31.2457H113.137V19.6616H128.916Z"
        fill="black"
      />
      <path
        d="M145.861 26.9909C146.429 27.5999 146.822 28.2955 146.914 29.1134C146.992 29.813 146.973 30.5245 147 31.2477H144.718C144.718 30.7964 144.741 30.3491 144.71 29.9056C144.691 29.614 144.641 29.3046 144.521 29.0444C144.255 28.4729 143.77 28.1694 143.158 28.1674C139.974 28.1576 136.79 28.1635 133.607 28.1635C133.561 28.1635 133.517 28.1694 133.444 28.1753V31.2497H131.191V19.6439H131.43C135.303 19.6439 139.174 19.642 143.047 19.6439C145.12 19.6439 146.722 21.0767 146.954 23.1893C147.107 24.5945 146.92 25.9306 145.863 26.9909H145.861ZM133.446 25.8183C133.505 25.8242 133.536 25.8301 133.566 25.8301C136.773 25.8301 139.978 25.8341 143.184 25.8282C143.933 25.8282 144.584 25.229 144.682 24.4605C144.722 24.1432 144.731 23.814 144.703 23.4968C144.618 22.5685 143.964 21.9734 143.056 21.9734C139.941 21.9734 136.829 21.9734 133.714 21.9734C133.626 21.9734 133.538 21.9734 133.446 21.9734V25.8183Z"
        fill="black"
      />
      <path
        d="M66.8826 19.6516C68.3051 19.6516 69.7294 19.6319 71.1518 19.6575C72.8767 19.6891 74.4715 21.1592 74.7223 22.9171C74.7567 23.1655 74.7739 23.4177 74.7759 23.668C74.7816 24.88 74.7797 26.092 74.7778 27.304C74.7759 29.4916 73.0816 31.2534 70.9489 31.2633C68.24 31.2751 65.531 31.2731 62.8221 31.2633C60.6665 31.2554 58.9779 29.4916 58.9779 27.2705C58.9779 26.0743 58.9779 24.878 58.9779 23.6818C58.9779 21.38 60.6473 19.6536 62.8891 19.6457C64.2197 19.6398 65.5521 19.6457 66.8826 19.6457C66.8826 19.6477 66.8826 19.6516 66.8826 19.6536V19.6516ZM66.8769 28.9181C68.2151 28.9181 69.5552 28.9181 70.8934 28.9181C71.8353 28.9181 72.513 28.2204 72.513 27.2548C72.513 26.0585 72.513 24.8623 72.513 23.666C72.513 22.6747 71.8315 21.9731 70.8647 21.9731C68.2017 21.9731 65.5406 21.9731 62.8776 21.9731C61.9396 21.9731 61.2504 22.6708 61.2446 23.6345C61.2389 24.8465 61.2389 26.0585 61.2446 27.2705C61.2504 28.2303 61.9281 28.9181 62.8585 28.9181C64.1967 28.9181 65.5368 28.9181 66.875 28.9181H66.8769Z"
        fill="black"
      />
      <path
        d="M38.6813 28.1624V31.2466H36.4337V19.6547C36.5141 19.6507 36.5888 19.6448 36.6634 19.6448C40.544 19.6448 44.4227 19.6409 48.3032 19.6448C50.233 19.6468 51.7932 20.9041 52.1359 22.8335C52.402 24.3214 52.2565 25.762 51.1672 26.9307C50.4053 27.7485 49.4653 28.1624 48.3587 28.1624C45.2363 28.1624 42.1138 28.1624 38.9933 28.1624C38.9014 28.1624 38.8114 28.1624 38.6832 28.1624H38.6813ZM38.6889 25.8192C38.754 25.8251 38.798 25.831 38.844 25.831C42.0354 25.831 45.2248 25.8349 48.4162 25.831C49.1666 25.831 49.8233 25.2378 49.9228 24.4751C49.963 24.1736 49.9726 23.8622 49.9535 23.5587C49.8979 22.6167 49.226 21.9782 48.3109 21.9782C45.1884 21.9782 42.0679 21.9782 38.9455 21.9782C38.8631 21.9782 38.7827 21.9782 38.6889 21.9782V25.8231V25.8192Z"
        fill="black"
      />
      <path
        d="M4.59683 3.45898H6.86161V36.9755H4.59683V3.45898Z"
        fill="black"
      />
      <path
        d="M79.2919 21.9745V31.2508H77.0328V19.6431C77.1171 19.6431 77.1975 19.6431 77.2779 19.6431C81.1508 19.6431 85.0237 19.6431 88.8966 19.6431C90.9336 19.6431 92.4977 21.0305 92.7982 23.0958C92.8212 23.2594 92.8269 23.4249 92.8269 23.5905C92.8269 26.0697 92.8269 28.5489 92.8269 31.0301C92.8269 31.099 92.8193 31.168 92.8155 31.2508H90.5622C90.5622 31.1483 90.5622 31.0557 90.5622 30.965C90.5622 28.541 90.5622 26.115 90.5622 23.691C90.5622 22.6682 89.8864 21.9745 88.8889 21.9745C85.7895 21.9745 82.69 21.9745 79.5886 21.9745C79.4986 21.9745 79.4087 21.9745 79.29 21.9745H79.2919Z"
        fill="black"
      />
      <path
        d="M16.0296 35.9482H13.7514V3.46631H16.0296V35.9482Z"
        fill="black"
      />
      <path d="M18.3497 6.87012H20.6183V33.897H18.3497V6.87012Z" fill="black" />
      <path d="M0 17.9609H2.27626V33.8945H0V17.9609Z" fill="black" />
      <path
        d="M56.7116 31.2521H54.4871V19.6602H56.7116V31.2521Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_23278_7751">
        <rect width="147" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default PioneerLogo
