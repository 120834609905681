import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import SEO from '../components/seo'
import BaseDescriptionSection from '../components/BaseDescriptionSection'
import AboutSection from '../components/AboutSection'
import BenefitsSection from '../components/BenefitsSection'
import HowItWorksSection from '../components/HowItWorksSection'
import ClientsSection from '../components/ClientsSection'
import ModulesSection from '../components/ModulesSection'
import UpdatesSection from '../components/UpdatesSection'
import BannerSection from '../components/BannerSection'
import { Button } from '../components/Common/Button'
import Feedback from '../components/Feedback'
import PartnersBlock from '../components/PartnersBlock'
import SwitchfromAutodesktoSignalDocsBlock from '../components/SwitchfromAutodesktoSignalDocsBlock'

import { GlobalContext } from '../context/GlobalContext'
import { AppRegionEnum } from '../types/app-region.enum'
import { useTranslations } from '../hooks/use-translations'

import * as s from './pages.module.scss'

const IndexPage: React.FC = () => {
  const { region } = React.useContext(GlobalContext)
  const images = useStaticQuery(imagesQuery)
  const { t, getPath } = useTranslations()

  return (
    <>
      <SEO
        title={t('_title')}
        description={t('seo_description')}
        keywords={region === AppRegionEnum.Us ? t('keywords_main') : undefined}
      />

      <BannerSection
        imageDesktop={
          images[`banner_${region}`].childImageSharp.gatsbyImageData
        }
        imageMobile={
          images[`banner_mobile_${region}`].childImageSharp.gatsbyImageData
        }
        title={t('Home title')}
        colorShadow="light"
        titleSize={40}
        isUppercase={false}
      >
        <AnchorLink
          to={getPath('/#how-it-works-section')}
          stripHash
          className={s.anchor_link}
        >
          <Button size="medium">{t('How our technology works')}</Button>
        </AnchorLink>
      </BannerSection>
      <BaseDescriptionSection />
      <BenefitsSection />
      <ModulesSection />
      {region === AppRegionEnum.Ru && <SwitchfromAutodesktoSignalDocsBlock />}
      <HowItWorksSection />
      {region === AppRegionEnum.Ru ? <Feedback /> : <ClientsSection />}
      <UpdatesSection />
      {region === AppRegionEnum.Ru && <PartnersBlock />}
      <AboutSection />
    </>
  )
}

export default IndexPage

const imagesQuery = graphql`
  query {
    banner_ru: file(relativePath: { eq: "home/signal-banner.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    banner_mobile_ru: file(
      relativePath: { eq: "home/signal-banner-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    banner_us: file(relativePath: { eq: "home/signax-banner.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    banner_mobile_us: file(
      relativePath: { eq: "home/signax-banner-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
