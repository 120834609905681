import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import cn from 'classnames'
import Container from '../Common/Container'
import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import Typography, { Size } from '../Common/Typography'

import * as s from './BannerSection.module.scss'

interface BannerPorps {
  imageDesktop: IGatsbyImageData
  imageMobile?: IGatsbyImageData
  title?: string
  subTitle?: string
  classNameSubTitle?: string
  titleSize?: Size
  className?: string
  imgStyle?: React.CSSProperties
  colorShadow?: 'light' | 'dark'
  isUppercase?: boolean
}

const BannerSection: React.FC<BannerPorps> = ({
  imageDesktop,
  imageMobile,
  className,
  classNameSubTitle,
  imgStyle,
  title,
  subTitle,
  titleSize = 80,
  colorShadow,
  isUppercase = true,
  children,
}) => {
  const { t } = useTranslations()
  const { location } = React.useContext(GlobalContext)

  return (
    <section className={cn(s.section, className)}>
      <div
        className={cn(
          s.content,
          colorShadow && s[`shadow_${colorShadow}`],
          (location?.pathname === '/' ||
            location?.pathname === '/bim-360-migrate') &&
            s.content_position_end
        )}
      >
        <Container>
          {title && (
            <Typography
              variant="h1"
              color="white"
              size={titleSize}
              isUppercase={isUppercase}
            >
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography
              variant="h2"
              color="white"
              className={cn(s.subTitle, classNameSubTitle)}
              mt={32}
            >
              {subTitle}
            </Typography>
          )}
          {children}
        </Container>
      </div>
      <GatsbyImage
        image={imageDesktop}
        alt={t('_image_alt')}
        title={t('_image_title')}
        style={imgStyle}
        className={cn(s.image, imageMobile && s.hiddenMobile)}
      />
      {imageMobile && (
        <GatsbyImage
          image={imageMobile}
          alt={t('_image_alt')}
          title={t('_image_title')}
          style={imgStyle}
          className={s.imageMobile}
        />
      )}
    </section>
  )
}

export default BannerSection
