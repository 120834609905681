import React from 'react'

import * as s from './PartnersBlock.module.scss'

interface Props {
  title: string
  Icon: JSX.Element
  IconHover: JSX.Element
  onClick: () => void
}

const PartnersBtn: React.FC<Props> = ({ title, Icon, IconHover, onClick }) => {
  const [isHovered, setIsHovered] = React.useState(false)

  return (
    <div
      onClick={onClick}
      className={s.partners_btn}
      title={title}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? IconHover : Icon}
    </div>
  )
}

export default PartnersBtn
