import React from 'react'
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react'
import cn from 'classnames'

import Section from '../Common/Section'
import Typography from '../Common/Typography'
import ArrowButton from '../Common/ArrowButton'
import NavLinkButton from '../Common/NavLinkButton'
import { UpdatesCard } from './Card'
import { useTranslations } from '../../hooks/use-translations'
import { useArticlesData } from '../../hooks/useArticlesData'
import { useSolutionsData } from '../../hooks/useSolutionsData'
import { useNewsData } from '../../hooks/useNewsData'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'
import { INewsItem } from '../../types'

import './UpdatesSection.styles.scss'

const UpdatesSection: React.FC = () => {
  const { t } = useTranslations()
  const { region } = React.useContext(GlobalContext)
  const news = useNewsData(region as AppRegionEnum)
  const solutions = useSolutionsData()
  const articles = useArticlesData()
  const dataLength = news.length + articles.length + solutions.length
  const swiperRef = React.useRef<SwiperRef>(null)
  const [disabledNavigationPrev, setDisabledNavigationPrev] =
    React.useState(true)
  const [disabledNavigationNext, setDisabledNavigationNext] =
    React.useState(false)

  const data =
    region === AppRegionEnum.Ru
      ? news
      : Array(dataLength)
          .fill(0)
          .reduce((acc: INewsItem[], _, idx) => {
            if (news[idx]) acc.push({ ...news[idx], type: 'News', date: '' })
            if (articles[idx])
              acc.push({ ...articles[idx], type: 'Articles', date: '' })
            if (solutions[idx])
              acc.push({ ...solutions[idx], type: 'Solutions', date: '' })
            return acc
          }, [])

  return (
    <Section>
      <div className="updates-container">
        <Typography variant="h2" size={40} color="blue">
          {region === AppRegionEnum.Us ? t('Updates') : t('News')}
        </Typography>
        <Swiper
          ref={swiperRef}
          slidesPerView="auto"
          onSlideChange={swiper => {
            setDisabledNavigationPrev(swiper.isBeginning)
            setDisabledNavigationNext(swiper.isEnd)
          }}
          style={{ maxHeight: '374px' }}
          className="updates-swiper"
          grabCursor
        >
          {data.map(item => (
            <SwiperSlide className="updates-slide" key={item.id}>
              <UpdatesCard data={item} />
            </SwiperSlide>
          ))}
        </Swiper>
        <ArrowButton
          arrowType="left"
          disabled={disabledNavigationPrev}
          onClick={() => swiperRef.current?.swiper.slidePrev()}
          className={cn('updates-button_slide', 'updates-button_slide_prev')}
        />
        <ArrowButton
          arrowType="right"
          disabled={disabledNavigationNext}
          onClick={() => swiperRef.current?.swiper.slideNext()}
          className={cn('updates-button_slide', 'updates-button_slide_next')}
        />
        {region === AppRegionEnum.Us && (
          <div className="updates-read_more">
            <span>{t('Read more:')}</span>
            <div className="updates-read_more_links">
              <NavLinkButton to="/news/" title={t('News')} />
              <NavLinkButton to="/articles/" title={t('Articles')} />
              <NavLinkButton to="/solutions/" title={t('Solutions')} />
            </div>
          </div>
        )}
      </div>
    </Section>
  )
}

export default UpdatesSection
