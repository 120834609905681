// extracted by mini-css-extract-plugin
export var section = "AboutSection-module--section--FFCle";
export var container = "AboutSection-module--container---v1Dy";
export var textTitle = "AboutSection-module--textTitle--f9Eaq";
export var content = "AboutSection-module--content--I9lqn";
export var content_signal = "AboutSection-module--content_signal--XiWzz";
export var content_signax = "AboutSection-module--content_signax--7f8Ov";
export var buttons = "AboutSection-module--buttons--qRWep";
export var image_bg = "AboutSection-module--image_bg--c+p-V";
export var image_position = "AboutSection-module--image_position--IbunU";
export var shadow = "AboutSection-module--shadow--BhPBv";