import React from 'react'

const TretiiTrestLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="151"
    height="36"
    viewBox="0 0 151 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_23278_3307)">
      <path
        d="M0.75 10.9667C2.21875 9.61352 4.05469 8.82681 5.74219 7.82768C9.90625 5.36527 14.1172 2.97366 18.3047 0.550582C18.4766 0.456177 18.6484 0.361771 18.8125 0.259498C19.3984 -0.10239 19.9687 -0.0630542 20.5547 0.275233C25.5547 3.1782 30.5547 6.08118 35.5547 8.98415C36.2266 9.37751 36.9062 9.75513 37.5703 10.1642C38.7656 10.888 38.7734 12.0445 37.5859 12.7604C36.4219 13.4684 35.25 14.1607 34.0859 14.8609C33.9922 14.8688 33.8906 14.8845 33.7969 14.8924C33.6406 14.7822 33.4922 14.6564 33.3281 14.5541C31.9297 13.7438 30.5234 12.9335 29.125 12.1231C26.2969 10.4868 23.4531 8.87401 20.6328 7.21404C19.8984 6.78135 19.2969 6.82069 18.5625 7.26125C16.2891 8.63013 13.9687 9.92034 11.6797 11.2499C9.60937 12.4536 7.55469 13.6808 5.49219 14.8924C5.41406 14.8924 5.33594 14.8766 5.26562 14.8688C4.91406 14.6406 4.57031 14.3967 4.21094 14.1922C3.00781 13.5235 1.75781 12.9256 0.75 11.9422V10.9667Z"
        fill="#FD0241"
      />
      <path
        d="M34.2031 21.6655C35.3203 22.3185 36.4375 22.9793 37.5547 23.6323C38.7422 24.3325 38.7734 25.544 37.6016 26.2363C35.2266 27.6367 32.8359 29.0056 30.4531 30.3902C27.5391 32.0816 24.5937 33.718 21.7187 35.4802C20.7031 36.1017 19.6797 35.9994 18.625 35.9837C18.4531 35.9837 18.2734 35.8342 18.1094 35.732C12.7187 32.6244 7.32812 29.5169 1.94531 26.3937C1.50781 26.1498 1.14844 25.7643 0.75 25.4418C0.75 25.1192 0.75 24.7888 0.75 24.4662C1.375 24.0178 1.97656 23.5458 2.61719 23.1367C3.40625 22.6332 4.22656 22.169 5.03125 21.6891C5.10937 21.6891 5.1875 21.6813 5.26562 21.6734C9.80469 24.3167 14.3516 26.9522 18.8906 29.5877C19.3437 29.8473 19.8203 29.8473 20.2891 29.6428C20.5547 29.5248 20.8047 29.3596 21.0547 29.218C23.3281 27.9042 25.6016 26.5904 27.8672 25.2687C29.9141 24.0807 31.9609 22.8849 34 21.697C34.0625 21.6891 34.1328 21.6734 34.1953 21.6655H34.2031Z"
        fill="#02A8E3"
      />
      <path
        d="M5.26563 21.6741C5.1875 21.6741 5.10938 21.682 5.03125 21.6898C4.83594 21.5325 4.64844 21.3673 4.44531 21.2335C3.22656 20.3996 1.75 19.9591 0.75 18.7947V18.0159C1.15625 17.5281 1.58594 17.1033 2.17188 16.7886C3.24219 16.2222 4.24219 15.522 5.26563 14.8848C5.34375 14.8848 5.42188 14.9005 5.49219 14.9084C6.95313 15.7738 8.41406 16.6313 9.875 17.5045C10.2422 17.7248 10.6797 17.8585 10.9375 18.2991C10.5 18.5587 10.1094 18.779 9.72656 19.0071C8.23438 19.8961 6.75 20.793 5.25781 21.682L5.26563 21.6741Z"
        fill="#979B9D"
      />
      <path
        d="M150.75 18.7945H148.234V24.6083H145.695V18.8024H143.203V16.4265C143.43 16.395 143.641 16.34 143.859 16.34C145.875 16.34 147.891 16.34 149.906 16.3242C150.25 16.3242 150.523 16.3872 150.75 16.6389V18.7945Z"
        fill="#040407"
      />
      <path
        d="M59.3593 27.9356H56.9061V16.5046C57.7655 16.2293 58.6171 16.2843 59.5468 16.6462C59.6718 16.6934 59.8749 16.6777 59.9921 16.6069C62.1483 15.3167 65.1014 16.5911 65.6952 19.5177C66.0546 21.2957 65.6093 22.8849 64.1483 24.0649C62.9139 25.0641 61.5546 25.1349 60.1327 24.5055C59.9608 24.4268 59.7968 24.3167 59.5233 24.1593C59.3124 25.4495 59.5546 26.6768 59.3671 27.9356H59.3593ZM61.3671 18.5501C60.1796 18.5343 59.4061 19.2974 59.3983 20.4775C59.3905 21.6812 60.1327 22.4758 61.2889 22.4994C62.4608 22.523 63.2421 21.752 63.2577 20.5562C63.2733 19.3525 62.5311 18.5579 61.3671 18.5422V18.5501Z"
        fill="#08080B"
      />
      <path
        d="M117.937 16.7959C118.305 16.6386 118.726 16.379 119.187 16.2531C121.367 15.6867 123.586 17.15 124.008 19.3764C124.328 21.0835 124.047 22.6176 122.734 23.8449C121.422 25.0722 119.726 25.2295 118.164 24.3012C118.055 24.2382 117.945 24.1753 117.758 24.073V27.8886H115.273V16.3239C115.984 16.3239 116.664 16.2924 117.336 16.3475C117.539 16.3632 117.726 16.6229 117.945 16.7881L117.937 16.7959ZM117.734 20.5407C117.742 21.7286 118.531 22.5075 119.695 22.4996C120.836 22.4917 121.633 21.6578 121.617 20.4935C121.601 19.3134 120.82 18.5346 119.648 18.5503C118.484 18.5582 117.719 19.3528 117.734 20.5407Z"
        fill="#07070A"
      />
      <path
        d="M127.797 21.5248C128.469 22.6026 129.617 22.9724 130.649 22.3902C131.242 22.0519 131.641 22.1227 132.133 22.4846C132.461 22.7364 132.852 22.9173 133.227 23.1376C132.914 23.7827 132.406 24.1603 131.852 24.4042C130.25 25.1122 128.625 25.1358 127.086 24.2311C125.375 23.2241 124.633 21.0764 125.25 19.1332C125.867 17.19 127.664 15.9864 129.742 16.128C131.75 16.2617 133.281 17.6699 133.602 19.7469C133.688 20.3054 133.617 20.8955 133.617 21.5248H127.797ZM131.094 19.5974C130.656 18.5825 130.031 18.2442 129.039 18.4094C128.336 18.5274 127.977 18.8893 127.906 19.5974H131.094Z"
        fill="#07070A"
      />
      <path
        d="M75.2109 21.5486H69.4921C69.9765 22.4926 71.1874 23.0119 72.1484 22.4533C72.9609 21.9734 73.4609 22.2566 74.0624 22.6578C74.328 22.8309 74.6015 22.9883 74.9218 23.1849C74.328 23.9717 73.6484 24.483 72.7577 24.7033C71.5234 25.0101 70.3046 24.9944 69.1405 24.4594C67.2109 23.5704 66.2734 21.3362 66.914 19.1884C67.5234 17.1351 69.4999 15.8685 71.6484 16.1517C73.7499 16.4271 75.289 18.1578 75.3359 20.282C75.3359 20.5731 75.3359 20.8641 75.3202 21.1631C75.3202 21.2575 75.2734 21.344 75.2187 21.5486H75.2109ZM72.7812 19.5739C72.2968 18.5748 71.7655 18.2758 70.7734 18.4017C70.0468 18.4961 69.664 18.8423 69.5155 19.5739H72.7812Z"
        fill="#07070A"
      />
      <path
        d="M93.6716 16.3716H96.0544V20.1635C96.1091 20.1871 96.1638 20.2107 96.2185 20.2422C96.7029 19.6994 97.2029 19.1644 97.6794 18.6137C98.1482 18.0709 98.6404 17.5438 99.0544 16.9695C99.4216 16.466 99.8591 16.2536 100.469 16.3401C100.75 16.3794 101.039 16.3401 101.39 16.3401V24.6242H99.0076V20.5962C98.2654 21.4459 97.6716 22.1146 97.0779 22.799C96.6951 23.2396 96.3123 23.688 95.9607 24.1522C95.656 24.5534 95.3044 24.7422 94.7888 24.6793C94.4373 24.6399 94.0779 24.6793 93.6638 24.6793V16.3716H93.6716Z"
        fill="#07070A"
      />
      <path
        d="M86.5081 16.3882V20.4476C87.2034 19.6531 87.7971 18.9843 88.3909 18.3156C88.6877 17.9695 89.0393 17.6548 89.2581 17.2693C89.8596 16.223 90.7737 16.2466 91.8284 16.4197V24.6329H89.4534V20.605C88.8127 21.3445 88.3049 21.9267 87.8049 22.5088C87.344 23.0517 86.8674 23.5866 86.4299 24.1531C86.1252 24.5464 85.7815 24.751 85.2659 24.688C84.9221 24.6408 84.5627 24.6802 84.1721 24.6802V16.3882H86.5081Z"
        fill="#08080A"
      />
      <path
        d="M58.344 13.1695C57.6643 13.9877 57.1018 14.6957 56.4846 15.3566C56.3127 15.5375 55.9846 15.624 55.719 15.6398C55.1409 15.6791 54.5627 15.6555 53.8987 15.6555V24.624H51.2346V15.687H48.2971V13.2167C48.969 13.0436 56.8674 12.9886 58.344 13.1695Z"
        fill="#08080B"
      />
      <path
        d="M109.578 15.6631H106.656V13.1299H116.414C115.711 13.9559 115.093 14.7112 114.445 15.4271C114.312 15.5687 114.054 15.6395 113.843 15.6474C113.359 15.6788 112.875 15.6552 112.257 15.6552C112.226 16.6701 112.25 17.6614 112.242 18.6605C112.242 19.636 112.242 20.6115 112.242 21.5871V24.6316H109.578V15.6631Z"
        fill="#07070A"
      />
      <path
        d="M142.617 18.3226C141.891 18.7474 141.219 19.1329 140.563 19.5105C139.5 18.4642 138.375 18.3462 137.555 19.1801C136.875 19.8645 136.883 21.1311 137.57 21.8471C138.328 22.6416 139.539 22.5236 140.578 21.5324C141.242 21.91 141.906 22.2876 142.57 22.6652C141.945 24.278 140.023 25.1749 138.023 24.8287C136.164 24.514 134.672 22.799 134.547 20.903C134.391 18.4642 135.852 16.8357 137.563 16.3165C139.586 15.695 141.688 16.5289 142.625 18.3226H142.617Z"
        fill="#07070A"
      />
      <path
        d="M34.2034 21.6669C34.1409 21.6748 34.0706 21.6905 34.0081 21.6984C33.0784 21.1241 32.1487 20.5341 31.2112 19.9676C30.2503 19.3933 29.2737 18.8348 28.2112 18.2133C29.3362 17.5682 30.3362 16.9939 31.3362 16.4038C32.1644 15.9082 32.9847 15.3968 33.805 14.8855C33.8987 14.8776 34.0003 14.8619 34.0941 14.854C34.7894 15.3024 35.4847 15.7587 36.1956 16.1914C36.6878 16.4904 37.2112 16.7421 37.7034 17.0489C38.7347 17.6862 38.7112 18.937 37.6644 19.5743C36.5159 20.2745 35.3597 20.9668 34.2112 21.6669H34.2034Z"
        fill="#979B9D"
      />
      <path
        d="M78.1409 24.6163V18.8182H75.6722V16.3794H83.0394V18.7789H80.6331V24.6084H78.1409V24.6163Z"
        fill="#08080B"
      />
      <path
        d="M101.008 13.3188C100.414 14.0112 99.9296 14.6091 99.4061 15.1755C99.2733 15.3171 99.0311 15.4272 98.828 15.4351C97.9296 15.4666 97.0389 15.4508 96.1014 15.4508V13.3267H101.008V13.3188Z"
        fill="#050508"
      />
      <path
        d="M33.7971 14.8926C32.9768 15.396 32.1565 15.9153 31.3284 16.4109C30.3362 17.0009 29.3284 17.5752 28.2034 18.2203C29.2737 18.8419 30.2424 19.3926 31.2034 19.9747C32.1409 20.5412 33.0706 21.1312 34.0002 21.7055C31.9534 22.8934 29.9143 24.0892 27.8674 25.2772C25.594 26.5988 23.3206 27.9127 21.0549 29.2265C20.8049 29.3759 20.5549 29.5333 20.2893 29.6513C19.8206 29.8637 19.3362 29.8637 18.8909 29.5962C14.344 26.9607 9.80493 24.3174 5.26587 21.6819C6.75806 20.7929 8.24243 19.896 9.73462 19.0071C10.1174 18.7789 10.5081 18.5508 10.9456 18.299C10.6877 17.8585 10.2424 17.7247 9.88306 17.5044C8.42993 16.6312 6.96118 15.7737 5.50024 14.9083C7.56274 13.6967 9.61743 12.4695 11.6877 11.2658C13.9768 9.92839 16.2971 8.63818 18.5706 7.27717C19.2971 6.83661 19.8987 6.79727 20.6409 7.22996C23.4612 8.88993 26.2971 10.5027 29.1331 12.1391C30.5315 12.9494 31.9377 13.7518 33.3362 14.57C33.5002 14.6644 33.6487 14.7903 33.8049 14.9083L33.7971 14.8926ZM15.0393 12.9258C15.6409 13.6181 16.2268 14.2868 16.844 14.9948C17.0315 14.7745 17.1409 14.6172 17.2815 14.4835C17.9299 13.8462 18.7034 13.5945 19.6018 13.7125C20.1799 13.7912 20.5862 14.1137 20.6643 14.57C20.7581 15.1128 20.7034 15.6557 20.1799 15.9467C19.7659 16.1749 19.2893 16.3008 18.8284 16.4266C18.5627 16.4974 18.2737 16.4738 18.0159 16.4896V18.5114C18.5471 18.5508 19.0393 18.5508 19.5081 18.6294C20.0549 18.7238 20.5862 18.9205 20.8362 19.4948C21.2737 20.4861 20.8362 21.5875 19.6096 21.737C18.4221 21.8786 17.3518 21.6426 16.3831 20.7457C15.844 21.4695 15.3596 22.1303 14.8752 22.7833C15.8127 23.7509 16.5549 24.1286 18.219 24.3646C19.6721 24.577 21.0706 24.3646 22.3674 23.6329C24.6409 22.3349 24.4534 18.7789 22.3831 17.6618C22.2346 17.5831 22.1096 17.4651 21.9377 17.3471C22.0784 17.2448 22.1565 17.1819 22.2424 17.1347C23.469 16.3952 23.9612 15.2702 23.7581 13.9013C23.5627 12.6111 22.6643 11.8794 21.4768 11.4782C20.0706 11.0062 18.6643 11.0849 17.2659 11.5176C16.4143 11.7772 15.6487 12.1627 15.0237 12.9179L15.0393 12.9258Z"
        fill="#020206"
      />
    </g>
    <defs>
      <clipPath id="clip0_23278_3307">
        <rect
          width="150"
          height="36"
          fill="white"
          transform="translate(0.75)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default TretiiTrestLogo
